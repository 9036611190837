import {AnswerAtom, PrefetchedImagesAtom, SurveyAtom} from "../atoms";
import React from "react";
import {Box, Button, Modal, Stack, Typography} from "@mui/material";
import {useAtomValue} from "jotai";
import {Link} from "react-router-dom";
import logoImg from "../../../assets/logo/Ella.svg";
import {IconChevronRight} from "@tabler/icons-react";
import {enums} from "../../../utils/enums";

export const SectionQuestion = () => {
  const answer = useAtomValue(AnswerAtom);
  const survey = useAtomValue(SurveyAtom);
  const images = useAtomValue(PrefetchedImagesAtom);

  const handleSubmitClick = () => {
    const submitButton = document.getElementById("submit-survey");
    console.log(submitButton)
    if (submitButton) {
      submitButton.click(); // Simulate a click on the submit button
    }
  };
  const handleBackClick = () => {
    const backButton = document.getElementById("back-survey");
    if (backButton) {
      backButton.click(); // Simulate a click on the submit button
    }
  };

  return (
    <Modal open={true}>
      <Stack
        direction={"column"} alignItems={"center"} justifyItems={"center"}
        sx={{
          // position: "absolute",
          maxHeight: "100dvh", width: "100dvw",
          height: "100%",
          backgroundx: "#fff",
          backgroundImage: "linear-gradient(45deg, #F6F2FF 0%, #DAE1FF 50%, #E5F3FF 100%) !important",
          zIndex: 1000
        }}
      >
        <Stack maxWidth={"1500px"} justifyContent={"center"} alignItems={"center"} gap={2}
               sx={{mx: "auto", my: "auto", flexDirection: {xs: "column", md: "row"}, p: {xs: 2, md: 5}}}>
          <Stack direction={"column"} gap={"20px"} sx={{flex: {xs: 0, md: 1}}}>
            <Typography sx={{
              fontSize: {xs: "36px", md: "50px"},
              color: "#2D2D2D", textAlign: {xs: "center", sm: "start"}
            }}>{answer?.question || "Thank you"}</Typography>
            <Typography
              sx={{
                fontSize: {xs: "16px", md: "20px"},
                color: "#474747", textAlign: {xs: "center", sm: "start"}
              }}
            >
              {answer?.subTitle || answer?.description}
            </Typography>
            <Typography sx={{fontSize: {xs: "16px", md: "20px"}, color: "#474747", fontWeight: 500}}>
              {answer?.bottomExplainer}&nbsp;
              <Typography component={"a"} href={answer?.link} target={"_blank"}
                          sx={{fontSize: "inherit", color: "inherit", fontWeight: 500}}>
                {answer?.link}
              </Typography>
            </Typography>
            <Stack direction={"row"} gap={2}>
              <Button
                variant={"contained"}
                onClick={handleBackClick}
                sx={{
                  bgcolor: "#C1C6F0",
                  color: "#FFF",
                  borderRadius: 3,
                  boxShadow: 0,
                  p: {xs: 1, md: 2},
                  fontSize: {xs: "15px", md: "16px"},
                  '&:hover': {bgcolor: "#C1C6F099", boxShadow: 0}
                }}
              >
                Go Back
              </Button>
              <Button
                onClick={handleSubmitClick}
                endIcon={<IconChevronRight/>}
                sx={{
                  width: {xs: "100%", md: "auto"},
                  color: "#FFFFFF",
                  bgcolor: "#8960FF",
                  minWidth: 150,
                  borderRadius: 3,
                  p: {xs: 1, md: 2},
                  fontSize: {xs: "15px", md: "16px"},
                  backgroundImage: "linear-gradient(to right, #8960FF, #637DF7) !important",
                  "&:hover": {
                    bgcolor: "#637DF7",
                    backgroundImage: "linear-gradient(to right, #637DF7, #637DF7) !important",
                  }
                }}
              >
                {document?.getElementById("submit-survey")?.innerText || "Continue"}
                &nbsp;Survey
              </Button>
            </Stack>
          </Stack>
          <Box sx={{flex: {xs: 0, md: 1}}}>
            {
              answer?.imgUrl &&
              <img
                src={images[answer?.imgUrl]?.src || answer?.imgUrl}
                alt={"Thank you image"} style={{borderRadius: "24px", maxWidth: "600px"}}
                width={"100%"} height={"auto"}
              />
            }
          </Box>
        </Stack>
        {
          survey?.plan !== enums.ANNUAL &&
          <Box sx={{position: "absolute", bottom: 5, right: 10}}>
            <Link to={"/"}>
              <img src={logoImg} alt={"Logo"} height={50}/>
            </Link>
          </Box>
        }
      </Stack>
    </Modal>
  )
}
