import {deleteObject, getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "./firebaseApp";
import uuid from "react-uuid";
import {QuestionStatusType} from "../pages/create-project-page-new/enums";
import {toast} from "react-toastify";


export const uploadPhoto = async (uri) => {

  const blob = await (await fetch(uri)).blob();
  const imageName = `${uuid()}.jpg`;

  return await uploadFile(blob, "answers", imageName, {contentType: "image/jpeg"});
}

export const uploadVoice = async (dataUri) => {
  try {
    const blob = await (await fetch(dataUri)).blob();

    const audioName = `${uuid()}.webm`;

    return await uploadFile(blob, "answers", audioName, {contentType: "audio/webm"})
  } catch (error) {
    console.log(error)
    return ""
  }
}


export function renderBoldText(text) {
  // Regular expression to match words wrapped in ** (e.g., **word**)
  const regex = /\*\*(.*?)\*\*/g;

  // Replace matched patterns with <strong> tags
  return text.replace(regex, '<strong>$1</strong>');
}

export const uploadFile =  async (file, folder = "storage", name, metadata = {}) => {
  const fileFolderRef = ref(storage, `${folder}/${name}`)
  const imgRef = await uploadBytes(fileFolderRef, file, {...metadata});
  return await getDownloadURL(imgRef.ref);
}

// Helper function to convert dataUri to Blob
export const dataUriToBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);
  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], {type: mimeString});
  return blob;

}

export const removeFile = async (fileUrl) => {
  const fileRef = ref(storage, fileUrl);
  await deleteObject(fileRef);
}

export const createTranslationQuestion = ({question, language}) => {
  const translateQuestion = {...question};
  translateQuestion.uid = uuid();
  translateQuestion.languageObject = {...language};
  translateQuestion.originalReferenceUid = question.uid;
  translateQuestion.status = QuestionStatusType.TRANSLATED;

  return translateQuestion;
}


export const copyToClipboard = async (url) => {
  try {
    await navigator.clipboard.writeText(url);
    toast.success("URL copied successfully");
  } catch (error) {
    toast.success("Error copying URL");
  }
};