import {Chip, Fade, Stack, Typography} from "@mui/material";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Map} from "react-map-gl";
import Box from "@mui/material/Box";
import {AppContext} from "../../utils/AppContext";
import {enums} from "../../utils/enums";
import PulsingDot from "../../assets/PulsingDot/PulsingDot";
import {CustomMarker} from "../../components/Map/CustomMarker";
import {colors} from "../../components/Map/mapIcons";
import SurveyQuestionSelectorDrodowns from "../../components/shared-components/SurveyQuestionSelectorDrodowns";
import {TitleDate} from "../../components/shared-components/text-formating/title-date/title-date";
import {Responses} from "../project-summaries-page/components/Responses";
import "./react-board.css";
import {useNavigate, useParams} from "react-router-dom";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../utils/firebaseApp";
import LoadingScreen from "../../components/shared-components/LoadingScreen";
import {EnglishNativeLanguage} from "../../utils/english-native-language";
import {QuestionTypes} from "../create-project-page-new/enums";
import {CreateSurveyBanner} from "../../components/shared-components/banner/create-survey-banner";

const token = process.env.REACT_APP_MAPBOX_TOKEN;

export const AnalysisPage = () => {
  const {state: {surveys, surveyObjectToDisplay, questionObjects, answerObjects}} = useContext(AppContext);
  const [activeSurvey, setActiveSurvey] = useState(surveyObjectToDisplay ? surveyObjectToDisplay : surveys[0]);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [onlyOriginalQuestions, setOnlyOriginalQuestions] = useState([]);
  const [answersForActiveQuestion, setAnswersForActiveQuestion] = useState([]);
  const [viewport, setViewport] = useState({
    longitude: -74.006,
    latitude: 40.7128,
    zoom: 10,
  });
  const mapRef = useRef();
  const [loading, setLoading] = useState(false);
  const {id} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        setLoading(true);

        const docRef = doc(db, "surveys", id);
        const surveySnapshot = await getDoc(docRef);
        if (!surveySnapshot.exists()) navigate('/404');

        setActiveSurvey({...surveySnapshot.data()});

      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    if (surveys?.length) {
      if (!id) {
        const surveyUid = surveys[0]?.uid || null;
        if (surveyUid) {
          navigate(`/analysis/${surveyUid}`)
        }
      } else {
        const survey = surveys?.find(i => i?.uid === id);
        if (survey) {
          setActiveSurvey(survey);
        } else {
          fetchSurvey();
        }
      }
    }
  }, [id, surveys])

  useEffect(() => {
    if (!questionObjects?.length) return
    if (!surveys?.length) return

    const surveyEnglishQuestions = activeSurvey ? questionObjects.filter(
      (questionObject) => (
        questionObject?.surveyUid === activeSurvey?.uid
        && questionObject?.languageObject?.BCP47 === EnglishNativeLanguage.BCP47
        && QuestionTypes.SECTION !== questionObject?.type
      )
    )?.sort((a, b) => a?.orderNumber - b?.orderNumber) || [] : [];
    setOnlyOriginalQuestions(surveyEnglishQuestions || []);
    setActiveQuestion(surveyEnglishQuestions[0] || null)
  }, [activeSurvey, surveys, questionObjects])

  useEffect(() => {
    if (!activeQuestion) return
    console.log('rerendering')
    const qUid = activeQuestion.originalReferenceUid || activeQuestion.uid
    const answers =
      [...answerObjects.filter((answer) => answer.originalReferenceUid === qUid || answer.questionUid === qUid)]
    setAnswersForActiveQuestion(answers);
    if (activeQuestion?.type === QuestionTypes.MAPINPUT) {
      const pois = answers?.map(i => i?.pois || [])?.flat() || [];
      if (pois?.length > 0 && mapRef?.current) {
        const midpoint = calculateMidpointFromJsonList(pois)
        mapRef.current?.flyTo({center: [midpoint.lng, midpoint.lat], duration: 2000});
        console.log("Pois and Midpoint", pois, midpoint)
        console.log("MapRef", mapRef?.current)
      }
    }
  }, [activeQuestion, answerObjects, mapRef?.current]);

  function calculateMidpointFromJsonList(jsonList) {
    if (jsonList.length === 0) {
      return null; // No coordinates to calculate midpoint
    }

    // Calculate average latitude and longitude
    const sumLat = jsonList.reduce((sum, jsonObj) => sum + jsonObj.coordinates.lat, 0);
    const sumLng = jsonList.reduce((sum, jsonObj) => sum + jsonObj.coordinates.lng, 0);

    const avgLat = sumLat / jsonList.length;
    const avgLng = sumLng / jsonList.length;

    return {lat: avgLat, lng: avgLng};
  }

  if (loading) {
    return <LoadingScreen/>
  }


  if (surveys.length === 0) {
    return (
      <Stack
        direction={"column"}
        gap={2} maxWidth={"lg"}
        sx={{mx: {xs: 2, md: "auto"}}}
      >
        <TitleDate title="Survey Responses" big/>
        <CreateSurveyBanner/>
      </Stack>
    );
  }

  return (
    <>
      <Stack
        direction={"column"}
        gap={0} maxWidth={"lg"}
        sx={{
          mx: {xs: 2, md: "auto"}
        }}
      >
        <Stack
          direction={"column"} gap={2} fullWidth
        >
          <TitleDate title="Survey Responses" big/>
          <Stack direction={"column"} gap={0} sx={{mb: 2}}>
            <SurveyQuestionSelectorDrodowns
              surveys={surveys}
              showQuestionSelect={true}
              questionObjects={onlyOriginalQuestions?.sort((a, b) => b?.orderNumber - a?.orderNumber)}
              setActiveSurvey={(survey) => survey?.uid && navigate(`/analysis/${survey?.uid}`)}
              activeSurvey={activeSurvey}
              setActiveQuestion={setActiveQuestion}
              activeQuestion={activeQuestion}
            />
            <Typography variant={"body2"} sx={{px: 1}}>New responses may take a minute to display below.</Typography>
          </Stack>
        </Stack>
        {/*{activeQuestion && activeQuestion.type === enums.MAPINPUT &&*/}
        {activeQuestion && activeQuestion?.type === QuestionTypes.MAPINPUT &&
          <Fade in={activeQuestion && activeQuestion?.type === QuestionTypes.MAPINPUT} timeout={500}>
            <Box sx={{my: activeQuestion?.type === QuestionTypes.MAPINPUT ? 2 : 0, width: "100%"}}>
              <Map
                mapboxApiAccessToken={token}
                mapboxAccessToken={token}
                scrollZoom={true}
                initialViewState={viewport}
                style={{
                  width: "100%",
                  height: "100%",
                  minHeight: activeQuestion?.type === QuestionTypes.MAPINPUT ? "400px" : 0
                }}
                mapStyle={"mapbox://styles/urosran/clgxw85q1008801qt5gswf9la"}
                attributionControl={false}
                position={"top-right"}
                ref={mapRef}
              >
                {answersForActiveQuestion.length > 0 &&
                  answersForActiveQuestion?.map((answerObject) =>
                    answerObject.pois?.map((poi) => {
                      const index = activeQuestion?.options?.findIndex(i => i?.option === poi?.option)
                      return (
                        <CustomMarker
                          key={poi.coordinates.lng + poi.coordinates.lat}
                          latitude={parseFloat(poi.coordinates.lat)}
                          longitude={parseFloat(poi.coordinates.lng)}
                          viewport={viewport}
                          coords={poi.coordinates}
                          setViewport={setViewport}
                          icon={
                            <PulsingDot
                              pulse={false}
                              fill={colors[index]}
                            />
                          }
                          locationObject={poi}
                        />
                      );
                    }),
                  )}
              </Map>
              {activeQuestion?.options?.map((option, index) => {
                return (
                  <Chip
                    label={option?.option}
                    disabled={false}
                    name={option?.option}
                    sx={{
                      backgroundColor: colors[index],
                      margin: 1,
                      color: "white",
                    }}
                    key={option?.option}
                  />
                );
              })}
            </Box>
          </Fade>
        }
        {/*}*/}
        <Responses
          responses={answersForActiveQuestion}
          question={activeQuestion}
        />
      </Stack>
    </>
  );
};
