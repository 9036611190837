import { Box, Button, FormControlLabel, Modal, Stack, Switch, TextField, Typography } from "@mui/material";
import { doc, updateDoc, writeBatch } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useAtom, useAtomValue } from "jotai";
import React, {useContext, useEffect, useMemo, useState} from "react";
import { confirm } from "react-confirm-box";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { confirmDialogOptions } from "../../../components/ConfirmDialog";
import { auth, db, functions } from "../../../utils/firebaseApp";
import { createTranslationQuestion, uploadFile } from "../../../utils/functions";
import {
    AiDrawerState,
    CreateQuestionModalState,
    CreateSurveyQuestionsState,
    EditQuestionModalState,
    SelectedLanguageState
} from "../atoms";
import { QuestionStatusType, QuestionTypes } from "../enums";
import { useSaveSurvey } from "../hooks/useSaveSurvey";
import { OptionsQuestions } from "./questions/options-questions";
import { SectionQuestion } from "./questions/section-question";
import { SliderQuestion } from "./questions/slider-question";
import { SelectQuestionType } from "./select-question-type";
import {AppContext} from "../../../utils/AppContext";
import {aiDrawerWidth} from "./AiDrawer";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "750px", width: "95%",
    maxHeight: "80dvh", overflowY: "auto",
    borderRadius: "16px",
    bgcolor: "background.paper",
    p: 2, pb: 2,
    zIndex: 3
};

const initialQuestionState = {
    uid: uuid(),
    question: "",
    description: "",
    type: QuestionTypes.TEXT,
    orderNumber: 0,
    userUid: null,
    surveyUid: null,
    languageObject: null,
    isRequired: false,
    allOtherCasesQuestion: null,
}

export const CreateQuestionModal = ({container = undefined}) => {
    const [open, setOpen] = useAtom(CreateQuestionModalState);
    const [editQuestion, setEditQuestion] = useAtom(EditQuestionModalState);
    const aiDrawerOpen = useAtomValue(AiDrawerState);
    const { state: { workspaceId } } = useContext(AppContext);

    const { survey, saveSurvey } = useSaveSurvey();
    const questions = useAtomValue(CreateSurveyQuestionsState);
    const selectedLanguage = useAtomValue(SelectedLanguageState);
    const [questionType, setQuestionType] = useState(QuestionTypes.TEXT);
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [question, setQuestion] = useState(JSON.parse(JSON.stringify({ ...initialQuestionState })));
    const startTranslation = httpsCallable(functions, "translateFromSourceToTarget");


    useEffect(() => {
        setQuestion(prev => ({ ...prev, type: questionType }));
    }, [questionType])

    useEffect(() => {
        if (open) {
            console.log(initialQuestionState, editQuestion, questions)
            if (editQuestion) {
                setQuestion({ ...editQuestion });
                setQuestionType(editQuestion?.type || QuestionTypes.TEXT);
            } else {
                setQuestion(JSON.parse(JSON.stringify({ ...initialQuestionState })));
                setQuestionType(QuestionTypes.TEXT);
            }
        }
    }, [open, editQuestion, questions])

    const handleClose = (e) => {
        e?.preventDefault();
        if (loading) {
            return;
        }
        setQuestion(JSON.parse(JSON.stringify({ ...initialQuestionState })));
        setQuestionType(QuestionTypes.TEXT);
        setEditQuestion(null);
        setImage(null);
        setOpen(false);
    }

    const onUpdate = (key, value) => {
        setQuestion(prev => {
            console.log(prev, key, value)
            return { ...prev, [key]: value }
        })
    }

    const onSubmit = async (e) => {
        e?.preventDefault();
        let surveyUid = survey?.uid || null;
        if (!survey?.dateCreated) {
            await saveSurvey({ ...survey, uid: surveyUid });
        }

        // Only calculate orderNumber for new questions, not edits
        const orderNumber = editQuestion
            ? editQuestion.orderNumber  // Keep existing orderNumber when editing
            : questions?.filter(i => i?.languageObject?.BCP47 === selectedLanguage?.BCP47)?.length || 0;

        // Initialize question
        const questionObj = { ...question };
        questionObj.uid = editQuestion?.uid || uuid();
        questionObj.userUid = editQuestion?.dateCreated || auth?.currentUser?.uid;
        questionObj.surveyUid = surveyUid;
        questionObj.dateCreated = editQuestion?.dateCreated || new Date().getTime();
        questionObj.dateUpdated = new Date().getTime();
        questionObj.languageObject = selectedLanguage;
        if (workspaceId) {
            questionObj.workspaceId = workspaceId?.uid || workspaceId?.id || workspaceId;
        }
        console.log("editQuestion", editQuestion);
        console.log("editQuestion?.orderNumber", editQuestion?.orderNumber);
        questionObj.orderNumber = editQuestion?.orderNumber || orderNumber;
        const nextQuestion = questions?.find(q => q.orderNumber === orderNumber + 1);
        questionObj.allOtherCasesQuestion = editQuestion?.allOtherCasesQuestion || nextQuestion?.orderNumber || null;

        let options = [];
        // If it is question with options than filter only options with values;
        if (
            questionType === QuestionTypes.MULTICHOICE || questionType === QuestionTypes.DROPDOWN
            || questionType === QuestionTypes.MAPINPUT || questionType === QuestionTypes.CHECKBOXES || questionType === QuestionTypes.IMAGE_GALLERY
        ) {
            options = questionObj?.options?.filter(i => i?.option?.trim() ||  i?.other);
            if (options.length === 0) {
                toast.error("Please add some options");
                return;
            }
        }

        const toastId = toast.loading("Creating your question...");
        try {
            setLoading(true);
            if (questionType === QuestionTypes.IMAGE_GALLERY) {
                // Handle image uploads for options
                const options = questionObj?.options || [];
                const uploadPromises = options.map(async (option) => {
                    if (option.imageUrl && option.imageUrl.startsWith('blob:')) {
                        const imgUid = uuid();
                        // Convert blob URL to File object
                        const response = await fetch(option.imageUrl);
                        const blob = await response.blob();
                        const file = new File([blob], `option-image-${imgUid}.jpg`, { type: 'image/jpeg' });

                        toast.update(toastId, {
                            render: `Uploading image for option: ${option.option}...`,
                            type: "info",
                            isLoading: true
                        });

                        const uploadedUrl = await uploadFile(file, "questions", `${imgUid}-option-image.jpg`);
                        option.imageUrl = uploadedUrl;
                    }
                    return option;
                });
                await Promise.all(uploadPromises);
            }
        } catch (error) {
            console.log(error);
            toast.update(toastId, {
                render: "Failed to create question...",
                autoClose: 3000,
                type: "error",
                isLoading: false
            });
        }

        questionObj.options = options;

        console.log("questionObj", questionObj);

        // const toastId = toast.loading("Creating your question...");
        // upload image to the storage
        if (questionType === QuestionTypes.SECTION || questionType === QuestionTypes.LOCATE_USER) {
            if (image && image?.name) {
                try {
                    toast.update(toastId, { render: "Uploading image......", type: "info", isLoading: true });
                    const imgUid = uuid();
                    questionObj.imgUrl = await uploadFile(image, "questions", `${imgUid}-${image?.name}`);
                    toast.update(toastId, { render: "Image uploaded...", type: "info", isLoading: true });
                } catch (error) {
                    toast.update(toastId, {
                        render: "Image uploaded...",
                        autoClose: 3000,
                        type: "error",
                        isLoading: false
                    });
                }
            }
        }
        questionObj.status = QuestionStatusType.TRANSLATED;
        questionObj.originalReferenceUid = editQuestion?.originalReferenceUid || null;
        questionObj.allOtherCasesQuestion = editQuestion?.allOtherCasesQuestion || nextQuestion?.orderNumber || null;
        const batch = writeBatch(db);

        if (editQuestion) {
            // Find the next question based on order number
            const nextQuestion = questions
                ?.filter(q => q?.languageObject?.BCP47 === selectedLanguage?.BCP47)
                ?.find(q => q.orderNumber === editQuestion.orderNumber + 1);

            // Update the current question
            questionObj.rules = []; // Clear existing rules
            questionObj.allOtherCasesQuestion = nextQuestion?.orderNumber || null;

            // Also update all translations
            const translations = questions.filter(q =>
                q.originalReferenceUid === questionObj.uid ||
                (questionObj.originalReferenceUid &&
                    (q.uid === questionObj.originalReferenceUid ||
                        q.originalReferenceUid === questionObj.originalReferenceUid))
            );

            for (const translation of translations) {
                batch.update(doc(db, "questions", translation.uid), {
                    rules: [],
                    allOtherCasesQuestion: nextQuestion?.orderNumber || null
                });
            }
        } else {
            // Existing new question logic
            const previousQuestion = questions
                ?.filter(q => q?.languageObject?.BCP47 === selectedLanguage?.BCP47)
                ?.find(q => q.orderNumber === orderNumber - 1);

            if (previousQuestion) {
                batch.update(doc(db, "questions", previousQuestion.uid), {
                    allOtherCasesQuestion: questionObj.orderNumber
                });

                const previousTranslations = questions.filter(q =>
                    q.originalReferenceUid === previousQuestion.uid ||
                    (previousQuestion.originalReferenceUid &&
                        (q.uid === previousQuestion.originalReferenceUid ||
                            q.originalReferenceUid === previousQuestion.originalReferenceUid))
                );

                for (const translation of previousTranslations) {
                    batch.update(doc(db, "questions", translation.uid), {
                        allOtherCasesQuestion: questionObj.orderNumber
                    });
                }
            }
        }

        batch.set(doc(db, "questions", questionObj.uid), questionObj);

        const translateQuestions = questions?.filter(i => i?.originalReferenceUid === questionObj.uid || (questionObj?.originalReferenceUid && (i?.uid === questionObj?.originalReferenceUid || i?.originalReferenceUid === questionObj?.originalReferenceUid))) || [];
        if (translateQuestions.length === 0) {
            for (const language of survey?.languages || []) {
                if (language && language?.BCP47 !== selectedLanguage?.BCP47) {
                    const translateQuestion = createTranslationQuestion({ question: questionObj, language });

                    batch.set(doc(db, "questions", translateQuestion.uid), translateQuestion);
                    translateQuestions.push(translateQuestion);
                }
            }
        }
        try {
            setLoading(true);
            await batch.commit();
            if (translateQuestions?.length > 0 && !questionObj?.originalReferenceUid) {
                // This will ask user if we need to do the translation, because translation maybe is wrong so user can't edit it will go in loop of generating translations
                const response = await confirm(
                    {
                        title: `Do you want to translate question in other languages?`,
                        confirmText: "Yes",
                        cancelText: "No",
                        // subtitle: `This action will delete all questions in other languages that were created as a translation of this one.`,
                    },
                    confirmDialogOptions,
                );
                if (response) {
                    toast.update(toastId, { render: "Translating in progress...", type: "info", isLoading: true });
                    for (const questionItem of translateQuestions || []) {
                        if (!questionItem?.uid || !questionItem?.languageObject?.Native) {
                            continue;
                        }
                        try {
                            const response = await startTranslation({
                                sourceUid: questionObj?.uid,
                                targetUid: questionItem?.uid
                            })
                            console.log(response)
                            toast.update(toastId, {
                                render: `Translation started: ${questionItem?.languageObject?.Native} `,
                                type: "info",
                                isLoading: true
                            });
                        } catch (error) {
                            toast.update(toastId, {
                                render: `Failed to translate: ${questionItem?.languageObject?.Native} `,
                                type: "error",
                                isLoading: true
                            });
                            console.log(error);
                            console.log("START TRANSLATION FAILED", questionItem);
                            await updateDoc(doc(db, "questions", questionItem.uid), { status: QuestionStatusType.FAILED });
                        }
                    }
                }
            }
            toast.update(toastId, {
                render: `Question added successfully`,
                autoClose: 3000,
                type: "success",
                isLoading: false
            });
        } catch (error) {
            console.log(error);
            toast.update(toastId, {
                render: "Failed to create question...",
                autoClose: 3000,
                type: "error",
                isLoading: false
            });
        } finally {
            setLoading(false);
        }
        handleClose();
    }

    const onChange = e => {
        const key = e?.target?.name;
        const value = e?.target?.value;
        console.log("key", key);
        console.log("value", value);

        if (key) {
            onUpdate(key, value);
        }
    }


    const isValid = useMemo(() => {
        if (!question?.question) {
            return false;
        }
        if (questionType === QuestionTypes.SLIDER && !question?.from && !question?.to) {
            return false;
        }
        if (
            questionType === QuestionTypes.MULTICHOICE || questionType === QuestionTypes.DROPDOWN
            || questionType === QuestionTypes.MAPINPUT || questionType === QuestionTypes.CHECKBOXES
        ) {
            const options = question?.options?.filter(i => i?.option?.trim() || i?.other) || [];
            if (options?.length === 0) {
                return false;
            }
        }
        // if (questionType === QuestionTypes.SECTION || questionType === QuestionTypes.LOCATE_USER) {
        //     if (!question?.subTitle) {
        //         return false;
        //     }
        // }
        return true;
    }, [question, questionType])

    return (
        <Modal
            open={open}
            // onClose={handleClose}
            container={container}
            aria-labelledby="question-title"
            aria-describedby="question-description"
            disableEnforceFocus={true}
            disableScrollLock={true}
            disablePortal
            slotProps={{
                backdrop: {
                    sx: {
                        position: "fixed",
                        maxWidth: `calc(100% - ${aiDrawerOpen ? aiDrawerWidth : "0px"})`,
                        // height: container?.clientHeight || "100%",
                        backdropFilter: "blur(4px)",
                        bgcolor:'#fff',
                        zIndex: 2,
                    }
                }
            }}
            sx={{
                maxWidth: `calc(100% - ${aiDrawerOpen ? aiDrawerWidth : "0px"})`,
                ml: aiDrawerOpen ? "80px" : 0,
                position: "fixed", // Ensure modal is positioned within the parent
                zIndex: 3,
            }}
        >
            <Stack sx={{...style, transform: aiDrawerOpen ? "translate(-60%, -50%)" : "translate(-50%, -50%)",}} direction={"column"} gap={0}>
                <Typography fontWeight={400} variant={"h4"}
                    sx={{ p: 2 }}>{editQuestion ? "Edit" : "Create"} Question</Typography>
                <Stack direction={"column"} gap={3} sx={{ flex: 1, overflowY: "auto", p: 2 }}>
                    <SelectQuestionType type={questionType} setType={setQuestionType} />

                    <Box>
                        <InputWithLabel
                            label={questionType !== QuestionTypes.LOCATE_USER && questionType !== QuestionTypes.SECTION ? "Question" : "Title"}>
                            <TextField
                                value={question?.question} onChange={onChange} name={"question"}
                                fullWidth
                                placeholder={questionType !== QuestionTypes.LOCATE_USER && questionType !== QuestionTypes.SECTION ? "How are you today?" : "This is the banner respondents will see"}
                                multiline minRows={1} maxRows={2}
                            />
                        </InputWithLabel>
                        {question?.question?.length > 100 &&
                            <Typography fontWeight={400} fontSize={"0.75rem"}>
                                * Note that you are above 100 characters. Titles over 100 characters may display poorly
                                on mobile devices.
                            </Typography>
                        }
                        {
                            ![QuestionTypes.SECTION, QuestionTypes.LOCATE_USER].includes(questionType) ?
                              <InputWithLabel
                                label={"Description"}>
                                  <TextField
                                    value={question?.description} onChange={onChange} name={"description"}
                                    fullWidth
                                    placeholder={"This is the description of the question"}
                                    multiline minRows={1} maxRows={2}
                                  />
                              </InputWithLabel>
                              : null
                        }

                    </Box>

                    {
                        {
                            [QuestionTypes.SLIDER]: <SliderQuestion question={question} onChange={onChange} />,
                            [QuestionTypes.MULTICHOICE]: <OptionsQuestions question={question} onUpdate={onUpdate} />,
                            [QuestionTypes.DROPDOWN]: <OptionsQuestions question={question} onUpdate={onUpdate} />,
                            [QuestionTypes.MAPINPUT]: <OptionsQuestions question={question} onUpdate={onUpdate} />,
                            [QuestionTypes.CHECKBOXES]: <OptionsQuestions question={question} onUpdate={onUpdate} />,
                            [QuestionTypes.IMAGE_GALLERY]: <OptionsQuestions question={question} onUpdate={onUpdate} image={image} setImage={setImage} />,
                            [QuestionTypes.SECTION]: <SectionQuestion question={question} onUpdate={onUpdate}
                                onChange={onChange}
                                image={image} setImage={setImage} />,
                            [QuestionTypes.LOCATE_USER]: <SectionQuestion question={question} onUpdate={onUpdate}
                                onChange={onChange}
                                image={image} setImage={setImage} />,
                        }[questionType]
                    }
                    {
                        questionType === QuestionTypes.IMAGE_GALLERY &&
                        <FormControlLabel
                            color={"secondary"}
                            control={
                                <Switch checked={!!question?.multipleChoice}
                                    onChange={(event, checked) => onUpdate("multipleChoice", checked)} />
                            }
                            label="Allow multiple choices"
                        />
                    } 
                    {
                        questionType !== QuestionTypes.SECTION &&
                        <FormControlLabel
                            color={"secondary"}
                            control={
                                <Switch checked={!!question?.required}
                                    onChange={(event, checked) => onUpdate("required", checked)} />
                            }
                            label="Required"
                        />
                    }
                </Stack>

                <Stack direction={"row"} gap={1} justifyContent={"flex-end"} sx={{ px: 2, pt: 2 }}>
                    <Button
                        className={"gradient-outlined-btn"}
                        type={"button"}
                        variant={"outlined"} sx={{ borderRadius: 2 }}
                        onClick={handleClose} disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={"gradient-btn"}
                        sx={{ borderRadius: 2, color: "#fefefe", width: "auto !important" }}
                        onClick={onSubmit}
                        disabled={!isValid || loading}
                    >
                        Save
                    </Button>
                </Stack>
            </Stack>
        </Modal>
    )
}


export const InputWithLabel = ({
    label, children
}) => {

    return (
        <Box>
            <Typography gutterBottom fontWeight={500}>{label}</Typography>
            {children}
        </Box>
    )
}
