import DescriptionIcon from '@mui/icons-material/Description';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Checkbox, FormControlLabel, FormControl, Select, MenuItem, ListItemText, Paper, Stack, TextField, Typography, Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateSurveyBanner } from "../../components/shared-components/banner/create-survey-banner";
import LoadingScreen from "../../components/shared-components/LoadingScreen";
import { TitleDate } from "../../components/shared-components/text-formating/title-date/title-date";
import { AppContext } from "../../utils/AppContext";
import { EnglishNativeLanguage } from "../../utils/english-native-language";
import { QuestionTypes } from "../create-project-page-new/enums";
import SurveyQuestionSelectorDrodowns from "../../components/shared-components/SurveyQuestionSelectorDrodowns";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../utils/firebaseApp";
import Markdown from 'react-markdown'
import './markdown.css'
import { countDistinctObjectsByDay } from "../../utils/sharedFunctions";
import { toast } from "react-toastify";
import {renderBoldText} from "../../utils/functions";
// Selection Interface Component
const SelectionInterface = ({
    surveys,
    activeSurvey,
    setActiveSurvey,
    onlyOriginalQuestions,
    selectedQuestions,
    setSelectedQuestions,
    reportOptions,
    handleOptionChange,
    handleGetStarted
}) => (
    <Stack
        direction={"column"}
        gap={2}
        maxWidth={"lg"}
        sx={{ mx: { xs: 2, md: "auto" }, py: 2 }}
    >
        <TitleDate title="Ella Report Generator" big />

        <Stack spacing={2} sx={{ mt: 2 }}>
            {/* Survey Selection Dropdown */}
            <SurveyQuestionSelectorDrodowns
                surveys={surveys}
                showQuestionSelect={false}
                setActiveSurvey={setActiveSurvey}
                activeSurvey={activeSurvey}
            />

            {/* Question Selection Dropdown - Multiple Select */}
            <Stack>
                <FormControl fullWidth>
                    <Select
                        multiple
                        displayEmpty
                        value={selectedQuestions.map(q => q.uid)}
                        onChange={(event) => {
                            const selectedIds = event.target.value;
                            const newSelectedQuestions = onlyOriginalQuestions.filter(
                                question => selectedIds.includes(question.uid)
                            );
                            setSelectedQuestions(newSelectedQuestions);
                        }}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em>Select your questions</em>;
                            }
                            return `${selected.length} question${selected.length > 1 ? 's' : ''} selected`;
                        }}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 300
                                }
                            }
                        }}
                    >
                        <MenuItem disabled value="">
                            <em>Select your questions</em>
                        </MenuItem>
                        {onlyOriginalQuestions.map((question) => (
                            <MenuItem key={question.uid} value={question.uid}>
                                <Checkbox checked={selectedQuestions.some(q => q.uid === question.uid)} />
                                <ListItemText primary={question.question} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
        </Stack>

        <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
            What do you want your report to contain?
        </Typography>

        <Stack spacing={1}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={reportOptions.includeMethodology}
                        onChange={handleOptionChange}
                        name="includeMethodology"
                    />
                }
                label="Include a methodology section. This section will address items such as the question types used, whether it is primarily a quantitative or qualitative survey, etc."
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={reportOptions.includeQuantitativeAnalysis}
                        onChange={handleOptionChange}
                        name="includeQuantitativeAnalysis"
                    />
                }
                label="Include a quantitative analysis section. This section will cover a statistical breakdown question by question, reporting all relevant and topline numbers as well as highlight statistically significant findings."
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={reportOptions.includeOutliersAnalysis}
                        onChange={handleOptionChange}
                        name="includeOutliersAnalysis"
                    />
                }
                label="Include an outliers analysis, to identify and analyze responses that deviate significantly from the norm."
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={reportOptions.useFullQuotes}
                        onChange={handleOptionChange}
                        name="useFullQuotes"
                    />
                }
                label="Use full quotes. When including quotes, ensure complete quotes are included rather than partial snippets."
            />
        </Stack>

        <Button
            variant="contained"
            color="primary"
            onClick={handleGetStarted}
            disabled={!activeSurvey || selectedQuestions.length === 0}
            sx={{ mt: 2, width: "fit-content" }}
        >
            Get started
        </Button>
    </Stack>
);

// Report Interface Component
const ReportInterface = ({
    userInstruction,
    setUserInstruction,
    isGeneratingReport,
    generateReport,
    reportText,
    messages,
    setMessages
}) => (
    <Stack direction="row" gap={2} alignItems={"stretch"} sx={{ height: "calc(100dvh - 64px - 48px)" }}>
        <Stack
            direction={"column"}
            sx={{ width: "50%", overflowY: "auto" }}
        >
          <TitleDate title="Ella Report Generator" big />
            <Stack direction={"column"} gap={2} sx={{flex: 1, my: 2, overflowY: "auto"}} fullWidth>
                  <Box>
                    <Paper sx={{px: 0, py: 1.5, boxShadow: 0}}>
                      <Typography
                        variant={'body1'}
                        sx={{
                          whiteSpace: 'pre-line', color: '#2F2F2F', fontSize: "0.9rem", fontWeight: 300
                        }}
                      >
                        I'm going to use all of the questions you selected and analyze their
                        responses. If you have custom instructions like skipping writing an
                        introduction, please let me know. Then I'll get started.
                      </Typography>
                    </Paper>
                  </Box>

                {/* Display message history */}
                    {messages.slice(1).map((message, index) => (
                        <Paper
                            key={index}
                            sx={{
                                bgcolor: message.role === "user" ? "#e3f2fd" : "transparent",
                                px: message.role === "user" ? 2 : 0, py: 1.5, boxShadow: 0,
                                borderRadius: 2,
                                ml: message.role === "user" ? "auto" : 0,
                                mr: message.role === "assistant" ? "auto" : 0,
                                // maxWidth: "80%"
                            }}
                        >
                          <Typography
                            variant={'body1'}
                            sx={{
                              whiteSpace: 'pre-line', color: '#2F2F2F', fontSize: "0.9rem", fontWeight: 300
                            }}
                            dangerouslySetInnerHTML={{ __html: renderBoldText(message.content || "") }} // Ensures formatted output
                          />
                        </Paper>
                    ))}
            </Stack>
          <Box sx={{bgcolor: "background.main"}}>
            <TextField
              fullWidth
              multiline
              rows={3}
              placeholder="Custom instructions here. E.g: Focus particularly on question #2..."
              value={userInstruction}
              onChange={(e) => setUserInstruction(e.target.value)}
            />

            <Button
              variant="contained" color="primary" fullWidth
              endIcon={<DescriptionIcon />}
              onClick={generateReport}
              disabled={isGeneratingReport}
            >
              {isGeneratingReport ? "Generating..." : "Generate Report"}
            </Button>
          </Box>
        </Stack>

        <Stack
            direction={"column"}
            sx={{
                width: "50%",
                bgcolor: "#f9f9f9",
                overflowY: "auto",
                position: "relative",
                borderRadius: 5
            }}
        >
            <Box
                elevation={1}
                sx={{
                    p: 3,
                    minHeight: "80vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: reportText ? "flex-start" : "center",
                    alignItems: reportText ? "flex-start" : "center",
                    color: 'black',
                    pb: "60px"
                }}
            >
                {reportText ? (
                    <div className="report-markdown-content">
                        <Markdown>
                            {reportText}
                        </Markdown>
                    </div>
                ) : (
                    <Typography variant="body1" color="text.secondary">
                        Your report will appear here
                    </Typography>
                )}
            </Box>
        </Stack>
        {reportText && (
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CopyIcon />}
                    sx={{ position: "absolute", bottom: 30, right: 30 }}
                    onClick={() => {
                        // Get the rendered markdown content using our custom class
                        const markdownContainer = document.querySelector('.report-markdown-content');

                        if (!markdownContainer) {
                            toast.error('Could not find rendered markdown content. Please try selecting and copying manually.');
                            return;
                        }

                        // Create a temporary element to hold the HTML content
                        const tempDiv = document.createElement('div');
                        tempDiv.innerHTML = markdownContainer.innerHTML;
                        document.body.appendChild(tempDiv);

                        try {
                            // Select the text
                            const range = document.createRange();
                            range.selectNode(tempDiv);
                            const selection = window.getSelection();
                            selection.removeAllRanges();
                            selection.addRange(range);

                            // Copy the formatted HTML to clipboard
                            document.execCommand('copy');
                            selection.removeAllRanges();

                            // Notify user
                            toast.success('Report copied! You can now paste into Word or Google Docs.');
                        } catch (error) {
                            console.error('Copy failed:', error);
                            toast.error('Copy failed. Please try selecting and copying the text manually.');
                        } finally {
                            // Clean up
                            document.body.removeChild(tempDiv);
                        }
                    }}
                >
                    Copy Report
                </Button>
            )}
    </Stack>
);

// Main Component
export const ReportWriterPage = () => {
    const { state: { surveys, questionObjects, answerObjects } } = useContext(AppContext);
    const [activeSurvey, setActiveSurvey] = useState(surveys.length > 0 ? surveys[0] : null);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [onlyOriginalQuestions, setOnlyOriginalQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showReportInterface, setShowReportInterface] = useState(false);
    const [reportText, setReportText] = useState("");
    const [reportOptions, setReportOptions] = useState({
        includeMethodology: false,
        includeQuantitativeAnalysis: false,
        includeOutliersAnalysis: false,
        useFullQuotes: false
    });
    const navigate = useNavigate();
    const [userInstruction, setUserInstruction] = useState("");
    const [isGeneratingReport, setIsGeneratingReport] = useState(false);
    const generateReportGpt = httpsCallable(functions, "generateReport");

    // Add state for message history
    const [messages, setMessages] = useState([]);

    // Initialize system message when component mounts
    useEffect(() => {
        // Initialize with system message
        setMessages([
            {
                role: "system",
                content: "You are a PhD-level expert in survey design, specializing in crafting thorough, unbiased, and high-quality reports based on surveys."
            }
        ]);
    }, []);

    // Handle checkbox changes
    const handleOptionChange = (event) => {
        setReportOptions({
            ...reportOptions,
            [event.target.name]: event.target.checked
        });
    };

    useEffect(() => {
        if (!questionObjects?.length || !activeSurvey) return;

        const surveyEnglishQuestions = questionObjects.filter(
            (questionObject) => (
                questionObject?.surveyUid === activeSurvey?.uid
                && questionObject?.languageObject?.BCP47 === EnglishNativeLanguage.BCP47
                && QuestionTypes.SECTION !== questionObject?.type
            )
        )?.sort((a, b) => a?.orderNumber - b?.orderNumber) || [];

        setOnlyOriginalQuestions(surveyEnglishQuestions || []);
    }, [activeSurvey, questionObjects]);

    const handleGetStarted = () => {
        // Save selections and transition to report interface
        if (!activeSurvey || selectedQuestions.length === 0) {
            // Show error or validation message
            return;
        }

        setShowReportInterface(true);
    };

    const generateReport = async () => {
        setIsGeneratingReport(true);
        const filteredAnswerObjects = answerObjects.filter(answer =>
            selectedQuestions.some(question => question.uid === answer.questionUid)
        );
        try {
            // Create base sections of the prompt
            const introSection = `
            You are a **PhD-level expert in survey design**, specializing in crafting **thorough, unbiased, and high-quality reports based on surveys**. 
            Your primary goal is to write comprehensive, quantitative and qualitative mixed-methods reports to thoroughly report the results of a survey.

            Custom Instructions (Custom instructions supersede any other instructions. Prioritize fulfilling the custom instructions request):
            ${userInstruction || "No specific instructions provided."}

            Core Responsibilities
            Analyze survey data thoroughly and objectively
            Generate insights from both quantitative and qualitative responses
            Present findings in a clear, well-structured report
            Maintain neutrality and avoid introducing bias into interpretations
            Identify patterns and trends across responses
            `;

                        // Build the report structure section based on selected options
                        let reportStructureSection = `
            Report Structure
            Unless indicated in 'Custom Instructions', your report should always include:
            Executive Summary: Brief overview of key findings and insights
            `;

                        // Add conditional sections based on checkbox selections
                        if (reportOptions.includeMethodology) {
                            reportStructureSection += `Methodology: Survey methodology, addressing items like question types used\n`;
                        }

                        if (reportOptions.includeQuantitativeAnalysis) {
                            reportStructureSection += `Quantitative Analysis: Statistical breakdown question by question. Utilize the provided 'JSON statistical data' to perform reporting of each question, reporting all relevant and topline numbers as well as highlight statistically significant findings.\n`;
                        }

                        reportStructureSection += `Qualitative Analysis: Thematic analysis of open-ended responses with representative quotes
            Key Findings: Synthesis of the most significant insights across all question types
            Conclusion: Data-driven conclusive remarks based on the survey results
            `;

                        // Add outliers analysis if selected
                        if (reportOptions.includeOutliersAnalysis) {
                            reportStructureSection += `Separately after you finished the report, write a concise yet thorough outliers analysis by cross-referencing the 'JSON statistical data' with the 'JSON Responses data' to identify and analyze responses that deviate significantly from the norm. Addressing these outliers, exploring potential explanations and implications.\n`;
                        }

                        // Add existing report handling
                        const existingReportSection = `
            If there is an already existing report draft in our conversation messages, you must use the latest Current Version of the report in your response.
            `;

                        // Data handling guidelines
                        const dataHandlingSection = `
            Data Handling Guidelines
            Treat all survey data as confidential and maintain respondent anonymity if personal information is mentioned within the responses, anonymize when reporting
            Identify and account for potential sampling biases in your analysis
            Consider both statistical significance and practical significance in your findings
            When sample sizes are small, explicitly acknowledge limitations in generalizability
            Present contradictory findings fairly and explore possible explanations. However do not engage in two-sideism when one answer or finding is clearly correct. You must be hyperclear.
            `;

                        // Response format section with conditional quotes handling
                        const responseFormatSection = `
            Response Format
            Always use markdown formatting for your reports
            Include headings, subheadings, and bullet points and lists for clarity
            Support qualitative analyses with direct quotes when appropriate
            ${reportOptions.useFullQuotes
                                ? "When using quotes you must use the complete verbatim quote, never using partial snippets. Group similar quotes thematically and highlight particularly insightful or representative responses."
                                : "You may use partial quotes when appropriate to highlight key points."}
            Craft your report to be accessible to both technical and non-technical audiences
            Remember to maintain a balanced, objective tone throughout your report while providing insightful analysis that helps users understand the full implications of their survey data.
            `;

            // Special analysis options section
            const specialAnalysisSection = `
                Special Analysis Options
                Based on user selection, your report will include different analytical components:
                ${reportOptions.useFullQuotes ? "Full Quotes Analysis: Incorporate complete verbatim quotes from respondents to provide rich context and authentic voice. Group similar quotes thematically and highlight particularly insightful or representative responses." : ""}
                ${reportOptions.includeQuantitativeAnalysis ? "Statistical Analysis: Utilize the provided JSON statistical data to perform in-depth analysis of each question. Include measures of central tendency, variance, correlation analysis where appropriate, and highlight statistically significant findings." : ""}
                ${reportOptions.includeOutliersAnalysis ? "Outliers Analysis: Examine the provided JSON outlier data to identify and analyze responses that deviate significantly from the norm. Create a dedicated section addressing these outliers, exploring potential explanations and implications without compromising the thoroughness of the main report." : ""}
                `;

            // Create a structured JSON object of questions and answers
            const reportQuestionsAndAnswers = selectedQuestions.map(question => {
                const questionAnswers = answerObjects.filter(
                    answer => answer.questionUid === question.uid
                ).map(answer => answer.text);
                console.log(questionAnswers)
                return {
                    questionUid: question.uid,
                    questionText: question.question,
                    questionType: question.type,
                    answers: questionAnswers
                };
            });

            // Convert to JSON string for inclusion in the prompt
            const questionsAnswersJSON = JSON.stringify(reportQuestionsAndAnswers, null, 2);

            const numberOfRespondents = countDistinctObjectsByDay(
                [
                    ...new Map(
                        filteredAnswerObjects
                            ?.sort((a, b) => a.timestamp - b.timestamp)
                            ?.filter(
                                (answer) => answer.surveyUid === activeSurvey?.uid,
                            )
                            ?.map((item) => [item["respondentUid"], item]),
                    ).values(),
                ],
                "timestamp",
            )

            // Calculate the number of answers for the selected questions only
            const numberOfAnswers = filteredAnswerObjects.length;

            const numberOfQuestions = selectedQuestions.length;

            // Calculate average number of words per answer for text and voice answers only
            const textAndVoiceAnswers = filteredAnswerObjects.filter(answer => {
                // Find the corresponding question
                const question = questionObjects.find(q => q.uid === answer.questionUid);

                // Check if it's a text or voice question type
                return question && (
                    question.type === QuestionTypes.TEXT ||
                    question.type === QuestionTypes.VOICE
                )
            });

            const numberOfTextAndVoiceAnswers = textAndVoiceAnswers.length;
            const countOfAnswersPerQuestionType = selectedQuestions.reduce((acc, question) => {
                acc[question.type] = (acc[question.type] || 0) + 1;
                return acc;
            }, {});

            const averageNumberOfWordsPerAnswer = numberOfTextAndVoiceAnswers > 0
                ? textAndVoiceAnswers.reduce((acc, answer) => {
                    return acc + (answer.text ? answer.text.split(' ').length : 0);
                }, 0) / numberOfTextAndVoiceAnswers
                : 0;

            const questionStatsJSON = {
                numberOfRespondents: numberOfRespondents,
                numberOfQuestions: numberOfQuestions,
                numberOfAnswers: numberOfAnswers,
                countOfAnswersPerQuestionType: countOfAnswersPerQuestionType,
                averageNumberOfWordsPerAnswer: averageNumberOfWordsPerAnswer
            };
            console.log(questionStatsJSON);
            // Combine all sections to create the complete system prompt
            const completeSystemPrompt = [
                introSection,
                reportStructureSection,
                existingReportSection,
                dataHandlingSection,
                responseFormatSection,
                specialAnalysisSection,
                JSON.stringify(reportQuestionsAndAnswers),
                JSON.stringify(questionStatsJSON)
            ].join('\n');

            console.log(completeSystemPrompt)

            // Create a system message with the complete prompt
            const systemMessage = {
                role: "system",
                content: completeSystemPrompt
            };

            // Create a user message with the survey data
            console.log(reportQuestionsAndAnswers)
            const questions = reportQuestionsAndAnswers.map(question => question.questionText).join(', ');
            const userMessage = {
                role: "user",
                content: `Please generate a report for the following questions: ${questions}`
            };


            // Add user message to the message history
            const updatedMessages = [...messages, userMessage];
            setMessages(updatedMessages);

            // Create the messages array to send to the API
            // We'll use the first system message and all subsequent messages
            const messagesToSend = [systemMessage, ...updatedMessages.slice(1)];

            // Send the request with the messages
            const response = await generateReportGpt({
                messages: messagesToSend
            });

            // Handle the response
            if (response && response.data && response.data.message) {
                const assistantMessage = response.data.message;

                // Add assistant message to the message history
                setMessages([...updatedMessages, assistantMessage]);

                // Update the report text
                setReportText(assistantMessage.content);
            } else {
                setReportText("Failed to generate report. Please try again.");
            }
        } catch (error) {
            console.error(error);
            setReportText("An error occurred while generating the report. Please try again.");
        } finally {
            setIsGeneratingReport(false);
        }
    };

    if (loading) {
        return <LoadingScreen />;
    }

    if (surveys.length === 0) {
        return (
            <Stack
                direction={"column"}
                gap={2} maxWidth={"lg"}
                sx={{ mx: { xs: 2, md: "auto" } }}
            >
                <TitleDate title="Report Writer" big />
                <CreateSurveyBanner />
            </Stack>
        );
    }

    if (showReportInterface) {
        return (
            <ReportInterface
                userInstruction={userInstruction}
                setUserInstruction={setUserInstruction}
                isGeneratingReport={isGeneratingReport}
                generateReport={generateReport}
                reportText={reportText}
                messages={messages}
                setMessages={setMessages}
            />
        );
    }

    return (
        <SelectionInterface
            surveys={surveys}
            activeSurvey={activeSurvey}
            setActiveSurvey={setActiveSurvey}
            onlyOriginalQuestions={onlyOriginalQuestions}
            selectedQuestions={selectedQuestions}
            setSelectedQuestions={setSelectedQuestions}
            reportOptions={reportOptions}
            handleOptionChange={handleOptionChange}
            handleGetStarted={handleGetStarted}
        />
    );
};
