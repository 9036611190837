import {atom} from "jotai";
import uuid from "react-uuid";


export const initialSurvey = {
  uid: null,
  userUid: null,
  dateCreated: null,
  questions: [],
  translations: [{language: 'en', uid: uuid()}],
  getUserIPAddress: false,
  saveAudioRecordings: false,
  surveyName: "",
  surveyDescription: '',
  start: null,
  end: null,
  languages: [{
    "Name": "English (United States)",
    "BCP47": "en-US",
    "Native": "English"
  }],
  archived: false
}

export const CreateSurveyState = atom({...initialSurvey});
export const CreateSurveyQuestionsState = atom([]);

export const SelectedLanguageState = atom(null);
export const AiDrawerState = atom(false);

export const CreateSurveySettingsState = atom(false);

export const CreateQuestionModalState = atom(false);
export const EditQuestionModalState = atom(null);

export const VoiceRecordingState = atom(false);
export const CreateThankYouQuestionState = atom(false);
