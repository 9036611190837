import {Box, Stack, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {TitleDate} from "../../components/shared-components/text-formating/title-date/title-date";
import {AppContext} from "../../utils/AppContext";
import SurveyQuestionSelectorDrodowns from "../../components/shared-components/SurveyQuestionSelectorDrodowns";
import {countDistinctObjectsByDay, countTopics, saveSelectedSurveyAsCSV,} from "../../utils/sharedFunctions";
import {TotalParticipantChart} from "./components/TotalParticipantChart";
import {QuestionGraphWrapper} from "../analysis-page/components/question-graphs/QuestionGraphWrapper";
import {CardItem} from "../../components/card/CardItem";
import {Actions} from "./components/Actions";
import {collection, doc, getDoc, getDocs, orderBy, query, updateDoc, where} from "firebase/firestore";
import {auth, db} from "../../utils/firebaseApp";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import LoadingScreen from "../../components/shared-components/LoadingScreen";
import {CreateSurveyBanner} from "../../components/shared-components/banner/create-survey-banner";

export const ProjectSummariesPage = ({survey}) => {
    const {
        state: { surveys, currentUser, surveyObjectToDisplay, questionObjects, answerObjects},
        dispatch
    } = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [activeSurvey, setActiveSurvey] = useState(surveyObjectToDisplay ? surveyObjectToDisplay : surveys[0]);
    const [surveyQuestions, setSurveyQuestions] = useState([]);

    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSurvey = async () => {
            try {
                setLoading(true);

                const docRef = doc(db, "surveys", id);
                const surveySnapshot = await getDoc(docRef);
                if (!surveySnapshot.exists()) navigate('/404');

                setActiveSurvey({...surveySnapshot.data()});

            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }

        if (surveys?.length) {
            if (!id){
                const surveyUid = surveys[0]?.uid || null;
                if (surveyUid) {
                    navigate(`/summaries/${surveyUid}`)
                }
            } else {
                const survey = surveys?.find(i => i?.uid === id);
                if (survey) {
                    setActiveSurvey(survey);
                } else {
                    fetchSurvey();
                }
            }
        }
    }, [id, surveys])

    useEffect(() => {
        updateAnswers();
    }, []);

    useEffect(() => {
        if (!activeSurvey) return;
        let allQuestionObjectsForSurvey = questionObjects.filter(
            (question) => question?.surveyUid === activeSurvey?.uid,
        );
        let uniqueQuestionObjectsForSurvey = allQuestionObjectsForSurvey.filter(
            (question) =>
                question?.languageObject.BCP47 === "en-US" //activeSurvey?.languages[0].BCP47,
        )?.sort((a, b) => a?.orderNumber - b?.orderNumber);

        setSurveyQuestions(uniqueQuestionObjectsForSurvey);
    }, [activeSurvey, answerObjects]);

    const updateAnswers = async () => {
        if (currentUser && currentUser?.uid) {
            const answerQuery = query(
              collection(db, "answers"),
              where("userUid", "==", currentUser?.uid),
              orderBy("timestamp"),
            );
            const answerSnapshot = await getDocs(answerQuery);
            let updatedAnswerObjects = [];
            answerSnapshot.forEach((doc) => {
                updatedAnswerObjects.push(doc.data());
            });

            dispatch({answerObjects: [...updatedAnswerObjects]});
        }
    };

    function answersToCsv() {
        if (activeSurvey) {
            console.log(activeSurvey);
            let surveyObject = {
                ...activeSurvey,
                answers: answerObjects?.filter(item => item?.surveyUid === activeSurvey?.uid),
                questions: questionObjects?.filter(item => item?.surveyUid === activeSurvey?.uid),
            };
            saveSelectedSurveyAsCSV(surveyObject);
        }
    }

    function getTopics() {
        let answersWithTopics = answerObjects.filter((answer) =>
            answer.hasOwnProperty("topic"),
        );

        return countTopics(answersWithTopics);
    }

    function getXaxisLabels() {
        return Object.keys(
            countDistinctObjectsByDay(
                [
                    ...new Map(
                        answerObjects
                            ?.sort((a, b) => a.timestamp - b.timestamp)
                            ?.filter(
                                (answer) => answer.surveyUid === activeSurvey?.uid,
                            )
                            ?.map((item) => [item["respondentUid"], item]),
                    ).values(),
                ],
                "timestamp",
            ),
        )
    }

    function getYaxisData() {
        return Object.values(
            countDistinctObjectsByDay(
                [
                    ...new Map(
                        answerObjects
                            .sort((a, b) => a.timestamp - b.timestamp)
                            .filter(
                                (answer) => answer.surveyUid === activeSurvey?.uid,
                            )
                            .map((item) => [item["respondentUid"], item]),
                    ).values(),
                ],
                "timestamp",
            ),
        )
    }

    function getHeadingForSummariesPage() {
        return (
            <>
                <TitleDate title="Survey Summary" big/>
                <Stack sx={{flexDirection: {xs: "column", md: "row"} }} gap={2} justifyContent={"space-between"} useFlexGap flexWrap={"wrap"}>
                    <Box sx={{maxWidth: "300px", width: '100%'}}>
                        <SurveyQuestionSelectorDrodowns
                          showQuestionSelect={false}
                          answerObjects={answerObjects}
                          questionObjects={surveyQuestions}
                          setActiveSurvey={(survey) => survey?.uid && navigate(`/summaries/${survey?.uid}`)}
                          activeSurvey={activeSurvey}
                          setActiveQuestion={setActiveQuestion}
                          surveys={surveys}
                          activeQuestion={activeQuestion}
                        />
                    </Box>
                    <Box sx={{mt: 1}}>
                        <Actions
                          downloadAnswers={answersToCsv}
                          activeSurvey={activeSurvey}
                        />
                    </Box>
                </Stack>
            </>
        )
    }

    if (loading) {
        return <LoadingScreen/>
    }

    if (surveys.length === 0) {
        return (
          <Stack
            direction={"column"}
            gap={2} maxWidth={"lg"}
            sx={{mx: {xs: 2, md: "auto"}}}
          >
              <TitleDate title="Survey Summary" big/>
              <CreateSurveyBanner/>
          </Stack>
        );
    }

    return (
        <>
            <Stack
                direction={"column"}
                gap={2} maxWidth={"lg"}
                sx={{mx: {xs: 2, md: "auto"}, pb: "100px"}}
            >
                {/*//When we pass in the parameter survey we don't need the survey selectors and all that jazz*/}
                {!survey && getHeadingForSummariesPage()}

                <CardItem
                  title={<Typography fontSize={16} fontWeight={"bold"} color={"#404040"}>Total number of Participants</Typography>}
                  subheader={<Typography fontSize={12} variant={"secondary2"} color={"#858585"}>Line chart</Typography>}
                  collapsable={true}
                >
                    <TotalParticipantChart xAxisLabels={getXaxisLabels()} xAxisData={getYaxisData()}/>
                </CardItem>

                {surveyQuestions
                    .sort((a, b) => a.orderNumber - b.orderNumber)
                    ?.map((item, index) =>
                        item.type !== "section" ? (
                            <QuestionGraphWrapper
                                setActiveQuestion={setActiveQuestion}
                                key={item.uid}
                                question={item}
                                index={index + 1}
                                collapsable={true}
                            />
                        ) : null,
                    )}
            </Stack>
        </>
    );
};
