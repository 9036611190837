export const topics = [
    {topic: 'Fire', year: 1994},
    {topic: 'Water', year: 1972},
    {topic: 'Earth', year: 1974},
    {topic: 'Air', year: 1974}
]

export const topicColors = [
    "#FFD1DC",  // Pastel Pink
    "#B5EAD7",  // Mint Green
    "#FFECB5",  // Buttery Yellow
    "#D5E4FE",  // Baby Blue
    "#E6C0E9",  // Soft Orchid
    "#CFF1A1",  // Pastel Lime
    "#FFDDC1",  // Light Peach
    "#AFC8AD",  // Dusty Green
    "#F5D5E0",  // Light Rose
    "#E2D2F9",  // Soft Periwinkle
    "#C2DFFF",  // Sky Blue
    "#B2EBF2",  // Soft Aqua
    "#A7E8BD",  // Cool Mint
    "#D8E2A4",  // Olive Pastel
    "#FDE9C9",  // Warm Vanilla
    "#FFD4CF",  // Pale Coral
    "#E1BEE7",  // Muted Purple
    "#D4C5E2",  // Soft Plum
    "#FFCBCB",  // Soft Pink
    "#F7C8DA",  // Blush Pink
    "#E5D4EF",  // Lavender
    "#C5E2E7",  // Light Teal
    "#E6F5C9",  // Soft Yellow-Green
    "#FFF2B2",  // Light Yellow
    "#FFD9A5",  // Pale Apricot
    "#E8D1A5",  // Sand Beige
    "#F1D6B8",  // Warm Cream
    "#D8BFD8",  // Light Mauve
    "#C4A3BF",  // Muted Lilac
    "#B6D7A8"   // Pastel Sage
]
