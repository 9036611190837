import {QuestionTypes} from "../pages/create-project-page-new/enums";

export const enums = {
  DASHBOARD: "dashboard",
  SURVEYS: "surveys",
  SUMMARIES: "summaries",
  ANALYSIS: "analysis",
  CREATESURVEY: "createSurvey",
  serverUrl: "",
  YES: "YES",
  NO: "NO",
  TEXT: 'text',
  VOICE: 'voice',
  MULTICHOICE: 'multichoice',
  DROPDOWN: 'dropdown',
  CHECKBOXES: 'checkboxes',
  MAPINPUT: 'mapInput',
  IMAGE: "image",
  IMAGE_GALLERY: "imageGallery",
  SECTION: "section",
  LOCATE_USER: 'locateuser',
  SLIDER: 'slider',
  LANGUAGESELECT: 'languageselect',
  LANETITLE: "Unclassified Words",
  MAX_FREE_WORKSPACE_MEMBERS: {
    UNPAID: 0,
    FREE: 3,
    MONTHLY: 5,
    ANNUAL: 5
  },
  MAX_SURVEYS: {
    UNPAID: 0,
    FREE: 3,
    MONTHLY: 30,
    ANNUAL: 300
  },
  MAX_RESPONSES_PER_MONTH: {
    UNPAID: 0,
    FREE: 10,
    MONTHLY: 1000,
    ANNUAL: 5000
  },
  ADMIN: "ADMIN",
  EDITOR: "EDITOR",
  VIEWER: "VIEWER",
  FREE: "FREE",
  MONTHLY: "MONTHLY",
  ANNUAL: "ANNUAL",
  UNPAID: "UNPAID",
}


export const QUESTION_TYPES_FOR_LOGIC = [
  enums.MULTICHOICE,
  enums.DROPDOWN,
  enums.CHECKBOXES,
  enums.IMAGE_GALLERY
  // enums.MAPINPUT
];
export const QUESTION_TYPES_FOR_OTHER = [QuestionTypes.CHECKBOXES, QuestionTypes.MULTICHOICE]


