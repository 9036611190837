import React, {useContext, useMemo} from 'react';
import PropTypes from 'prop-types';
import { Chip, IconButton, Stack, Typography, useTheme, Box } from "@mui/material";
import moment from "moment/moment";
import AbcIcon from "@mui/icons-material/Abc";
import { grey } from "@mui/material/colors";
import LanguageIcon from "@mui/icons-material/Language";
import { EditableTextField } from "./EditableTextField";
import { doc, updateDoc, writeBatch } from "firebase/firestore";
import { db } from "../../../utils/firebaseApp";
import { toast } from "react-toastify";
import { AppContext } from "../../../utils/AppContext";
import { confirm } from "react-confirm-box";
import { confirmDialogOptions } from "../../../components/ConfirmDialog";
import { DeleteOutline } from "@mui/icons-material";
import { EnglishNativeLanguage } from "../../../utils/english-native-language";
import CustomAudioPlayer from "./CustomAudioPlayer";
import { QuestionTypes } from "../../create-project-page-new/enums";

export const Response = ({ response, question }) => {
  const theme = useTheme();
  const { state: { answerObjects }, dispatch } = useContext(AppContext);

  async function updateAnswer(text, type) {
    const toastId = toast.loading("Updating answer...");
    try {
      switch (type?.toLowerCase()) {
        case "original answer":
          await updateDoc(doc(db, "answers", response.uid), { text: text });
          answerObjects.forEach((answer) => {
            if (answer.uid === response.uid) {
              answer.text = text;
            }
          })
          break;
        case "translation":
          await updateDoc(doc(db, "answers", response.uid), { translation: text });
          answerObjects.forEach((answer) => {
            if (answer.uid === response.uid) {
              answer.translation = text;
            }
          })
          break;
      }

      dispatch({ answerObjects: answerObjects })
      toast.update(toastId, {
        render: `Answer updated successfully`,
        type: "success", autoClose: 3000,
        isLoading: false
      });
    } catch (e) {
      toast.update(toastId, {
        render: `Error updating answer`,
        type: "error", autoClose: 3000,
        isLoading: false
      });
    }
  }

  async function removeResponse(uid) {
    const response = await confirm(
      {
        title: `Are you sure you want to delete this answer?`,
        subtitle: `This action will permanently delete this response`,
      },
      confirmDialogOptions,
    );
    if (response === false) {
      return;
    }
    const toastId = toast.loading("Removing your response...");

    const batch = writeBatch(db);
    const responseRef = doc(db, "answers", uid);
    batch.delete(responseRef);
    batch.commit().then(() => {
      const newAnswers = answerObjects.filter((answer) => answer.uid !== uid);
      dispatch({ answerObjects: newAnswers })
      toast.update(toastId, {
        render: `Response is removed`,
        type: "success", autoClose: 3000,
        isLoading: false
      });
    }).catch((error) => {
      console.log(error);
      toast.update(toastId, {
        render: `Failed to remove response`,
        type: "error", autoClose: 3000,
        isLoading: false
      });
    })
  }
  if (response?.type === QuestionTypes.IMAGE && (!response?.photos || response?.photos?.length === 0)) {
    return null;
  }

  const checkForOptions = (text) => {
    if (response?.options?.length > 0) {
      return response?.options?.filter((option) => option.checked).map((option) => option.option).join(", ");
    }
    return text;
  }

  const topics = question?.topics?.filter(i => response?.classifiedTopics?.includes(i?.uid))

  return (
    <Stack direction={"column"} gap={1} sx={{ borderBottom: 1, minHeight: "15vh", borderColor: "divider", pb: 2, pt: 1 }} key={response.uid}>
      <Stack
        direction={"row"}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "flex-start", md: "space-between" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
        gap={1}
      >
        <Typography variant={"body2"}>
          {moment(response?.timestamp).format("MMMM DD, yyyy HH:mm")}
        </Typography>

        <Stack direction={"row"} gap={1.5}>
          {response?.topic && response?.topic !== "" && (
            <Chip
              icon={<AbcIcon fontSize={"small"} />}
              label={response?.topic}
              sx={{ bgcolor: grey[200], color: grey[700] }}
            />
          )}

          <Stack direction={"row"} gap={1} alignItems={"center"} sx={{ color: grey[600] }}>
            <LanguageIcon fontSize={"small"} />
            <Typography variant={"subtitle2"} color={grey[600]}
              fontSize={"0.9rem"}>{response?.languageObject?.Native}</Typography>
          </Stack>

          <IconButton onClick={() => removeResponse(response.uid)}
            sx={{ color: theme.palette.error.main }}>
            <DeleteOutline fontSize={"small"} />
          </IconButton>
        </Stack>
      </Stack>
      {
        response.voiceUrl
        && <CustomAudioPlayer audioUrl={response?.voiceUrl} />
      }
      

      {[QuestionTypes.TEXT, QuestionTypes.VOICE].includes(response?.type)
      &&  <EditableTextField initialText={checkForOptions(response?.text)} onSave={updateAnswer} type={'Original Answer'} fullResponse={response} />}
      
      {
        response?.languageObject?.BCP47 !== EnglishNativeLanguage?.BCP47 &&
        <EditableTextField
          initialText={response?.translation || ""}
          placeholder={"No translation for this response"}
          type={'Translation'}
          onSave={updateAnswer}
        />
      }

      <Stack direction={"row"} gap={1}>
        {topics?.map((topic) => (
          <Chip key={topic?.uid || topic} label={topic?.name || topic} sx={{ bgcolor: topic?.color || grey[200], mr: 1 }} />
        ))}
      </Stack>
      {
        response?.photos?.length > 0 && (
          <Stack direction="column" spacing={2} sx={{ mt: 1 }}>
            {response.photos.map((photoUrl, index) => {
              // Find the matching detected objects data for this photo URL
              const photoData = response?.detectedObjects?.find(data => data.photoUrl === photoUrl);
              const hasObjects = photoData?.objects && photoData.objects.length > 0;
              
              return (
                <Box key={`photo-${index}`}>
                  {/* Photo thumbnail */}
                  <Box sx={{ mb: hasObjects ? 1 : 0 }}>
                    <img 
                      src={photoUrl} 
                      alt={`Photo ${index + 1}`} 
                      style={{ 
                        maxWidth: '100px', 
                        maxHeight: '100px', 
                        objectFit: 'cover',
                        borderRadius: '4px'
                      }} 
                    />
                  </Box>
                  
                  {/* Display object chips if available */}
                  {hasObjects && (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      {photoData.objects.map((object, objIndex) => (
                        <Chip 
                          key={`obj-${index}-${objIndex}`}
                          label={object}
                          sx={{ bgcolor: grey[200], color: grey[700], mr: 1, mb: 1 }}
                        />
                      ))}
                    </Box>
                  )}
                </Box>
              );
            })}
          </Stack>
        )
      }

    </Stack>
  );
}

Response.propTypes = {
  response: PropTypes.object.isRequired,
};

export default Response;
