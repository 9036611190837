import {Avatar, Button, IconButton, Stack, TextField, Typography, Select, MenuItem} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {useState, useEffect} from "react";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../../utils/firebaseApp";
import {enums} from "../../../utils/enums";
import {useAuthContext} from "../../../contexts/auth_context";
import {toast} from "react-toastify";
import {Tooltip} from "@mui/material";
import Box from "@mui/material/Box";

export const MemberList = ({members, onAddMember, onUpdateMember, onRemoveMember, workspace, addingInProgress}) => {
  const [newMemberEmail, setNewMemberEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState(enums.VIEWER);
  const [userEmails, setUserEmails] = useState([]);
  const {user} = useAuthContext();
  const handleAddMember = async () => {
    console.log(userEmails);
    if (newMemberEmail === user.email || newMemberEmail === "" || !newMemberEmail.includes("@") || userEmails.find(email => email.email === newMemberEmail)) {
      toast.error("Please enter a valid email address");
      return;
    }

    if (newMemberEmail && members.length < (workspace.membersPaidFor)) {
      await onAddMember(newMemberEmail, selectedRole);
      setNewMemberEmail("");
      setSelectedRole(enums.VIEWER);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersRef = collection(db, "users");
        const memberUids = members.map(member => member.uid);

        if (memberUids.length === 0) return;

        const q = query(usersRef, where("uid", "in", memberUids));
        const querySnapshot = await getDocs(q);

        const emails = [];
        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          if (userData.email) {
            emails.push({
              email: userData.email,
              uid: userData.uid
            });
          }
        });
        setUserEmails(emails);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [members]);

  const handleRemoveMember = (memberToRemove) => {
    onRemoveMember(memberToRemove.uid);
  };

  const handleRoleChange = (member, newRole) => {
    onUpdateMember(member.uid, newRole);
  };

  return (
    <Stack direction="column" spacing={2} sx={{width: '100%', maxWidth: 400}}>
      {members.map((member, index) => (
        <Stack
          key={index}
          direction="row"
          gap={2}
          alignItems="center"
          sx={{width: '100%'}}
        >
          <Box sx={{flex: 2}}>
            <Typography
              variant="body2"
              sx={{
                color: 'text.primary',
                py: 1,
                px: 1.5
              }}
            >
              {userEmails.find(user => user.uid === member.uid)?.email || member.email}
            </Typography>
          </Box>
          <Box sx={{flex: 1}}>

            {member.role === enums.ADMIN ? (
              <TextField
                value="Admin"
                disabled
                size="small" fullWidth
              />
            ) : (
              <Select
                value={member.role || enums.VIEWER}
                size="small" fullWidth
                onChange={(e) => handleRoleChange(member, e.target.value)}
              >
                <MenuItem value={enums.VIEWER} sx={{py: 1, px: 2}}>Viewer</MenuItem>
                <MenuItem value={enums.EDITOR} sx={{py: 1, px: 2}}>Editor</MenuItem>
              </Select>
            )}
          </Box>
          <Box>
            <IconButton
              onClick={() => handleRemoveMember(member)}
              size="small"
              sx={{color: 'text.secondary'}}
              disabled={member.role === enums.ADMIN}
            >
              <DeleteIcon/>
            </IconButton>
          </Box>
        </Stack>
      ))}

      {members.length < enums.MAX_FREE_WORKSPACE_MEMBERS[workspace.plan] && (
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          sx={{width: '100%'}}
        >
          <Box sx={{flex: 2}}>
            <TextField
              value={newMemberEmail}
              onChange={(e) => setNewMemberEmail(e.target.value)}
              placeholder="Enter email address"
              size="small" fullWidth
              variant="standard"
              sx={{
                marginRight: 1,
              }}
            />
          </Box>
          <Box sx={{flex: 1}}>
            <Select
              value={selectedRole}
              size="small" fullWidth

              onChange={(e) => setSelectedRole(e.target.value)}
            >
              <MenuItem value={enums.VIEWER} sx={{py: 1, px: 2}}>Viewer</MenuItem>
              <MenuItem value={enums.EDITOR} sx={{py: 1, px: 2}}>Editor</MenuItem>
            </Select>
          </Box>
          <Box>
            <Tooltip title="Add member">
              <IconButton
                onClick={handleAddMember}
                size="small"
                disabled={members.length >= enums.MAX_FREE_WORKSPACE_MEMBERS[workspace.plan] || addingInProgress}
                sx={{color: 'primary.main'}}
              >
                <PersonAddIcon/>
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      )}
    </Stack>
  );
}; 