import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { Wordcloud } from '@visx/wordcloud';
import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
export const WordCloud = ({ words, width = '100%', height = '100%' }) => {
  const [formattedWords, setFormattedWords] = useState([]);

  useEffect(() => {
    if (words.length > 0) {
      const formattedWords = words.map(({ text, value }) => ({
        text,
        value: Math.min(value, 100) // Cap the value to prevent excessive scaling
      }));
      setFormattedWords(formattedWords);
    }
  }, [words]);

  const colors = ['#143059', '#2F6B9A', '#82a6c2'];
  
  if (formattedWords.length === 0) {
    return (<Typography>No data to display</Typography>)
  }
  
  return (
    <div style={{ width, height, maxHeight: '600px', position: 'relative' }}>
      <svg 
        width="100%" 
        height="100%" 
        style={{ maxHeight: '600px' }}
        viewBox="0 0 600 400"
        preserveAspectRatio="xMidYMid meet"
      >
        <Wordcloud
          words={formattedWords}
          width={600}  // Fixed width for layout calculation
          height={400} // Fixed height for layout calculation
          fontSize={(datum) => Math.min(Math.sqrt(datum.value) * 10, 60)} // Cap font size
          padding={2}
          rotate={0}
          font="Poppins"
        >
          {(cloudWords) => (
            <Group>
              {cloudWords.map((w, i) => (
                <Text
                  key={w.text}
                  fill={colors[i % colors.length]}
                  textAnchor="middle"
                  transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                  fontSize={w.size}
                  fontFamily={w.font}
                >
                  {w.text}
                </Text>
              ))}
            </Group>
          )}
        </Wordcloud>
      </svg>
    </div>
  );
};

export default WordCloud;
