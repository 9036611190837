import {Autocomplete, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";

export const AutocompleteWithLabel = ({ label, labelProps = {}, options, value = "", onChange, name, ...props }) => {
  return (
    <Box>
      {label && <Typography {...labelProps}>{label}</Typography>}
      <Autocomplete
        options={options}
        value={value} name={name || undefined}
        onChange={(_, newValue) => onChange(newValue)}
        renderInput={(params) => <TextField {...params} fullWidth {...props} />}

      />
    </Box>
  );
};