import React, {useState} from 'react';
import {Input, TextField, Typography} from '@mui/material';
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {Filter} from 'bad-words';

export const EditableTextField = ({initialText, onSave, type, fullResponse}) => {
    const [text, setText] = useState(initialText);
    const [isEditing, setIsEditing] = useState(false);
    // const hasLetters = /[a-zA-Z]/.test(text);
    const hasNonPunctuation = /[^.,;:!?…\s]/.test(text);
    const filter = new Filter();

    const handleSave = () => {
        if (text !== initialText) {
            onSave(text, type);
        }
        setIsEditing(false);
    };

    const manipulateText = (text) => {
      // First check if there's any meaningful content
      if (!hasNonPunctuation) {
        return "";
      }
      
      // If in edit mode, show the original text
      if (isEditing) {
        return text;
      }
      
      // Otherwise, filter bad words for display
      const cleanedText = filter.clean(text);
      
      // If we have entity recognition data and not in edit mode, display it inline
      if (fullResponse?.entities && !isEditing) {
        // Sort entities by start position to process them in order
        const sortedEntities = [...fullResponse.entities].sort((a, b) => a.start - b.start);
        
        // Create an array of text segments and entity labels
        let lastEnd = 0;
        const segments = [];
        
        sortedEntities.forEach(entity => {
          // Add text before this entity
          if (entity.start > lastEnd) {
            segments.push(cleanedText.substring(lastEnd, entity.start));
          }
          
          // Add the entity text with highlighting and its label as subscript
          segments.push(
            <React.Fragment key={entity.start}>
              <Box 
                component="span" 
                sx={{ 
                  backgroundColor: 'rgba(144, 202, 249, 0.2)',
                  padding: '0 2px',
                  borderRadius: '2px'
                }}
              >
                {entity.text}
              </Box>
              <Box 
                component="span" 
                sx={{ 
                  display: 'inline-block',
                  mx: 0.5,
                  px: 0.5,
                  py: 0.1,
                  borderRadius: 1,
                  backgroundColor: 'primary.light',
                  color: 'primary.contrastText',
                  fontSize: '0.7rem',
                  verticalAlign: 'sub',
                  lineHeight: 1
                }}
              >
                {entity.label}
              </Box>
            </React.Fragment>
          );
          
          lastEnd = entity.end;
        });
        
        // Add any remaining text after the last entity
        if (lastEnd < cleanedText.length) {
          segments.push(cleanedText.substring(lastEnd));
        }
        
        return <React.Fragment>{segments}</React.Fragment>;
      }
      
      return cleanedText;
    }

    return (
      <TextField
        value={isEditing ? text : undefined}
        fullWidth
        variant="standard"
        InputProps={{
          disableUnderline: !isEditing,
          readOnly: !isEditing,
          multiline: true,
          maxRows: 3,
          onDoubleClick: () => setIsEditing(prev => !prev),
          // When not editing, render the content with entities
          ...((!isEditing) && {
            inputComponent: () => (
              <Box sx={{ pt: 0.5, pb: 0.5 }}>
                {manipulateText(initialText)}
              </Box>
            )
          })
        }}
        onChange={(e) => setText(e.target.value)}
        onBlur={handleSave}
        helperText={isEditing ? "Click outside to save" : "Double click to edit"}
        placeholder={"Nothing was recorded..."}
        sx={{ 
          '& .MuiInput-root': {
            color: hasNonPunctuation ? 'inherit' : 'text.secondary'
          }
        }}
      />
    );
};


EditableTextField.propTypes = {
    initialText: PropTypes.string,
    onSave: PropTypes.func,
    type: PropTypes.string,
}
