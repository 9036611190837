import React from 'react';
import {Typography} from "@mui/material";

const QuestionTitle = ({title}) => {
  return (
    <Typography sx={{fontSize: {sm: "1.3rem", md: "1.4rem"} }} color={"secondary"} fontWeight={500}>{title}</Typography>
  );
};

export default QuestionTitle;

