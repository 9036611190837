import React, { useContext, useMemo } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { SurveyTitle } from "../../pages/projects-list-page/components/project-list-item/SurveyTitle";
import { AppContext } from "../../utils/AppContext";
import { useNavigate } from "react-router-dom";
import { enums } from "../../utils/enums";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useAuthContext } from "../../contexts/auth_context";
export const WorkspaceItem = ({ workspace }) => {
  const { state: { surveys }, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const { user } = useAuthContext();
  console.log("workspace");
  console.log(workspace);
  console.log(surveys);
  console.log(user);
  console.log(user?.role);

  return (
    workspace && (
      <Grid container className={"project-item-container"} sx={{ maxWidth: "calc(100% - 32px)" }}>
        <Grid item xs={12} md={6} lg={7} sx={{ p: 1 }}>
          <Stack
            direction={"column"}
            sx={{ width: "100%", minHeight: "140px" }}
            gap={3}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            <Box
              onClick={() => {
                dispatch({ workspaceId: workspace.uid });
                navigate(`/workspace/${workspace.uid}`);
              }}
              sx={{ cursor: "pointer" }}
            >
              <SurveyTitle
                title={workspace.name}
                dateCreated={workspace.createTime}
              />
              
            </Box>

            <Stack
              direction={"column"}
              gap={1}
              sx={{ width: "100%" }}
              className={"project-item"}
            >
              {workspace.plan !== enums.UNPAID && <div style={{ width: "90%", margin: "8px 0px 0px 0px" }}>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <Box className={"tooltip-box"}>
                    <Typography className={"tooltips"}>
                      {workspace.memberDetails.length} / {workspace.membersPaidFor}
                    </Typography>
                    <Typography className={"tooltips light"}>
                      Members
                    </Typography>
                  </Box>
                  <Box className={"tooltip-box"}>
                    <Typography className={"tooltips"}>
                      {surveys.filter(survey => survey.workspaceId === workspace.uid).length}
                    </Typography>
                    <Typography className={"tooltips light"}>
                      Surveys
                    </Typography>
                  </Box>
                </Stack>
              </div>}
              {workspace.plan === enums.UNPAID && (
                <Stack direction={"row"} gap={1}>
                  <Typography sx={{ color: "red" }}>Unpaid workspace - change plan to use this workspace</Typography>
                  <Button size="small" sx={{ width: "100px", margin: 0, padding: 0 }} variant="text" color="primary" onClick={() => navigate(`/workspace-plan/${workspace.uid}`)}>
                    Upgrade
                  </Button>
                </Stack>

              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={5} sx={{ p: 1 }}>
          <Stack
            direction={"column"}
            sx={{ width: "100%", height: "100%" }}
            gap={2}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            <Button
              size={"large"}
              variant={"contained"}
              fullWidth
              className={"gradient-btn"}
              component={Link}
              startIcon={<Edit />}
              sx={{ py: 1.5, mt: 1 }}
              disabled={workspace.memberDetails.find(member => member.uid === user.uid)?.role !== enums.ADMIN}
              to={`/workspace/${workspace.uid}`}
            >
              View Workspace
            </Button>
          </Stack>
        </Grid>
      </Grid>
    )
  );
};
