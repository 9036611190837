import React, { useEffect, useState } from "react";
import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { doc, writeBatch } from "firebase/firestore";
import { db } from "../../../utils/firebaseApp";
import { Response } from "./Response";
import { QuestionTypes } from "../../create-project-page-new/enums";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../utils/firebaseApp";
import { ClassifyByTopicsModal } from "./ClassifyByTopicsModal";

export const Responses = ({ responses, question }) => {
    const [sortBy, setSortBy] = useState("descending");
    const [sentiment, setSentiment] = useState([]);
    const [filteredResponses, setFilteredResponses] = useState([]);
    const [classifyByTopics, setClassifyByTopics] = useState(false);
    const recognizeObjects = httpsCallable(functions, "recognizeObjectsInImages");

    useEffect(() => {
        if (responses?.length) {
            setSentiment([]);
            filterData(responses);
        }
    }, [responses, sortBy]);

    const compareItems = (a, b) => {
        const dateA = a.timestamp;
        const dateB = b.timestamp;

        if (sortBy === "descending") {
            return dateA - dateB; // Ascending order
        }
        return dateB - dateA; // Descending order
    };

    useEffect(() => {
        if (responses?.length) {
            filterData(responses);
        } else {
            setFilteredResponses([]);
        }
    }, [responses]);

    const filterData = (items) => {
        let filteredItems = items;

        if (sentiment?.length > 0) {
            filteredItems = filteredItems.filter(item => sentiment.includes(item.sentiment));
        }
        const sortedItems = [...filteredItems].sort(compareItems);
        setFilteredResponses(sortedItems);
    };

    async function getTopicsAndClassifyAnswers() {
        toast.info('Classifying answers by topic using AI')
        let topics;

        const createTopicsUrl = process.env.REACT_APP_RENDER_URL + 'create_topics';
        const classifyResponsesUrl = process.env.REACT_APP_RENDER_URL + 'classify_responses';
        const bearerToken = process.env.REACT_APP_RENDER_TOKEN;

        try {
            const response = await axios.post(createTopicsUrl, {
                survey_responses: filteredResponses.map((response) => response?.translation || response.text),
                num_topics: 5
            }, {
                headers: {
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            topics = response.data;

            const classifyResponses = await axios.post(classifyResponsesUrl, {
                survey_responses: filteredResponses.map((response) => response?.translation || response.text),
                topics_list: topics
            }, {
                headers: {
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            const classifiedResponses = classifyResponses.data;
            filteredResponses.forEach((response, index) => {
                response.topics = classifiedResponses[index];
            });
            setFilteredResponses([...filteredResponses])

            const batch = writeBatch(db);
            filteredResponses.forEach((response) => {
                const responseRef = doc(db, "answers", response.uid);
                batch.update(responseRef, { topics: response.topics });
            })
            await batch.commit();
            toast.success("Classified successfully");
        } catch (error) {
            console.log(error);
            toast.error("Classification failed");
        }
    }

    async function identifyNamesPlacesCompanies() {
        // toast.info('Identifying names, places, companies using AI');
        const toastId = toast.loading("Identifying names, places, companies using AI");


        try {
            // Get all the text responses
            const textsToAnalyze = filteredResponses.map(response => 
                response?.translation || response.text
            ).filter(text => text); // Filter out any undefined/null/empty texts
            
            if (textsToAnalyze.length === 0) {
                toast.update(toastId, { render: "No text responses to analyze", type: "error", isLoading: false, autoClose: 3000 });
                return;
            }
            
            // Call the NER API
            const response = await axios.post(
                'https://ella-ner-functions-694820197024.us-central1.run.app/ner',
                {
                    api_key: process.env.REACT_APP_NER_API_KEY, // Consider moving this to environment variables
                    texts: textsToAnalyze
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            console.log("NER API response:", response.data);
            
            // Check if we have a valid response with results
            if (response.data && response.data.results && Array.isArray(response.data.results)) {
                // Create a copy of filteredResponses to update
                const updatedResponses = [...filteredResponses];
                
                // Map the valid text responses to their original indices in filteredResponses
                const validResponseIndices = filteredResponses
                    .map((response, index) => ({ 
                        hasText: Boolean(response?.translation || response.text),
                        originalIndex: index 
                    }))
                    .filter(item => item.hasText);
                
                // Update each response with its corresponding entities
                response.data.results.forEach((result, resultIndex) => {
                    const originalIndex = validResponseIndices[resultIndex].originalIndex;
                    updatedResponses[originalIndex].entities = result.entities;
                });
                
                // Update the state
                setFilteredResponses(updatedResponses);
                
                // Save to Firestore
                const batch = writeBatch(db);
                updatedResponses.forEach((response) => {
                    if (response.entities) {
                        const responseRef = doc(db, "answers", response.uid);
                        batch.update(responseRef, { entities: response.entities });
                    }
                });
                await batch.commit();
                toast.update(toastId, { render: "Identified entities successfully", type: "success", isLoading: false, autoClose: 3000 });
            } else {
                toast.update(toastId, { render: "Invalid response format from entity recognition service", type: "error", isLoading: false, autoClose: 3000 });
            }
        } catch (error) {
            console.error("Error identifying entities:", error);
            toast.update(toastId, { render: "Failed to identify entities: " + (error.message || "Unknown error"), type: "error", isLoading: false, autoClose: 3000 });
        }
    }

    function renderActionButtons() {
        if (question) {
            if (question?.type === QuestionTypes.TEXT || question?.type === QuestionTypes.VOICE) {
                return <Box sx={{ pb: 2 }}>
                    <Stack direction={"row"} gap={2}>
                    <Button variant={'contained'} disabled={responses?.length === 0} sx={{ width: '220px', mt: 2, borderRadius: 2 }}
                        onClick={() => setClassifyByTopics(true)}>
                        Classify by topic using AI
                    </Button>
                    <Button variant={'contained'} disabled={responses?.length === 0} sx={{ width: '300px', mt: 2, borderRadius: 2 }}
                        onClick={() => identifyNamesPlacesCompanies()}>
                            Identify names, places, companies
                        </Button>
                    </Stack>
                </Box>
            }
            if (question?.type === QuestionTypes.IMAGE) {
                return <Button size={"small"} sx={{ height: "30px" }} variant={"contained"} color={"primary"} onClick={() => recognizeObjectsInImages()}>Recognize objects</Button>
            }
        }
    }

    async function recognizeObjectsInImages() {
        toast.info("Recognizing objects in images");
        console.log(question.surveyUid)
        console.log(question.uid)
        try {
            const result = await recognizeObjects({ surveyUid: question?.surveyUid, questionUid: question?.uid });
            console.log(result);
            toast.success("Recognition successful");
        } catch (error) {
            console.log(error);
            toast.error("Recognition failed");
        }
    }

    return (
        question
        && question?.type
        && [QuestionTypes.TEXT, QuestionTypes.IMAGE, QuestionTypes.VOICE, QuestionTypes.SLIDER, QuestionTypes.DROPDOWN, QuestionTypes.CHECKBOXES, QuestionTypes.MULTICHOICE, QuestionTypes.IMAGE_GALLERY, QuestionTypes.LOCATE_USER].includes(question.type)
        && (
            <Box sx={{ paddingBottom: "150px" }}>
                <ClassifyByTopicsModal open={classifyByTopics} handleClose={() => setClassifyByTopics(false)} question={question} responses={responses} />
                <Stack direction={"row"} gap={2} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant={'h4'}>Analyze answers</Typography>

                    <Stack direction={"row"} gap={2}>
                        <FormControl sx={{ width: "250px" }} size={"small"}>
                            <InputLabel id="demo-multiple-sort-label">Sort by</InputLabel>
                            <Select
                                labelId="demo-multiple-sort-label"
                                id="demo-multiple-sort"
                                // multiple
                                value={sortBy}
                                onChange={e => setSortBy(e?.target.value)}
                                sx={{ height: "100%" }}
                            >
                                <MenuItem value={"ascending"}>Newest</MenuItem>
                                <MenuItem value={"descending"}>Oldest</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>
                {renderActionButtons()}

                <Divider sx={{ mt: 2, mb: 2 }} />
                {filteredResponses.length > 0
                    ? <Stack direction={"column"} gap={2}>
                        {filteredResponses?.map((filteredResponse) => (
                            <Response key={filteredResponse?.uid} question={question} response={filteredResponse} />
                        ))}
                    </Stack>
                    : <Typography variant={'h6'}>No responses yet...</Typography>
                }
            </Box>
        )
    );
};
