import {useAtom} from "jotai";
import {AnswerAtom} from "../atoms";
import QuestionTitle from "./QuestionTitle";
import {FormControlLabel, RadioGroup, Typography, Stack, TextField} from "@mui/material";
import React from "react";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";

export const CheckBoxQuestion = () => {
  const [answer, setAnswer] = useAtom(AnswerAtom);

  const onTextChange = (index, text) => {
    setAnswer(prev => {
      const options = [...(prev?.options || [])];
      if (options?.length >= index) {
        options[index].option = text;
        options[index].checked = !!text;
      }
      return {
        ...prev,
        options: [...options]
      }
    });
  };


  const onSelect = (index, checked) => {
    setAnswer(prev => {
      const options = prev?.options?.map((i, ind) => ({
        ...i,
        checked: ind === index ? checked : i?.checked,
        option: ind === index && !checked && i?.other ? "" : i?.option,
      }))
      console.log(options);
      return {
        ...prev,
        text: options?.filter(i => i?.checked)?.map(i => i?.option)?.join(", "),
        options: options
      }
    });
  };


  return (
    <>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
      >
        {answer?.options?.map((item, index) => {
          if (item?.other) {
            return (
              <FormControlLabel
                key="other"
                value="other"
                control={
                  <Stack direction="row" spacing={1} alignItems="flex-start">
                    <Checkbox
                      checked={!!item?.checked}
                      // disabled={!item?.option}
                      onChange={(e, checked) => {
                        onSelect(index, checked);
                        if (checked) {
                          const textfield = document.getElementById(`${index}_other`);
                          if (textfield) {
                            textfield?.focus();
                          }
                        }
                      }}
                    />
                    <TextField
                      size="small" id={`${index}_other`}
                      value={item?.option}
                      placeholder="Other"
                      onChange={(e) => onTextChange(index, e?.target?.value)}
                      onBlur={e => {
                        setAnswer(prev => {
                          let options = [...(prev?.options || [])];
                          if (options?.length >= index) {
                            options[index].checked = !!options[index].option;
                          }
                          return {
                            ...prev,
                            text: options[index].option,
                            options: [...options]
                          }
                        });
                      }}
                      // disabled={!item?.checked}
                    />
                  </Stack>
                }
                label=""
              />
            );
          }
          return (
            <FormControlLabel
              value={item?.option}
              control={<Checkbox checked={item?.checked} />}
              checked={item?.checked}
              label={item?.option}
              key={item.option}
              sx={{ pb: 0.7 }}
              onChange={(e, checked) => onSelect(index, checked)}
            />
          );
        })}
      </RadioGroup>
    </>
  )
}