import {useSaveSurvey} from "../hooks/useSaveSurvey";
import {Box, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {IconCheckupList, IconFlare, IconTex} from "@tabler/icons-react";
import React, {useState} from "react";
import {ContentCopy, DeleteOutline, Edit, RestartAlt} from "@mui/icons-material";
import {CreateThankYouQuestionState, SelectedLanguageState} from "../atoms";
import {useAtomValue, useSetAtom} from "jotai";
import {EnglishNativeLanguage} from "../../../utils/english-native-language";

export const ThankYouQuestionItem = () => {
  const {survey, saveSurvey} = useSaveSurvey();
  const selectedLanguage = useAtomValue(SelectedLanguageState);
  const setCreateThankYouPage = useSetAtom(CreateThankYouQuestionState);
  const [loading, setLoading] = useState(false);

  const onTranslateAgain = async () => {

  }

  const deleteQuestion = async () => {
    await saveSurvey({...survey, thankYouPage: null});
  }

  const text = survey?.thankYouPage?.question[selectedLanguage?.BCP47]

  return (
    <Stack direction={"row"} gap={2} alignItems={"center"}>
      <Box>
        <IconFlare/>
      </Box>
      <Box
        sx={{flex: 1, px: {xs: 2, md: 3, lg: 4}, py: {xs: 2, md: 3}, borderRadius: 5, bgcolor: "#fff"}}
        className={`question border-shadow`}
      >
        <Stack direction={"row"} gap={2} justifyContent={"space-between"} useFlexGap flexWrap={"wrap"}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <IconCheckupList fontSize={"small"}/>
            <Typography fontWeight={400} fontSize={"small"}
                        textTransform={"capitalize"}>Thank You Page - {selectedLanguage?.Native}</Typography>

          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Tooltip title={"Translate again"}>
              <IconButton
                size={"small"}
                disabled={loading || selectedLanguage?.BCP47 === EnglishNativeLanguage?.BCP47}
                onClick={onTranslateAgain}
              >
                <RestartAlt/>
              </IconButton>
            </Tooltip>
            <Tooltip title={"Edit Question"}>
              <IconButton
                size={"small"}
                disabled={loading}
                onClick={() => {
                  setCreateThankYouPage(true);
                }}
              >
                <Edit fontSize={"small"}/>
              </IconButton>
            </Tooltip>
            <Tooltip title={"This action is disabled for thank you page"}>
              <Box sx={{p: "5px", borderRadius: "50%", width: "30px", height: "30px", color: "divider"}}>
                <ContentCopy fontSize={"small"} style={{marginTop: "auto"}}/>
              </Box>
            </Tooltip>
            <Tooltip title={"Delete Question"}>
              <IconButton
                size={"small"}
                disabled={loading}
                onClick={deleteQuestion}
              >
                <DeleteOutline fontSize={"medium"}/>
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack direction={"row"} gap={2} sx={{py: 1}}>
          <Typography variant={"h6"} fontWeight={400} noWrap>{text || "N/A"}</Typography>
        </Stack>
      </Box>
    </Stack>
  )
}