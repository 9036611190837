import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Button, IconButton, Stack, TextField, Typography} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { toast } from "react-toastify";
import ellaLogo from '../../assets/logo/ella-short.png'
import personIcon1 from '../../assets/logo/person-icon.png'
import loadingGif from '../../assets/login/loading.webp'
import { useDidUpdate } from "../../utils/useDidUpdate";
import useImagePreloader from "../../utils/usePreloadImage";
import { AppContext } from "../../utils/AppContext";
import Box from "@mui/material/Box";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../utils/firebaseApp";
import {grey} from "@mui/material/colors";
import {useAtom, useAtomValue} from "jotai";
import {AiDrawerState, CreateSurveyQuestionsState} from "../create-project-page-new/atoms";
import {IconCopy, IconX} from "@tabler/icons-react";
import {useSaveSurvey} from "../create-project-page-new/hooks/useSaveSurvey";
import { jsonrepair } from 'jsonrepair'


const ChatWindow = () => {
    const [open, setOpen] = useAtom(AiDrawerState);

    // const [messages, setMessages] = useState();
    const [isAnswering, setIsAnswering] = useState(false);
    const [improvedClicked, setImprovedClicked] = useState(false);
    const [userQuery, setUserQuery] = useState('');
    const [chatGptUserQuery, setChatGptUserQuery] = useState(null);
    const { survey } = useSaveSurvey();
    const questions = useAtomValue(CreateSurveyQuestionsState)

    const bottomRef = useRef(null);
    const { state: { messages }, dispatch } = useContext(AppContext)
    useImagePreloader([ellaLogo, personIcon1, loadingGif])
    const getChatCompletion = httpsCallable(functions, "getChatCompletion");

    useDidUpdate(() => {
        if (bottomRef.current && messages.length > 0) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleClose = () => setOpen(false);

    useEffect(() => {
        async function getMessages(messages) {
            try {
                setIsAnswering(true);

                const response = await getChatCompletion({ messages });

                if (response.data) {
                    const message = response.data.message;
                    dispatch({ messages: [...messages, message] });
                    setChatGptUserQuery(null);
                } else {
                    toast.error('Something went wrong, please try again!');
                }

            } catch (error) {
                console.error("Error fetching chat completion:", error);
                toast.error('An error occurred, please try again!');
            } finally {
                setIsAnswering(false);
            }
        }

        if (!chatGptUserQuery) return;

        const newMessages = [...messages, chatGptUserQuery]; // Add new user message
        // Ensure the system messages are always present (first 3 messages)
        const systemMessages = messages.slice(0, 3);

        // Extract last 5 messages excluding system messages
        const conversationMessages = messages.slice(3);
        const lastFiveMessages = conversationMessages.length > 5
          ? conversationMessages.slice(-4).concat(chatGptUserQuery) // Keep last 4 + new query
          : conversationMessages.concat(chatGptUserQuery); // Keep all if less than 5 exist

        const messagesToSend = [...systemMessages, ...lastFiveMessages];

        dispatch({ messages: newMessages });
        setUserQuery('');
        setChatGptUserQuery(null);

        getMessages(messagesToSend); // Send only the selected messages
    }, [chatGptUserQuery]);

    function renderBoldText(text) {
        // Regular expression to match words wrapped in ** (e.g., **word**)
        const regex = /\*\*(.*?)\*\*/g;

        // Replace matched patterns with <strong> tags
        return text.replace(regex, '<strong>$1</strong>');
    }

    const improveSurvey = () => {
        setImprovedClicked(true);
        const surveyObj = {...(survey || {}), questions: questions || []}
        setChatGptUserQuery({
            role: "user",
            content: `
            Improve my survey:
            ${JSON.stringify(surveyObj)}
            `
        })
    }

    const copyText = async (text) => {
        if (text) {
            try {
                await navigator.clipboard.writeText(text);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const messagesMapped = useMemo(() => {
        return messages.map((message, index) => {
            let content = message?.content;

            if (content?.includes("Improve my survey:")) {
                content = "Improve my survey: <br/><em>...your survey and questions</em>";
            }
            if (content?.includes("User message: ")) {
                content = content.replaceAll("User message: ", "");
            }
            if (content?.includes("Current version of user’s survey:")) {
                content = content?.split("Current version of user’s survey:")[0]?.trim() || "";
            }

            switch (message.role) {
                case 'user':
                    return (
                      <Box key={`${index}-${message?.content}`} sx={{ maxWidth: "90%", ml: "auto", bgcolor: grey[100], py: 1, px: 2, borderRadius: 3, borderBottomRightRadius: 5 }}>
                          <Typography
                            variant={'body1'}
                            sx={{
                                whiteSpace: 'pre-line', color: '#2F2F2F', fontSize: "0.9rem", fontWeight: 300
                            }}
                            dangerouslySetInnerHTML={{ __html: content }} // Ensures HTML rendering
                          />
                      </Box>
                    );
                case 'assistant':
                    content = JSON.parse(jsonrepair(message?.content)) || "";
                    const improved_questions = content?.improved_questions || [];
                    return (
                      <Stack key={`${index}-${JSON.stringify(message?.content)}`} direction={"column"} gap={1}>
                          <Typography
                            variant={'body1'}
                            sx={{
                                whiteSpace: 'pre-line', color: '#2F2F2F', fontSize: "0.9rem", fontWeight: 300
                            }}
                            dangerouslySetInnerHTML={{ __html: renderBoldText(content?.your_response || "") }} // Ensures formatted output
                          />
                          {improved_questions?.map((question, ind) => (
                            <Stack direction={"row"} gap={0.5} alignItems={"center"} sx={{"&:hover > .copy": {opacity: 1}}}>
                                <Typography
                                  variant={'body1'}
                                  sx={{
                                      whiteSpace: 'pre-line', color: '#2F2F2F', fontSize: "0.9rem", fontWeight: 300
                                  }}
                                  dangerouslySetInnerHTML={{ __html: renderBoldText(question ? `** ${ind + 1}.** ${question}` : "") }} // Ensures formatted output
                                />
                                <Box className={"copy"} sx={{opacity: 0}}>
                                    <IconButton size={"small"} sx={{p: 0}} onClick={() => copyText(question)}>
                                        <IconCopy size={15} />
                                    </IconButton>
                                </Box>
                            </Stack>
                          ))}
                      </Stack>
                    );
                default:
                    return null;
            }
        })
    }, [messages]);

    const startNewConversation = () => {
        dispatch({messages: messages.slice(0, 3)});
        setUserQuery("");
        setImprovedClicked(false);
    }


    function sendChatGptPrompt() {
        const surveyObj = {...(survey || {}), questions: questions || []}

        setChatGptUserQuery(
            {
                "role": "user",
                "content": `User message: ${userQuery}
Current version of user’s survey: ${JSON.stringify(surveyObj)}
`
            }
        )
    }

    return (
        <Stack direction={"column"} maxWidth={"lg"} gap={3} justifyContent={"space-between"} sx={{
            mx: { xs: 2, md: "auto" }, width: "95%",
            maxHeight: { xs: "calc(100dvh - 68px - 32px)", md: "calc(100dvh - 68px)" },
            height: "100%", pt: 3, px: 0
        }}>
            <Stack direction={"row"} gap={1}>
                <Box sx={{width: "100%"}}>
                    <Typography variant={'h4'} fontWeight={'bold'} sx={{ mb: 1 }}>Ella Survey Designer</Typography>
                    <Typography sx={{color: '#A0A0A0', fontSize: "0.9rem", fontWeight: 400}}>Strengthen your survey questions 💪</Typography>
                </Box>
                {open && <Box>
                    <IconButton size={"small"} onClick={handleClose}><IconX /></IconButton>
                </Box>}
            </Stack>
            <Stack direction={'column'} gap={1.5} sx={{ flex: 1, overflowY: 'auto', px: 0.5, py: 4 }} className={"overflow-hidden"}>
                <Typography sx={{ color: '#2F2F2F', fontSize: "0.9rem", fontWeight: 300 }}>{"Hi how can I help you"}</Typography>
                <Typography sx={{ color: '#2F2F2F', fontSize: "0.9rem", fontWeight: 300 }}>{"You can type a specific request in the chat box or click ‘Assess My Survey’ to get feedback."}</Typography>
                {messagesMapped}
                <Box ref={bottomRef} />
            </Stack>
            <Stack direction={"column"} gap={1}>
                <Stack direction={improvedClicked ? "row-reverse" : "row"} gap={1} maxWidth={"sm"}>
                    <Box sx={{flex: 1}}>
                        {open && survey && questions && !improvedClicked &&
                            <Button onClick={improveSurvey} variant={"contained"} className={"gradient-btn"}>Improve My Survey</Button>
                        }
                    </Box>
                    <Box sx={{flex: 1}}>
                        {messages?.length > 3 && <Button fullWidth onClick={startNewConversation} variant={"outlined"} className={"gradient-outlined-btn"} sx={{textWrap: "nowrap"}}>Start new Conversation</Button>}
                    </Box>
                </Stack>

                <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'center'}>
                    <TextField
                      placeholder="What would be the best questions and question types..."
                      multiline
                      rows={1.5}
                      sx={{ flex: 1, fontSize: 15 }}
                      value={userQuery}
                      onChange={e => setUserQuery(e.target.value)}
                      onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                              e.preventDefault(); // Prevents new line
                              sendChatGptPrompt(); // Calls the function
                          }
                      }}
                    />
                    <Box sx={{ textAlign: "right" }}>
                        {
                            isAnswering ?
                              <img style={{ width: '50px' }} alt={"Loading"} src={loadingGif} /> :
                              <IconButton color="primary" variant={"outlined"} sx={{ width: "50px", height: "50px" }}
                                          aria-label="add" onClick={sendChatGptPrompt}>
                                  <SendIcon />
                              </IconButton>
                        }
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    );
}
export default ChatWindow;


