import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, FormControl, MenuItem, Modal, Paper, Select, Stack, Typography } from '@mui/material';
import { doc, writeBatch } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../../../utils/firebaseApp';

const Rule = ({ rule, question, questions, onDelete, onEdit }) => {

  const [selectedAnswerOption, setSelectedAnswerOption] = useState(rule.answerOption);
  const [selectedNextQuestion, setSelectedNextQuestion] = useState(rule.nextQuestion);

  // Load initial values when rule changes
  useEffect(() => {
    setSelectedAnswerOption(rule.answerOption);
    setSelectedNextQuestion(rule.nextQuestion);
  }, [rule.uid]); // Only run when rule.uid changes, meaning it's a different rule

  // Handle changes
  useEffect(() => {
    if (selectedAnswerOption &&
      selectedNextQuestion &&
      (selectedAnswerOption !== rule.answerOption ||
        selectedNextQuestion !== rule.nextQuestion)) {
      onEdit(rule.uid, selectedAnswerOption, selectedNextQuestion);
    }
  }, [selectedAnswerOption, selectedNextQuestion]);

  console.log('question in modal');
  console.log(question);

  return (
    <Paper sx={{ p: 2, mb: 2, borderRadius: 2 }} elevation={3}>
      <Stack direction="column">
        <Typography variant="subtitle1" >If answer is</Typography>
        <FormControl sx={{ width: '100%' }}>
          <Select 
            value={question.options.find(opt => opt.uid === selectedAnswerOption)?.uid} 
            displayEmpty 
            onChange={(e) => setSelectedAnswerOption(e.target.value)}
          >
            {question.options.map((option) => (
              <MenuItem 
                key={option.uid}
                value={option.uid}
                disabled={question.rules?.some(
                  existingRule =>
                    existingRule.answerOption === option.uid &&
                    existingRule.uid !== rule.uid
                )}
              >
                {option.option || "Other"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <Stack direction="column">
        <Typography variant="subtitle1">Then go to</Typography>
        <Stack direction="row" gap={2}>
          <FormControl fullWidth>
            <Select value={selectedNextQuestion} displayEmpty onChange={(e) => setSelectedNextQuestion(e.target.value)}>
              {questions
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .filter((otherQuestion) => otherQuestion.orderNumber !== question.orderNumber)
                .map((otherQuestion) => (
                  <MenuItem
                    key={otherQuestion.uid}
                    value={otherQuestion.orderNumber}
                  >
                    {otherQuestion.orderNumber + 1}. {otherQuestion.question}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <Button color="error" startIcon={<DeleteIcon />} onClick={() => onDelete(rule.uid)}>
        Delete rule
      </Button>
    </Paper>
  );
}

export const LogicModal = ({ open, onClose, question, questions, forceUpdate }) => {
  const [allOtherCasesQuestion, setAllOtherCasesQuestion] = useState(() => {
    return questions.find(q => q.orderNumber === question?.allOtherCasesQuestion)?.uid || null
  });

  const [questionToEdit, setQuestionToEdit] = useState(question);

  useEffect(() => {
    const questionToEdit = questions.find(q => q.uid === question.uid);
    setQuestionToEdit(questionToEdit);
  }, [questions]);

  const handleAddRule = async () => {
    const updatedQuestionWithRules = {
      ...questionToEdit,
      rules: [...(questionToEdit.rules || []), {
        uid: crypto.randomUUID(), // Add a unique ID for the rule
        answerOption: null,
        nextQuestion: null
      }]
    };
    //update question with new rule in db and state
    const batch = writeBatch(db);
    batch.set(doc(db, "questions", questionToEdit.uid), updatedQuestionWithRules);

    // Find and update all translations of this question
    const translatedQuestions = questions.filter(q =>
      q.originalReferenceUid === questionToEdit.uid ||
      (questionToEdit.originalReferenceUid &&
        (q.uid === questionToEdit.originalReferenceUid ||
          q.originalReferenceUid === questionToEdit.originalReferenceUid))
    );

    if (translatedQuestions.length > 1) {
      console.warn('Multiple translations found for question:', questionToEdit.uid, translatedQuestions);
    }

    // Update the translation if it exists
    if (translatedQuestions.length > 0) {
      const translatedQuestion = translatedQuestions[0];  // Take only the first one
      const updatedTranslation = {
        ...translatedQuestion,
        rules: updatedQuestionWithRules.rules
      };
      batch.set(doc(db, "questions", translatedQuestion.uid), updatedTranslation);
    }
    try { 
      await batch.commit();
      forceUpdate()
    } catch (error) {
      console.error('Error committing batch:', error);
    }
  };

  const updateQuestionAndTranslation = async (updatedQuestionWithRules) => {
    const batch = writeBatch(db);
    console.log('updatedQuestionWithRules');
    console.log(updatedQuestionWithRules);
    batch.set(doc(db, "questions", questionToEdit.uid), updatedQuestionWithRules);

    const translatedQuestions = questions.filter(q =>
      q.originalReferenceUid === questionToEdit.uid ||
      (questionToEdit.originalReferenceUid &&
        (q.uid === questionToEdit.originalReferenceUid ||
          q.originalReferenceUid === questionToEdit.originalReferenceUid))
    );

    if (translatedQuestions.length > 1) {
      console.warn('Multiple translations found for question:', questionToEdit.uid, translatedQuestions);
    }

    if (translatedQuestions.length > 0) {
      const translatedQuestion = translatedQuestions[0];
      const updatedTranslation = {
        ...translatedQuestion,
        rules: updatedQuestionWithRules.rules
      };
      batch.set(doc(db, "questions", translatedQuestion.uid), updatedTranslation);
    }
    try {
      await batch.commit();
      forceUpdate()
    } catch (error) {
      console.error('Error committing batch:', error);
    }
  };

  const handleDeleteAllRules = () => updateQuestionAndTranslation({ ...questionToEdit, rules: [] });

  const handleDeleteRule = (uid) => {
    console.log('handleDeleteRule');
    console.log(uid);
    updateQuestionAndTranslation({
      ...questionToEdit,
      rules: questionToEdit.rules.filter((rule) => rule.uid !== uid)
    });
  };

  const handleEditRule = (uid, answerOption, nextQuestion) => updateQuestionAndTranslation({
    ...questionToEdit,
    rules: questionToEdit.rules.map((rule) =>
      rule.uid === uid ? { ...rule, answerOption, nextQuestion } : rule
    )
  });

  // Add handler for updating allOtherCasesQuestion
  const handleAllOtherCasesChange = async (newValue) => {
    // setAllOtherCasesQuestion(newValue);
    setAllOtherCasesQuestion(newValue);
    const batch = writeBatch(db);
    const updatedQuestion = {
      ...questionToEdit,
      allOtherCasesQuestion: questions.find(q => q.uid === newValue)?.orderNumber || null
    };

    batch.set(doc(db, "questions", questionToEdit.uid), updatedQuestion);

    // Update translations if they exist
    const translatedQuestions = questions.filter(q =>
      q.originalReferenceUid === questionToEdit.uid ||
      (questionToEdit.originalReferenceUid &&
        (q.uid === questionToEdit.originalReferenceUid ||
          q.originalReferenceUid === questionToEdit.originalReferenceUid))
    );

    if (translatedQuestions.length > 0) {
      translatedQuestions.forEach(translatedQuestion => {
        const updatedTranslation = {
          ...translatedQuestion,
          allOtherCasesQuestion: newValue
        };
        batch.set(doc(db, "questions", translatedQuestion.uid), updatedTranslation);
      });
    }

    try {
      await batch.commit();
      forceUpdate()
    } catch (error) {
      console.error('Error committing batch:', error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="logic-modal-title"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
        maxHeight: '90vh',
      }}>
        <Typography id="logic-modal-title" variant="h6" component="h2" mb={3} sx={{ fontWeight: 600 }}>
          {questionToEdit?.orderNumber + 1}. {questionToEdit?.question}
        </Typography>

        {questionToEdit?.rules && questionToEdit?.rules.map((rule) => (
          <Rule key={rule.uid} rule={rule} question={questionToEdit} questions={questions}
            onDelete={handleDeleteRule} onEdit={handleEditRule} />
        ))}


        <Stack direction="column">
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddRule}
          >
            Add rule
          </Button>
          <Typography variant="subtitle1" >All other cases go to</Typography>
          <FormControl fullWidth>
            <Select
              value={allOtherCasesQuestion}
              displayEmpty
              onChange={(e) => handleAllOtherCasesChange(e.target.value)}
            >
              {questions.sort((a, b) => a.orderNumber - b.orderNumber)
              .map((q) => (
                <MenuItem disabled={q.uid === question.uid} value={q.uid}>{q.orderNumber + 1}. {q.question}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" justifyContent="space-between" mt={4}>

          <Button
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteAllRules}
          >
            Delete all rules
          </Button>
          <Box>
            <Button onClick={onClose} sx={{ mr: 2 }}>Cancel</Button>
            <Button variant="contained" onClick={onClose}>Save</Button>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};
