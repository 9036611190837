import {useAtom, useAtomValue} from "jotai";
import {AiDrawerState, CreateSurveyQuestionsState, CreateSurveySettingsState} from "../atoms";
import {
  Stack,
  Typography,
  Box,
  Modal,
  TextField,
  Autocomplete,
  Chip,
  FormControl,
  FormLabel,
  FormGroup, FormControlLabel, Switch, Button
} from "@mui/material";
import {useSaveSurvey} from "../hooks/useSaveSurvey";
import {countryCodesWithNativeLanguages} from "../../../utils/country-codes-google";
import React, {useEffect, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {createTranslationQuestion} from "../../../utils/functions";
import {doc, updateDoc, writeBatch} from "firebase/firestore";
import {db, functions} from "../../../utils/firebaseApp";
import {confirm} from "react-confirm-box";
import {confirmDialogOptions} from "../../../components/ConfirmDialog";
import {QuestionStatusType} from "../enums";
import {httpsCallable} from "firebase/functions";
import moment from "moment-timezone";
import {DateTimePicker} from "@mui/x-date-pickers";
import {EnglishNativeLanguage} from "../../../utils/english-native-language";
import {aiDrawerWidth} from "./AiDrawer";
import {AutocompleteWithLabel} from "../../../components/inputs/autocomplete-with-label";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-60%, -50%)",
  maxWidth: "sm",
  maxHeight: "70dvh", overflowY: "auto",
  width: "95%",
  borderRadius: "16px",
  bgcolor: "background.paper",
  p: 4, zIndex: 3
};

export const SurveySettingsModal = ({container = undefined}) => {
  const aiDrawerOpen = useAtomValue(AiDrawerState);
  const [open, setOpen] = useAtom(CreateSurveySettingsState);
  const {survey, saveSurvey} = useSaveSurvey();
  const questions = useAtomValue(CreateSurveyQuestionsState);
  const [newSurvey, setNewSurvey] = useState();
  const [loading, setLoading] = useState(false);
  const startTranslation = httpsCallable(functions, "translateFromSourceToTarget");
  const translateText = httpsCallable(functions, "translateText");

  useEffect(() => {
    if (survey) {
      setNewSurvey({...survey})
    }
  }, [survey])

  const addLanguage = (newLanguage) => {
    const isExisting = survey?.languages?.find(i => i?.BCP47 === newLanguage?.BCP47);
    if (isExisting) {
      toast.success("Cannot add same language twice");
      return;
    }
    const newSurveyObj = {...newSurvey};
    newSurveyObj.languages = [...(newSurveyObj?.languages || []), newLanguage];
    setNewSurvey({...newSurveyObj});
  }

  const deleteLanguage = (BCP47) => {
    if (newSurvey?.languages?.length === 1) {
      toast.error("You can't delete all languages");
      return;
    }
    const newSurveyObj = {...newSurvey};
    newSurveyObj.languages = newSurveyObj?.languages?.filter(item => item?.BCP47 !== BCP47);
    setNewSurvey({...newSurveyObj});
  }

  const handleSave = async () => {
    if (loading) return;
    try {
      setLoading(true);
      await updateThankYouPage();

      await updateNewLanguages();
      await updateRemovedLanguages();
    } catch (error) {
      console.log(error);
      toast.error("Failed to update survey...");
    } finally {
      setLoading(false);
      handleClose();
    }
  }

  const onTranslate = async (text, from, to) => {
    if (!from || !text || !to) {
      return null;
    }
    try {
      const response = translateText({text: text, from: from, to: to});
      return response?.data?.translation || null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  const updateThankYouPage = async () => {
    const englishBCP47 = EnglishNativeLanguage.BCP47;
    const toastId = toast.loading("Updating thank you page...");
    const updateSurvey = {...newSurvey}
    if (survey?.thankYouPage && survey?.thankYouPage?.question[englishBCP47]){
      const thankYouState = {...(survey?.thankYouPage || {})};
      const oldLanguages = survey?.languages?.map(i => i?.BCP47);
      const distinctLanguages = newSurvey?.languages?.filter(i => !oldLanguages.includes(i?.BCP47));
      for (const lang of distinctLanguages) {
        toast.update(toastId, {
          render: `The content is being translated into ${lang?.Native}.`,
          isLoading: true
        });
        thankYouState.question = {
          ...(survey?.thankYouPage?.question || {}),
          [lang?.BCP47]: await onTranslate(thankYouState?.question[englishBCP47], englishBCP47, lang?.BCP47) || thankYouState?.question[englishBCP47] || ""
        }
        thankYouState.description = {
          ...(survey?.thankYouPage?.description || {}),
          [lang?.BCP47]: await onTranslate(thankYouState?.description[englishBCP47], englishBCP47, lang?.BCP47) || thankYouState?.description[englishBCP47] || ""
        }
        thankYouState.subTitle = {
          ...(survey?.thankYouPage?.subTitle || {}),
          [lang?.BCP47]: await onTranslate(thankYouState?.subTitle[englishBCP47], englishBCP47, lang?.BCP47) || thankYouState?.subTitle[englishBCP47] || ""
        }
        thankYouState.bottomExplainer = {
          ...(survey?.thankYouPage?.bottomExplainer || {}),
          [lang?.BCP47]: await onTranslate(thankYouState?.bottomExplainer[englishBCP47], englishBCP47, lang?.BCP47) || thankYouState?.bottomExplainer[englishBCP47] || ""
        }
      }
      updateSurvey.thankYouPage = thankYouState;
    }

    console.log(new Date(updateSurvey.start), new Date(updateSurvey?.end))
    await saveSurvey({...updateSurvey});
    toast.update(toastId, {
      render: `Thank you page updated successfully`,
      type: "success", autoClose: 3000,
      isLoading: false
    });
  }

  const updateRemovedLanguages = async () => {
    const newLanguages = newSurvey?.languages?.map(i => i?.BCP47);
    const distinctLanguages = survey?.languages?.filter(i => !newLanguages.includes(i?.BCP47));
    const removedLanguages = distinctLanguages?.map(i => i?.BCP47);
    const removeQuestions = questions?.filter(item => removedLanguages.includes(item?.languageObject?.BCP47));
    const batch = writeBatch(db);
    for (const item of removeQuestions || []) {
      batch.delete(doc(db, "questions", item.uid));
    }
    try {
      setLoading(true);
      await batch.commit();
    } catch (error) {
      console.log(error);
      toast.error("Failed to delete question")
    }
  }

  const updateNewLanguages = async () => {
    const oldLanguages = survey?.languages?.map(i => i?.BCP47);
    const distinctLanguages = newSurvey?.languages?.filter(i => !oldLanguages.includes(i?.BCP47));
    const originalQuestions = questions?.filter(item => !item?.originalReferenceUid);

    if (originalQuestions?.length !== 0 && distinctLanguages?.length !== 0) {
      const batch = writeBatch(db);

      const translateQuestions = [];
      const toastId = toast.loading("Creating your questions...");
      for (const question of originalQuestions || []) {
        for (const language of distinctLanguages || []) {
          if (language && language?.BCP47 !== question?.languageObject?.BCP47) {
            const translateQuestion = createTranslationQuestion({question: question, language});

            batch.set(doc(db, "questions", translateQuestion.uid), translateQuestion);
            translateQuestions.push(translateQuestion);
          }
        }
      }
      await batch.commit()
      if (translateQuestions?.length > 0) {
        // This will ask user if we need to the translation, because translation maybe is wrong so user can't edit it will go in loop of generating translations
        const response = await confirm(
          {
            title: `Do you want to translate all questions into your newly added language?`,
            confirmText: "Yes",
            cancelText: "No",
            // subtitle: `This action will delete all questions in other languages that were created as a translation of this one.`,
          },
          confirmDialogOptions,
        );
        if (response) {
          for (const questionItem of translateQuestions || []) {
            if (!questionItem?.uid || !questionItem?.languageObject?.Name) {
              continue;
            }
            try {
              const response = await startTranslation({
                sourceUid: questionItem?.originalReferenceUid,
                targetUid: questionItem?.uid
              })
              toast.update(toastId, {
                render: `Translation started: ${questionItem?.languageObject?.Name} `,
                type: "info",
                isLoading: true
              });
            } catch (error) {
              toast.update(toastId, {
                render: `Failed to translate: ${questionItem?.languageObject?.Name} `,
                type: "error",
                isLoading: true
              });
              console.log(error);
              console.log("START TRANSLATION FAILED", questionItem);
              await updateDoc(doc(db, "questions", questionItem.uid), {status: QuestionStatusType.FAILED});
            }
          }
        }
        toast.update(toastId, {
          render: `Survey has completed updating`,
          autoClose: 3000,
          type: "success",
          isLoading: false
        });
      }
    }
  }

  const handleClose = () => setOpen(false);

  const filteredCountryLanguages = useMemo(() => {
    const surveyLanguages = newSurvey?.languages?.map(item => item.BCP47) || [];
    if (surveyLanguages?.length) {
      return countryCodesWithNativeLanguages?.filter(item => !surveyLanguages.includes(item?.BCP47));
    }
    return countryCodesWithNativeLanguages;
  }, [newSurvey?.languages])

  const timezones = moment.tz.names();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="survey-settings-title"
      aria-describedby="survey-settings-description"
      container={container || undefined}
      disableEnforceFocus={true}
      disableScrollLock={true}
      disablePortal
      slotProps={{
        backdrop: {
          sx: {
            position: "fixed",
            maxWidth: `calc(100% - ${aiDrawerOpen ? aiDrawerWidth : "0px"})`,
            // height: container?.clientHeight || "100%",
            backdropFilter: "blur(4px)",
            bgcolor:'#fff',
            zIndex: 2,
          }
        }
      }}
      sx={{
        maxWidth: `calc(100% - ${aiDrawerOpen ? aiDrawerWidth : "0px"})`,
        ml: aiDrawerOpen ? "80px" : 0,
        position: "fixed", // Ensure modal is positioned within the parent
        zIndex: 3,
      }}
    >
      <Stack sx={{...style, transform: aiDrawerOpen ? "translate(-60%, -50%)" : "translate(-50%, -50%)",}} direction={"column"} gap={2}>

        <Typography fontWeight={600} variant={"h4"}>Survey Settings</Typography>

        <Box sx={{flex: 1}}>
          <AutocompleteWithLabel
            label={"Timezone"} options={timezones} value={newSurvey?.timezone || ""}
            onChange={(value) => setNewSurvey(prev => ({...prev, "timezone": value}))} name={"timezone"}
          />

        </Box>
        <Stack direction={"row"} gap={2}>
          <Box sx={{flex: 1}}>
            <Typography>Start</Typography>
            <DateTimePicker
              sx={{width: "100%"}} ampm timezone={survey?.timezone || "UTC"}
              value={survey?.start ? moment(survey?.start) : null} // Convert timestamp to Moment.js object
              onChange={(newValue) => setNewSurvey(prev => ({...prev, start: moment(newValue).valueOf()}))}
              renderInput={(params) => <TextField {...params} />}
              slotProps={{
                inputAdornment: { sx: { mr: 2 } },
              }}
            />
          </Box>
          <Box sx={{flex: 1}}>
            <Typography>End</Typography>
            <DateTimePicker
              sx={{width: "100%"}} ampm timezone={survey?.timezone || "UTC"}
              value={survey?.end ? moment(survey?.end) : null} // Convert timestamp to Moment.js object
              onChange={(newValue) => setNewSurvey(prev => ({...prev, end: moment(newValue).valueOf()}))}
              renderInput={(params) => <TextField {...params} />}
              slotProps={{
                inputAdornment: { sx: { mr: 2 } },
              }}
            />
          </Box>
        </Stack>
        <Box>
          <Typography>Languages</Typography>
          <Autocomplete
            id="country-select-demo"
            sx={{flex: 1, marginTop: "10px"}}
            value={null} disabled={loading}
            placeholder="Select survey languages"
            options={filteredCountryLanguages}
            onChange={(e, newValue) => {
              addLanguage(newValue);
              e.target.value = "";
            }}
            blurOnSelect={true}
            getOptionLabel={(option) => option.Name}
            autoComplete={false} clearOnBlur={true}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={"Add Language"}
                inputProps={{
                  // autoComplete: 'new-password',
                  autoComplete: 'off',
                  ...params.inputProps,
                  form: {
                    autoComplete: 'off',
                  },
                }}
              />
            )}
          />
        </Box>
        <Stack direction={"row"} gap={1} useFlexGap flexWrap={"wrap"}>
          {newSurvey?.languages?.map(item => {
            return (<Chip key={item?.BCP47} disabled={item?.BCP47 === "en-US" || loading} label={item?.Name}
                          onDelete={() => deleteLanguage(item?.BCP47)}/>)
          })}
        </Stack>
        <Box>
          <FormLabel component="legend">Response Data</FormLabel>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                disabled={loading}
                control={
                  <Switch checked={newSurvey?.getUserIPAddress} color={"secondary"}
                          onChange={(event, checked) => setNewSurvey(prev => ({...prev, getUserIPAddress: checked}))}/>
                }
                label="Collect respondents approximate geolocation"
              />
              <FormControlLabel
                disabled={loading}
                control={
                  <Switch checked={newSurvey?.saveAudioRecordings} color={"secondary"}
                          onChange={(event, checked) => setNewSurvey(prev => ({
                            ...prev,
                            saveAudioRecordings: checked
                          }))}/>
                }
                label="Save audio recordings of user voice responses"
              />
            </FormGroup>
          </FormControl>
        </Box>

        <Stack direction={"row"} gap={2} justifyContent={"flex-end"}>
          <Button
            className={"gradient-outlined-btn"} disabled={loading}
            variant={"outlined"} sx={{borderRadius: 2}}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={"gradient-btn"} disabled={loading}
            sx={{borderRadius: 2, color: "#fefefe", width: "auto !important"}}
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>

      </Stack>
    </Modal>
  )
}
