import { Typography } from "@mui/material";
import { useAtom } from "jotai";
import React from "react";
import { AnswerAtom } from "../atoms";
import { InputText } from "./InputText";
import QuestionTitle from "./QuestionTitle";
import { Stack } from "@mui/material";
export const TextQuestion = () => {
  const [answer, setAnswer] = useAtom(AnswerAtom);

  console.log(answer?.text );

  return (
    <>
      <InputText value={answer?.text || ""} onChange={(e) => setAnswer(prev => ({ ...prev, text: e?.target?.value }))} />
    </>
  )
}