import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useContext, useEffect, useState } from "react";
import { useAuthContext } from "../../../contexts/auth_context";
import { enums } from "../../../utils/enums";
import { AppContext } from "../../../utils/AppContext";

export const CreateSurveyBanner = () => {
  const { user } = useAuthContext();
  const { state: { workspaces, workspaceId } } = useContext(AppContext);
  const [messageText, setMessageText] = useState("")

  useEffect(() => {
    if (!workspaces || workspaces.length === 0 || !workspaceId || !user) return
    console.log("workspaces", workspaces)
    console.log("workspaceId", workspaceId)
    const workspace = workspaces.find(w => w.uid === workspaceId);
    setMessageText(getCreateSurveyText(user, workspace))
  }, [workspaces, workspaceId, user])

  const getCreateSurveyText = (user, workspace) => {
    console.log("workspace", workspace)
    if (!user) return "You are not logged in, please log in to create surveys."
    if (workspace.memberDetails.some(m => m.uid === user.uid && m.role === enums.VIEWER)) return "You are a viewer, surveys will show up here when admininstrator creates them or you can create surveys by switching to an editor or admin account/workspace."
    return "Create your first survey using button up top! 🚀"
  }
  if (workspaces.length === 0) return null;
  
  return (
    <Stack direction={"column"} gap={2}>
      <Typography variant={"h5"} fontWeight={600} sx={{ marginTop: 5 }}>
       There are no surveys yet.
      </Typography>
      <Typography variant={"h5"} fontWeight={600}>
        {messageText}
      </Typography>
      <Box>
        {/* <Button
        disabled={shouldDisableButton(currentWorkspace, state, user)}
        component={Link}
        to={"/create-survey"}
        sx={{
          mt: 2,
          padding: "6px 18px", width: "auto !important",
          display: {xs: "none", md: "inline-flex"},
        }}
        variant={"contained"}
        className={"gradient-btn"}
      >
        <AddIcon sx={{height: "18px"}}/>
        <Typography sx={{fontSize: "1em"}}>Create Survey</Typography>
      </Button> */}
      </Box>
    </Stack>
  )
}