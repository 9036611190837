import {Box, Stack, TextField} from "@mui/material";
import {InputWithLabel} from "../create-question-modal";
import React from "react";


export const SliderQuestion = ({question, onChange}) => {
  console.log(question);
  return (
    <Stack direction={"row"} gap={2}>
      <Box sx={{flex: 1}}>
        <InputWithLabel label={"From"}>
          <TextField
            value={question.from} onChange={onChange} name={"from"} disabled={!!question?.originalReferenceUid}
            type={"number"} fullWidth placeholder={"0"} helperText={"Select minimum range"}
            required error={question?.from === "" || question?.from === null || question?.from === undefined || question?.from === question?.to}
          />
        </InputWithLabel>
      </Box>
      <Box sx={{flex: 1}}>
        <InputWithLabel label={"To"}>
          <TextField
            value={question?.to} onChange={onChange} name={"to"} disabled={!!question?.originalReferenceUid}
            type={"number"} fullWidth placeholder={"100"} helperText={"Select maximum range"}
            required error={question?.to === "" || question?.to === null || question?.to === undefined || question?.from === question?.to}
          />
        </InputWithLabel>
      </Box>
    </Stack>
  )
}