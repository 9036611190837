import {Button, Stack, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import {ChevronLeft} from "@mui/icons-material";

export const PageNotFound = () => (
  <Stack  sx={{width: "100%", height: "100%", transform: "translateY(-10%)", zIndex: 1000}}
         justifyContent={"center"}
         alignItems={"center"}>
    <Typography color={'primary'} textTransform={"uppercase"}
                fontWeight={"bold"}
                fontSize={100}>404</Typography>
    <Typography color={'primary'} textTransform={"uppercase"}
                fontSize={45} align={"center"}>Page
      Not Found</Typography>
    <Button variant={"contained"} startIcon={<ChevronLeft />} component={Link} to={"/"}>Back</Button>
  </Stack>
)