import { useCallback } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../../utils/firebaseApp';
import { toast } from 'react-toastify';

export const useSaveWorkspace = () => {
  const saveWorkspace = useCallback(async (workspaceData) => {
    if (!workspaceData?.uid) return;
    
    try {
      await setDoc(doc(db, 'workspaces', workspaceData.uid), workspaceData);
      console.log('Workspace saved successfully');
    } catch (error) {
      console.error('Error saving workspace:', error);
      toast.error('Error saving workspace');
    }
  }, []);

  return { saveWorkspace };
}; 