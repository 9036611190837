
export const QuestionTypes = {
  TEXT: 'text',
  VOICE: 'voice',
  MULTICHOICE: 'multichoice',
  MAPINPUT: 'mapInput',
  IMAGE: "image",
  IMAGE_GALLERY: "imageGallery",
  SECTION: "section",
  LOCATE_USER: 'locateuser',
  DROPDOWN: 'dropdown',
  CHECKBOXES: 'checkboxes',
  SLIDER: 'slider',
}

export const QuestionStatusType = {
  TRANSLATED: "TRANSLATED",
  TRANSLATING: "TRANSLATING",
  FAILED: "FAILED",
}