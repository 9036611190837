import {useSaveSurvey} from "../hooks/useSaveSurvey";
import {Button, IconButton, Input, Stack, Tooltip, Typography} from "@mui/material";
import {useAtom, useSetAtom} from "jotai";
import {AiDrawerState, CreateSurveySettingsState, SelectedLanguageState} from "../atoms";
import React, {useEffect, useMemo, useState} from "react";
import {ChevronLeft, ChevronRight, Close, QrCodeScanner, Save, Settings} from "@mui/icons-material";
import Box from "@mui/material/Box";
import ViewIcon from "../../../assets/icons/view-survey.png";
import {animals, colors, uniqueNamesGenerator} from "unique-names-generator";
import {IconExternalLink, IconSparkles} from "@tabler/icons-react";
import {ShareQrCodeModal} from "../../../components/survey-qrcode/ShareQrCodeModal";
import {Tabs, Tab} from "@mui/material";
import {grey} from "@mui/material/colors";

export const SurveyInformation = ({tabIndex, setTabIndex, availableViews}) => {
  const {survey, saveOnChange, setSurvey, saveSurvey} = useSaveSurvey();
  const [selectedLanguage, setSelectedLanguage] = useAtom(SelectedLanguageState);
  const setOpenAiDrawer = useSetAtom(AiDrawerState);
  const [defaultName, setDefaultName] = useState("");
  const [shareSurvey, setShareSurvey] = useState(false);
  const setOpenSettings = useSetAtom(CreateSurveySettingsState);


  useEffect(() => {
    if (!survey?.surveyName && !survey?.uid) {
      const name = uniqueNamesGenerator({
        dictionaries: [colors, animals],
        style: "lowerCase",
        separator: "-",
      })
      setDefaultName("");
      setSurvey(prev => ({...prev, surveyName: name}))
    } else {
      setDefaultName(survey?.surveyName || "");
    }
  }, [])

  useEffect(() => {
    if (survey?.languages && survey?.languages?.length) {
      const isExisting = survey?.languages?.find(i => i?.BCP47 === selectedLanguage?.BCP47);
      if (!isExisting) {
        setSelectedLanguage(survey?.languages[0] || null)
      }
    }
  }, [selectedLanguage, survey?.languages])

  useEffect(() => {
    if (selectedLanguage === null && survey?.languages?.length) {
      setSelectedLanguage(survey.languages[0]);
    }
  }, [selectedLanguage])


  const onSelectLanguage = (index) => {
    const language = survey?.languages[index] || null;

    if (language) {
      setSelectedLanguage(language);
    }
  }

  const onChange = e => {
    const key = e?.target?.name;
    const value = e?.target?.value;
    if (key) {
      onUpdateSurvey(key, value);
    }
  }

  const onUpdateSurvey = (key, value) => {
    setSurvey(prev => ({...prev, [key]: value}));
  }

  const handleOnBlur = () => {
    if (saveOnChange) {
      saveSurvey();
    }
  }

  const url = useMemo(() => {
    if (survey)
      return `${window.location.origin}/survey/${survey?.uid}`;
    return null;
  }, [survey]);

  const index = useMemo(() => {
    return survey?.languages?.findIndex(item => item?.BCP47 === selectedLanguage?.BCP47) || 0;
  }, [selectedLanguage, survey?.languages])

  return (
    <>
      <Stack gap={1}
             alignItems={"flex-start"}
             justifyContent={"space-between"} className={"border-shadow"}
             sx={{
               flexDirection: {xs: "column-reverse", sm: "row"},
               backgroundColor: "#ffffff",
               borderRadius: 2,
               // boxShadow: 1,
               p: 2,
               mb: 2
             }}
             useFlexGap flexWrap={"wrap"}
      >
        <ShareQrCodeModal
          open={!!shareSurvey}
          handleClose={() => setShareSurvey(false)}
          urlToDisplay={url}
          title={survey?.surveyName || defaultName}
          subtitle={"By sharing this link you're sharing it with the public"}
        />

        <Tabs value={tabIndex} onChange={(e, value) => setTabIndex(value)}>
          {availableViews.map((view, index) => (
            <Tab key={index} label={view} value={index}/>
          ))}
        </Tabs>

        <Box sx={{flex: 1}}>
          <Stack direction={"row"} gap={1} justifyContent={"flex-end"} alignItems={"center"}>
            <Stack direction={"row"} gap={1} alignItems={"center"} sx={{mx: 'auto'}}>
              <IconButton
                disabled={index === 0}
                onClick={() => onSelectLanguage(index - 1)}
              >
                <ChevronLeft/>
              </IconButton>
              <Typography fontSize={"1em"} fontWeight={500} color={"primary.main"}>{selectedLanguage?.Native}</Typography>
              <IconButton
                disabled={index === survey?.languages?.length - 1}
                onClick={() => onSelectLanguage(index + 1)}
              >
                <ChevronRight/>
              </IconButton>
            </Stack>
            <Tooltip title={"AI Chat"}>
              <IconButton color={"primary"} onClick={() => setOpenAiDrawer(true)}>
                <IconSparkles/>
              </IconButton>
            </Tooltip>
            <Tooltip title={"Settings"}>
              <IconButton color={"info"} onClick={() => setOpenSettings(true)}>
                <Settings/>
              </IconButton>
            </Tooltip>
            <Tooltip title={"View live survey"}>
              <span>

                <IconButton
                  variant={"contained"}
                  size={"small"} color={"info"}
                  disabled={!survey?.uid}
                  component={"a"}
                  href={window?.location?.origin + "/survey/" + survey.uid}
                  target={"_blank"}
                >
                  <IconExternalLink/>
                  {/*<img*/}
                  {/*  src={ViewIcon}*/}
                  {/*  alt={"View survey"}*/}
                  {/*  style={{ width: "24px", filter: survey?.uid ? undefined : "grayscale(100%)" }}*/}
                  {/*/>*/}
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={"View and download QR code"}>
              <span>
                <IconButton
                  variant={"contained"} color={"info"}
                  disabled={!survey?.uid}
                  onClick={() => setShareSurvey(true)}
                >
                  <QrCodeScanner/>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={"Save Survey"}>
              <Button
                color={"info"} size={"large"}
                startIcon={<Save/>}
                variant={"text"}
                sx={{borderRadius: 3, px: 2}}
                onClick={() => saveSurvey()}
              >
                Save
              </Button>
            </Tooltip>
          </Stack>
          {/* {survey?.dateUpdated &&
            <Typography variant={"body2"} align={"right"}>Last saved: {moment(survey?.dateUpdated).fromNow(true)}</Typography>} */}
        </Box>
      </Stack>
      <Input
        value={survey?.surveyName}
        name={"surveyName"} disableUnderline
        onChange={onChange}
        onBlur={handleOnBlur}
        label={"Survey name"}
        sx={{
          fontSize: "1.5rem",
          color: "primary.main",
          my: 2,
          maxWidth: "100%",
          marginLeft: "50px",
          borderBottom: 2,  // Add border bottom
          borderColor: grey[300],
          '& .MuiInputBase-root': {  // Targeting the Input root for proper styling
            borderBottom: 2,  // Add border bottom
            borderColor: grey[300],
          },
          '&:hover .MuiInputBase-root': {  // Optional: Change color on hover
            borderBottom: 2,  // Add border bottom
            borderColor: grey[400],
          },
          '&.Mui-focused': {  // Optional: Change color when focused
            borderBottom: 2,  // Add border bottom
            borderColor: "#5271FF !important",
          }
        }}
        endAdornment={<IconButton onClick={() => onUpdateSurvey("surveyName", "")}><Close/></IconButton>}
        placeholder={defaultName || "Enter your survey name"}
      />
    </>
  )
}