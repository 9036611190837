import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {PrismLight as SyntaxHighlighter} from "react-syntax-highlighter";
import jsx from "react-syntax-highlighter/dist/cjs/languages/prism/jsx";
// import { style } from 'react-syntax-highlighter/dist/esm/styles/{hljs|prism}'
import {prism} from "react-syntax-highlighter/dist/esm/styles/prism";
import {IconButton} from "@mui/material";
import {CopyAll} from "@mui/icons-material";
import {copyToClipboard} from "../../../utils/functions";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: {p: 0}
  };
}

export default function CodeSampleTabs({apiKey, surveyUid}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{borderRadius: 5, px: 2, pb: 2, pt: 1, bgcolor: "#FFF"}}
      className={"border-shadow"}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="JavaScript" {...a11yProps(0)} />
        <Tab label="Postman" {...a11yProps(1)} />
        <Tab label="Python" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0} sx={{border: 0}}>
        <CodeSamples apiKey={apiKey} surveyUid={surveyUid} language={"JavaScript"}/>
      </TabPanel>
      <TabPanel value={value} index={2} sx={{border: 0}}>
        <CodeSamples
          apiKey={apiKey}
          surveyUid={surveyUid}
          language={"Python"}
        />
      </TabPanel>
      <TabPanel value={value} index={1} sx={{border: 0}}>
        <CodeSamples
          apiKey={apiKey}
          surveyUid={surveyUid}
          language={"postman"}
        />
      </TabPanel>
    </Box>
  );
}

function CodeSamples({language, apiKey, surveyUid}) {
  SyntaxHighlighter.registerLanguage("jsx", jsx);
  const hiddenApi = apiKey !== "api-key" ? new Array(apiKey?.length)?.fill("*")?.join('') : apiKey
const functionToCall = 'https://getanswers-uctffb6poa-nn.a.run.app'

  const axiosString =
    "" +
    "await axios({\n" +
    '   method: "POST",\n' +
    `   url: "${functionToCall}",\n` +
    `   data: {apiKey:"${hiddenApi}", surveyUid:"${surveyUid}"}\n` +
    "})";
  const pythonString =
    "import requests\n" +
    "\n" +
    "data = {\n" +
    `    \"apiKey\": \"${hiddenApi}\",\n` +
    `    \"surveyUid\": \"${surveyUid}\"\n` +
    "}\n" +
    "\n" +
    `response = requests.post("${functionToCall}", json=data)\n` +
    "print(response.json())\n";
  const postmanString =
    "Select POST\n" +
    `Paste ${functionToCall}\n` +
    "Select body\n" +
    "Select x-www-form-urlencoded\n" +
    `apiKey:${hiddenApi}\n` +
    `surveyUid:${surveyUid}`;

  function getLanguage() {
    switch (language) {
      case "JavaScript":
        return axiosString;
      case "postman":
        return postmanString;
      case "Python":
        return pythonString;
      default:
        throw new Error("Language not supported");
    }
  }

  const copyCode = () => {
    const hiddenApi = apiKey !== "api-key" ? new Array(apiKey?.length)?.fill("*")?.join('') : apiKey
    const code = getLanguage().replaceAll(hiddenApi, apiKey);

    copyToClipboard(code);
  }

  return (
    <Box sx={{position: "relative"}}>
      <IconButton onClick={copyCode} sx={{position: "absolute", top: 10, right: 10}}>
        <CopyAll/>
      </IconButton>
      <SyntaxHighlighter
        language={"jsx"}
        showLineNumbers={true}
        style={prism}
        className={"syntax-code-block"}
        wrapLines={true}
      >
        {getLanguage()}
      </SyntaxHighlighter>
    </Box>
  );
}
