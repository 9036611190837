import { Stack, Typography } from "@mui/material";
import { useAtom } from "jotai";
import React from "react";
import MapComponent from "../../../components/Map/MapComponent";
import { AnswerAtom } from "../atoms";
import QuestionTitle from "./QuestionTitle";
export const MapInputQuestion = () => {
  const [answer, setAnswer] = useAtom(AnswerAtom);

  return (
    <>

      <MapComponent
        questionObject={answer} userCanSetPOIs={true}
        setPois={(newPois) => setAnswer(prev => ({...prev, pois: newPois}))} pois={answer?.pois || []}
        showUserDot={true}
      />

    </>
  )
}