import {Box, Button, IconButton, Modal, Stack, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {IconTrash, IconPlus} from "@tabler/icons-react";
import {topicColors} from "../../../utils/topics";
import {doc, updateDoc, writeBatch} from "firebase/firestore";
import {db, functions} from "../../../utils/firebaseApp";
import {QuestionStatusType} from "../../create-project-page-new/enums";
import {toast} from "react-toastify";
import {httpsCallable} from "firebase/functions";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "md",
  width: '80vw',
  maxHeight: "80vh",
  bgcolor: 'background.paper',
  flexDirection: "column",
  gap: 1,
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 3,
  borderRadius: '15px',
  outline: 'none'
};


export const ClassifyByTopicsModal = ({open, handleClose, responses, question}) => {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const discoverTopics = httpsCallable(functions, "discoverTopics");
  const applyTopicsToAnswers = httpsCallable(functions, "applyTopicsToAnswers");

  useEffect(() => {
    if (question?.topics) {
      setTopics(question?.topics || [])
    }
  }, [question?.topics]);

  const onClose = () => {
    if (loading) {
      return;
    }
    handleClose();
    setTopics([]);
    // TODO Reset state
  }

  const updateQuestion = async () => {
    try { 
      await updateDoc(doc(db, "questions", question.uid), { topics: topics });
    } catch (error) {
      console.error(error);
    }
  }

  const onUpdateTopics = async () => {
    try {
      setLoading(true)
      await updateQuestion();
      toast.success("Question updated with new topics");
      handleClose();
    } catch (error) {
      toast.error("Failed updated update question with new topics");
    } finally {
      setLoading(false)
    }
  }

  const onReclassify = async () => {
    const toastId = toast.loading("Reclassifying answers with topics...");
    try {
      setLoading(true);
      await updateQuestion();
      const existingTopics = topics?.map(i => i?.name) || [];
      const answerObjects = responses?.map(i => ({uid: i?.uid, text: i?.text})) || [];

      console.log(existingTopics, answerObjects);
      const response = await applyTopicsToAnswers({ question: question?.question, existingTopics, answerObjects });
      const content = response?.data?.message?.content
        ?.replaceAll("`", "")
        ?.replaceAll("json", "")
        ?.replaceAll("\n", " ") || null;

      console.log(response);
      console.log(content);

      const answersToUpdate = {}; // Map where key = answer UID, value = classifiedTopics array
      if (content && typeof content === "string") {
        const data = JSON.parse(content);
        if (data && data?.answers) {
          for (const answerObj of data?.answers || []) {
            const answer = responses?.find(ans => ans?.uid === answerObj?.uid) || null;
            const topic = topics?.find(t => t?.name?.toLowerCase() === answerObj?.topic?.toLowerCase()) || null;

            if (answer && topic) {
              if (!answersToUpdate[answer.uid]) {
                answersToUpdate[answer.uid] = new Set(answer.classifiedTopics || []);
              }
              answersToUpdate[answer.uid].add(topic.uid);
            }
          }
        }
      }
      if (Object.keys(answersToUpdate)) {
        const batch = writeBatch(db);
        console.log(answersToUpdate)
        for (const responseUid of Object.keys(answersToUpdate)) {
          console.log(responseUid)

          const classifiedTopics = answersToUpdate[responseUid];
          console.log(`batch update ${responseUid}`, classifiedTopics)
          batch.update(doc(db, "answers", responseUid), {classifiedTopics: [...(classifiedTopics || [])]})
        }
        await batch.commit();
      }
      onClose();
      toast.update(toastId, {
        render: "Answers successfully reclassified with topics!",
        isLoading: false,
        type: "success",
        autoClose: 4000
      });
    } catch (error) {
      console.log(error);
      toast.update(toastId, {
        render: "Failed to update question with new topics. Please try again.",
        isLoading: false,
        type: "error",
        autoClose: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  const onDiscoverTopics = async () => {
    const toastId = toast.loading("Discovering new topics from answers...");
    try {
      setLoading(true);
      const existingTopics = topics?.map(i => i?.name) || [];
      const answerObjects = [...new Set(responses?.map(i => i?.text?.toLowerCase()) || [])] || [];

      const response = await discoverTopics({ question: question?.question, existingTopics, answerObjects });
      const content = response?.data?.message?.content
        ?.replaceAll("`", "")
        ?.replaceAll("json", "")
        ?.replaceAll("\n", " ") || null;

      const newTopics = [];
      if (content && typeof content === "string") {
        const data = JSON.parse(content);
        if (data && data?.unique_topics) {
          for (const topic of data?.unique_topics || []) {
            const exist = topics?.find(t => t?.name?.toLowerCase() === topic?.toLowerCase()) || null;
            if (!exist) {
              newTopics.push({ name: topic, color: topicColors[topics?.length + newTopics?.length] || "#040404", uid: crypto.randomUUID() });
            }
          }
        }
      }
      setTopics(prev => ([...prev, ...newTopics]));

      toast.update(toastId, {
        render: newTopics?.length ? `Discovered ${newTopics?.length} new topics!` : "No new topics found.",
        isLoading: false,
        type: newTopics?.length ? "success" : "info",
        autoClose: 4000
      });
    } catch (error) {
      console.error(error);
      toast.update(toastId, {
        render: error?.message || "Failed to discover new topics. Please try again.",
        isLoading: false,
        type: "error",
        autoClose: 2000
      });
    } finally {
      setLoading(false);
    }
  };


  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={style} direction={"column"} gap={2}>
        <Box>
          <Typography variant={"h4"} fontWeight={600} sx={{pb: 1}}>Set Topics</Typography>
          <Typography variant={"body2"} fontWeight={300}>
            These topics will be used to tag your responses. A response can be tagged with more than one topic.
          </Typography>
          <Typography variant={"body2"} fontWeight={300}>
            To have AI suggest topics, click the button below.
          </Typography>
        </Box>
        <Box sx={{pb: 4}}>
          <Button onClick={onDiscoverTopics} disabled={loading} variant={"contained"} sx={{borderRadius: 2}}>
            Discover new topics
          </Button>
        </Box>
        <Stack direction={"column"} gap={0} sx={{maxHeight: "40dvh", overflowY: "auto"}}>
          <Typography variant={"body2"} fontWeight={600} color={"secondary.dark"} sx={{pb: 1}}>Topics:</Typography>
          {
            topics?.map((topic, index) => <TopicRow key={topic?.uid} index={index} topics={topics} topic={topic} setTopics={setTopics} />)
          }
        </Stack>
        <Box sx={{py: 4}}>
          <Button
            onClick={() => setTopics(prev => ([...(prev || []), {uid: crypto.randomUUID(), name: "", color: topicColors[topics?.length - 1] || "#040404"}]))}
            variant={"outlined"} startIcon={<IconPlus size={18} />} sx={{borderRadius: 2}}
          >
            Add Option
          </Button>
        </Box>
        <Stack direction={"row"} gap={1}>
          <Button
            onClick={onClose}
            variant={"outlined"} sx={{borderRadius: 2}}
          >
            Close
          </Button>
          <Button
            onClick={onReclassify} disabled={topics?.some(i => i?.name?.length === 0) || loading}
            variant={"outlined"} sx={{borderRadius: 2, ml: "auto"}}
          >
            Reclassify all
          </Button>
          <Button
            onClick={onUpdateTopics} disabled={topics?.some(i => i?.name?.length === 0) || loading}
            variant={"contained"} sx={{borderRadius: 2}}
          >
            Only add to unclassified
          </Button>

        </Stack>
      </Stack>
    </Modal>
  )
}

export const TopicRow = ({topic, index, setTopics}) => {

  const onUpdateTopic = (newTopic) => {
    setTopics(prev => prev?.map(t => t?.uid === newTopic.uid ? {...newTopic} : {...t}));
  }

  const onDeleteTopic = (newTopic) => {
    setTopics(prev => prev?.filter(t => t?.uid !== newTopic.uid));
  }

  return (
    <Stack direction={"row"} gap={1} alignItems={"center"}>
      <Typography>{index + 1}.</Typography>
      <TextField
        sx={{flex: 1, mt: 0}} placeholder="Topic name" size={"small"}
        value={topic?.name} error={topic?.name?.length === 0}
        onChange={e => onUpdateTopic({...topic, name: e?.target?.value})}
      />
      <Box>
        <IconButton size={"small"} onClick={() => onDeleteTopic(topic)}><IconTrash /></IconButton>
      </Box>
    </Stack>
  )
}