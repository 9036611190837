import {useAtom} from "jotai";
import {CreateSurveyState} from "../atoms";
import {v4 as uuid} from "uuid";
import {auth, db} from "../../../utils/firebaseApp";
import {useState} from "react";
import {toast} from "react-toastify";
import {doc, setDoc} from "firebase/firestore";

export const useSaveSurvey = () => {
  const [survey, setSurvey] = useAtom(CreateSurveyState);
  const [isPending, setIsPending] = useState(false);
  const [saveOnChange, setSaveOnChange] = useState(false);

  const saveSurvey = async (surveyObject = null) => {
    if (!auth?.currentUser?.uid){
      return;
    }
    const newSurvey = surveyObject ? {...surveyObject} : {...survey};
    if (!newSurvey?.uid) {
      newSurvey.uid = uuid();
    }
    if (!newSurvey?.userUid) {
      newSurvey.userUid = auth?.currentUser?.uid;
    }
    if (!newSurvey?.dateCreated) {
      newSurvey.dateCreated = new Date().getTime();
    }
    newSurvey.dateUpdated = new Date().getTime();
    try {
      setIsPending(true);

      await setDoc(doc(db, "surveys", newSurvey.uid), newSurvey);
      setSurvey(newSurvey);
      toast.success("Survey saved")
    } catch (error) {
      toast.error(error?.message || "Failed to save survey");
    } finally {
      setIsPending(false);
    }
  }

  return {
    survey,
    setSurvey,
    saveSurvey,
    isPending,
    saveOnChange,
    setSaveOnChange
  }
}