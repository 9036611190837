import {httpsCallable} from "firebase/functions";
import {functions} from "../../../utils/firebaseApp";
import React, {useEffect, useMemo, useState} from "react";
import {Button, Modal, Stack, TextField, Typography} from "@mui/material";
import {v4 as uuid} from "uuid";
import {QuestionTypes} from "../enums";
import {useAtomValue} from "jotai/index";
import {AiDrawerState, CreateThankYouQuestionState, SelectedLanguageState} from "../atoms";
import {SectionQuestion} from "./questions/section-question";
import {InputWithLabel} from "./create-question-modal";
import {useSaveSurvey} from "../hooks/useSaveSurvey";
import {useAtom} from "jotai";
import {toast} from "react-toastify";
import {confirm} from "react-confirm-box";
import {confirmDialogOptions} from "../../../components/ConfirmDialog";
import {aiDrawerWidth} from "./AiDrawer";
import {uploadFile} from "../../../utils/functions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "700px", width: "95%",
  maxHeight: "80dvh", overflowY: "auto",
  borderRadius: "16px",
  bgcolor: "background.paper",
  p: 2, pb: 2,
  zIndex: 3
};

const initialQuestionState = {
  uid: uuid(),
  question: "Thank you for filling out our survey!",
  description: "",
  type: QuestionTypes.SECTION,
  userUid: null,
  surveyUid: null,
  imgUrl: "",
  languageObject: null,
  subTitle: "",
  bottomExplainer: "",
  link: ""
}

export const CreateThankYouQuestion = ({container}) => {
  const {survey, saveSurvey} = useSaveSurvey();
  const selectedLanguage = useAtomValue(SelectedLanguageState);
  const [open, setOpen] = useAtom(CreateThankYouQuestionState);
  const translateText = httpsCallable(functions, "translateText");
  const aiDrawerOpen = useAtomValue(AiDrawerState);
  const [question, setQuestion] = useState(JSON.parse(JSON.stringify(initialQuestionState || {})));
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (open && selectedLanguage) {
      if (survey?.thankYouPage) {
        console.log(survey?.thankYouPage)
        setQuestion({
          ...JSON.parse(JSON.stringify(initialQuestionState || {})),
          ...(survey?.thankYouPage || {}),
          question: survey?.thankYouPage?.question[selectedLanguage?.BCP47] || "",
          description: survey?.thankYouPage?.description[selectedLanguage?.BCP47] || "",
          subTitle: survey?.thankYouPage?.subTitle[selectedLanguage?.BCP47] || "",
          bottomExplainer: survey?.thankYouPage?.bottomExplainer[selectedLanguage?.BCP47] || "",
        })
      } else {
        setQuestion(JSON.parse(JSON.stringify(initialQuestionState)));
      }
    }
  }, [survey, open, selectedLanguage])

  const onUpdate = (key, value) => setQuestion(prev => ({...prev, [key]: value}))

  const onChange = e => {
    const key = e?.target?.name;
    const value = e?.target?.value;
    console.log("key", key);
    console.log("value", value);

    if (key) {
      onUpdate(key, value);
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onTranslate = async (text, from, to) => {
    if (!from || !text || !to) {
      return null;
    }
    try {
      const response = translateText({text: text, from: from, to: to});
      return response?.data?.translation || null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  const onSubmit = async () => {
    const toastId = toast.loading("Creating thank you page...");
    const translateForOtherFields = async (thankYouState = {}) => {
      thankYouState.question = {}
      thankYouState.description = {}
      thankYouState.subTitle = {}
      thankYouState.bottomExplainer = {}
      for (const lang of survey?.languages || []) {
        toast.update(toastId, {
          render: `The content is being translated into ${lang?.Native}.`,
          isLoading: true
        });
        thankYouState.question[lang?.BCP47] = selectedLanguage?.BCP47 === lang?.BCP47 ? question?.question : await onTranslate(question?.question || "", selectedLanguage?.BCP47, lang?.BCP47) || question?.question || "";
        thankYouState.description[lang?.BCP47] = selectedLanguage?.BCP47 === lang?.BCP47 ? question?.description : await onTranslate(question?.description || "", selectedLanguage?.BCP47, lang?.BCP47) || question?.description || "";
        thankYouState.subTitle[lang?.BCP47] = selectedLanguage?.BCP47 === lang?.BCP47 ? question?.subTitle : await onTranslate(question?.subTitle || "", selectedLanguage?.BCP47, lang?.BCP47) || question?.subTitle || "";
        thankYouState.bottomExplainer[lang?.BCP47] = selectedLanguage?.BCP47 === lang?.BCP47 ? question?.bottomExplainer : await onTranslate(question?.bottomExplainer || "", selectedLanguage?.BCP47, lang?.BCP47) || question?.bottomExplainer || "";
      }
      return thankYouState;
    }
    const questionObj = {...question};
    if (image && image?.name) {
      try {
        toast.update(toastId, { render: "Uploading image......", type: "info", isLoading: true });
        const imgUid = uuid();
        questionObj.imgUrl = await uploadFile(image, "questions", `${imgUid}-${image?.name}`);
        toast.update(toastId, { render: "Image uploaded...", type: "info", isLoading: true });
      } catch (error) {
        toast.update(toastId, {
          render: "Image uploaded...",
          autoClose: 3000,
          type: "error",
          isLoading: false
        });
      }
    }
    if (survey?.thankYouPage) {
      const response = survey?.languages?.length > 0 ? await confirm(
        {
          title: `Do you want to translate in other languages?`,
          confirmText: "Yes",
          cancelText: "No",
          // subtitle: `This action will delete all questions in other languages that were created as a translation of this one.`,
        },
        confirmDialogOptions,
      ) : false;
      if (response) {
        const thankYouPage = await translateForOtherFields({...questionObj});
        await saveSurvey({...survey, thankYouPage: thankYouPage});
        toast.update(toastId, {
          render: `Thank you page created successfully`,
          type: "success", autoClose: 3000,
          isLoading: false
        });
      } else {
        const thankYouState = {...questionObj};
        thankYouState.question = {
          ...(survey?.thankYouPage?.question || {}),
          [selectedLanguage?.BCP47]: question?.question
        }
        thankYouState.description = {
          ...(survey?.thankYouPage?.description || {}),
          [selectedLanguage?.BCP47]: question?.description
        }
        thankYouState.subTitle = {
          ...(survey?.thankYouPage?.subTitle || {}),
          [selectedLanguage?.BCP47]: question?.subTitle
        }
        thankYouState.bottomExplainer = {
          ...(survey?.thankYouPage?.bottomExplainer || {}),
          [selectedLanguage?.BCP47]: question?.bottomExplainer
        }
        await saveSurvey({...survey, thankYouPage: thankYouState});
        toast.update(toastId, {
          render: `Thank you page created successfully`,
          type: "success", autoClose: 3000,
          isLoading: false
        });
      }
    } else {
      const thankYouPage = await translateForOtherFields({...questionObj});
      await saveSurvey({...survey, thankYouPage: thankYouPage});
      toast.update(toastId, {
        render: `Thank you page created successfully`,
        type: "success", autoClose: 3000,
        isLoading: false
      });
    }
    handleClose();
  }

  const isValid = useMemo(() => {
    return true
  }, [])

  return (
    <Modal
      open={open && !!survey}
      onClose={handleClose}
      container={container}
      aria-labelledby="question-title"
      aria-describedby="question-description"
      disableEnforceFocus={true}
      disableScrollLock={true}
      slotProps={{
        backdrop: {
          sx: {
            position: "fixed",
            maxWidth: `calc(100% - ${aiDrawerOpen ? 400 : 0}px)`,
            // height: container?.clientHeight || "100%",
            backdropFilter: "blur(4px)",
            backgroundColor: 'rgba(0,0,30,0.2)',
            zIndex: 2,
          }
        }
      }}
      sx={{
        maxWidth: `calc(100% - ${aiDrawerOpen ? aiDrawerWidth : "0px"})`,
        ml: aiDrawerOpen ? "80px" : 0,
        position: "fixed", // Ensure modal is positioned within the parent
        zIndex: 3,
      }}
    >
      <Stack sx={style} direction={"column"} gap={0}>
        <Typography fontWeight={400} variant={"h4"}
                    sx={{p: 2}}>Thank You Page</Typography>
        <Stack direction={"column"} gap={3} sx={{flex: 1, overflowY: "auto", p: 2}}>

          <InputWithLabel
            label={"Title"}>
            <TextField
              value={question?.question} onChange={onChange} name={"question"}
              fullWidth
              placeholder={"This is the banner respondents will see"}
              multiline minRows={1} maxRows={2}
            />
          </InputWithLabel>
          <SectionQuestion
            question={question} onUpdate={onUpdate}
            onChange={onChange}
            image={image} setImage={setImage}/>
        </Stack>

        <Stack direction={"row"} gap={1} justifyContent={"flex-end"} sx={{ px: 2, pt: 2 }}>
          <Button
            className={"gradient-outlined-btn"}
            variant={"outlined"} sx={{ borderRadius: 2 }}
            onClick={handleClose} disabled={loading}
          >
            Cancel
          </Button>
          <Button
            className={"gradient-btn"}
            sx={{ borderRadius: 2, color: "#fefefe", width: "auto !important" }}
            onClick={onSubmit}
            disabled={!isValid || loading}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}