import {
  ArrowDropDownCircleOutlined,
  CheckBox,
  ControlPointDuplicate,
  Image,
  Input,
  MyLocationOutlined,
  KeyboardVoice,
  LinearScale,
  LocationOn,
  Dashboard,
  ViewStream
} from "@mui/icons-material";
import { QuestionTypes } from "../pages/create-project-page-new/enums";

export const questionItems = {
  [QuestionTypes.TEXT]: {
    value: QuestionTypes.TEXT,
    name: "Text",
    icon: Input
  },
  [QuestionTypes.VOICE]: {
    value: QuestionTypes.VOICE,
    name: "Voice",
    icon: KeyboardVoice
  },
  [QuestionTypes.MULTICHOICE]: {
    value: QuestionTypes.MULTICHOICE,
    name: "Multiple Choice (Single Select)",
    icon: ControlPointDuplicate
  },
  [QuestionTypes.CHECKBOXES]: {
    value: QuestionTypes.CHECKBOXES,
    name: "Checkboxes (Multi Select)",
    icon: CheckBox
  },
  [QuestionTypes.SLIDER]: {
    value: QuestionTypes.SLIDER,
    name: "Slider",
    icon: LinearScale
  },
  [QuestionTypes.DROPDOWN]: {
    value: QuestionTypes.DROPDOWN,
    name: "Dropdown (Multi Select)",
    icon: ArrowDropDownCircleOutlined
  },
  [QuestionTypes.MAPINPUT]: {
    value: QuestionTypes.MAPINPUT,
    name: "Map Input",
    icon: LocationOn
  },
  [QuestionTypes.IMAGE]: {
    value: QuestionTypes.IMAGE,
    name: "Image Answer",
    icon: Image
  },
  [QuestionTypes.SECTION]: {
    value: QuestionTypes.SECTION,
    name: "Section",
    icon: ViewStream
  },
  [QuestionTypes.LOCATE_USER]: {
    value: QuestionTypes.LOCATE_USER,
    name: "Geolocate User",
    icon: MyLocationOutlined
  },
  [QuestionTypes.IMAGE_GALLERY]: {
    value: QuestionTypes.IMAGE_GALLERY,
    name: "Image Gallery",
    icon: Dashboard
  }
};
