import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {v4 as uuid} from "uuid";
import Box from "@mui/material/Box";
import Lottie from "lottie-react";
import recordingInProgressLt from "../../../assets/recording/lottie_ella_recording_running_click_to_stop.json";
import recordingStart from "../../../assets/recording/lottie_ella_click_here_start_recording.json";
import recordingCompletedLt from "../../../assets/recording/lottie_ella_recording_completed.json";
import {Button, Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import LinearProgress from "@mui/material/LinearProgress";
import {Mic, MicOutlined, Pause} from "@mui/icons-material";
import {toast} from "react-toastify";
import {useAtom} from "jotai";
import {VoiceRecordingState} from "../../create-project-page-new/atoms";

const lottieOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const MAX_DURATION = 60; // Maximum duration of 60 seconds

const AudioRecorder = ({onSubmit, duration, savedAudio}) => {
  const [isRecording, setIsRecording] = useAtom(VoiceRecordingState);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioDuration, setAudioDuration] = useState(0);

  // Ref to always capture the latest audioDuration value
  const audioDurationRef = useRef(0);
  const chunks = useRef([]);

  useEffect(() => {
    if (isRecording) {
      const interval = setInterval(() => {
        setAudioDuration((prev) => {
          audioDurationRef.current = prev + 1;
          return prev + 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isRecording]);

  useEffect(() => {
    if (duration) {
      setAudioDuration(duration);
      audioDurationRef.current = duration;
    }
  }, [duration]);

  useEffect(() => {
    if (audioDuration >= MAX_DURATION) {
      stopRecording();
    }
  }, [audioDuration]);

  useEffect(() => {
    // Cleanup when component unmounts
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stop();
      }
    };
  }, [mediaRecorder]);

  const startRecording = async () => {
    try {
      setAudioDuration(0);
      const stream = await navigator.mediaDevices.getUserMedia({audio: true});
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (event) => {
        chunks.current.push(event.data);
      };
      recorder.onstop = handleStop;
      setMediaRecorder(recorder);
      recorder.start();
      setIsRecording(true);
    } catch (error) {
      toast.error(error?.message || "Failed to start recording")
      console.error("Error starting audio recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
      setIsRecording(false);
    }
  };

  const handleStop = useCallback(async () => {
    const blob = new Blob(chunks.current);

    const base64String = await convertBlobToBase64(blob);
    // const base64Audio = base64String.substr(base64String.indexOf(",") + 1);
    // const audioBlob = await (await fetch(base64String)).blob();
    // console.log(audioBlob)
    // const answerUrl = URL.createObjectURL(blob);
    // const uid = uuid();
    // const answerAudioFile = new File([blob], `${uid}.webm`, {type: "audio/webm"});

    // setAudioUrl(answerUrl);
    // setAudioFile(answerAudioFile);

    // Call the onSubmit prop function with base64 and File object
    if (onSubmit) {
      const latestDuration = audioDurationRef.current;
      onSubmit({base64Audio: base64String, duration: latestDuration});
      chunks.current = [];
      audioDurationRef.current  = 0;
      setAudioDuration(0);
    }
  }, []);

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Remove the data URL prefix and just return the base64 data
        const base64data = reader.result.split(',')[1];
        resolve(base64data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  // Calculate percentage of the audio duration
  const audioPercent = useMemo(() => {
    if (audioDuration === 0) return 0;
    return Math.min((audioDuration / MAX_DURATION) * 100, 100);
  }, [audioDuration, MAX_DURATION]);

  const style = {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
  const lottieStyle = {
    height: 260,
    width: 260
  }
  return (
    <>
      <Stack spacing={2} sx={{ position: 'relative', height: '100%' }}>
        {isRecording ? (
          <>
            <Box onClick={stopRecording} sx={style}>
              <Lottie
                animationData={recordingInProgressLt}
                options={lottieOptions}
                style={lottieStyle}
              />
            </Box>
            <Box sx={{textAlign: 'center'}}>
              <Button onClick={stopRecording} variant={"outlined"} color={"error"}
                      startIcon={<Pause/>} sx={{border: 1, px: 2}}>
                Stop Recording
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box onClick={startRecording} sx={style}>
              <Lottie
                animationData={savedAudio ? recordingCompletedLt : recordingStart}
                options={lottieOptions}
                style={lottieStyle}
              />
            </Box>
            <Box sx={{textAlign: 'center'}}>
              <Button onClick={startRecording} className={"gradient-outlined-btn"}
                      startIcon={<Mic/>} sx={{border: 1, px: 2}}>
                { savedAudio? "Record Again" : "Start Recording" }
              </Button>
            </Box>
          </>
        )}

        <Stack direction={"column"} sx={{ maxWidth: "420px", width: '90%', mt: 2, mx: "auto !important" }} gap={1}>
          <Stack direction={"row"} sx={{width: "100%", mx: "auto"}} justifyContent={"space-between"}>
            <div/>
            <Typography variant={"subtitle2"} color={grey[500]}>
              {MAX_DURATION - audioDuration} seconds
            </Typography>
          </Stack>
          <LinearProgress variant={"determinate"} value={audioPercent}/>
        </Stack>
      </Stack>
    </>
  );
};

export default AudioRecorder;
