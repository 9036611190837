import {useAtom} from "jotai";
import {AnswerAtom, PrefetchedImagesAtom} from "../atoms";
import QuestionTitle from "./QuestionTitle";
import {FormControlLabel, RadioGroup, Typography, Stack, Grid, Box, Skeleton} from "@mui/material";
import React, {useEffect, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import {useAtomValue} from "jotai/index";

export const ImageGalleryQuestion = () => {
  const [answer, setAnswer] = useAtom(AnswerAtom);
  const images = useAtomValue(PrefetchedImagesAtom);
  const [showSkeleton, setShowSkeleton] = useState(false);

  useEffect(() => {
    if (answer?.uid) {
      setShowSkeleton(true);
      const timeout = setTimeout(() => setShowSkeleton(false), 1000);

      return () => {
        clearTimeout(timeout);
        setShowSkeleton(false);
      };
    }
  }, [answer?.uid])

  const onSelect = (option) => {
    setAnswer(prev => {
      const options = prev?.options?.map(i => ({...i, checked: i?.option === option ? !i?.checked : !!prev?.multipleChoice ? i?.checked : false}))
      return {
        ...prev,
        text: options?.filter(i => i?.checked)?.map(i => i?.option)?.join(", "),
        options: options
      }
    })
  }

  return (
    <>
      <Grid container  sx={{ mt: 1 }} alignItems={"center"} justifyContent={"center"}>
        {answer?.options?.map(item => (
          <Grid item xs={12} md={4} key={item.option} sx={{borderRadius: 1, p: 1, margin: 2,
            border: theme => `2px solid ${item?.checked ? theme.palette.primary.main : theme.palette.grey[300]}`,
          }} spacing={2}>
            <Box
              onClick={() => onSelect(item?.option)}
              sx={{
                position: 'relative',
                cursor: 'pointer',
                '&:hover': { opacity: 0.8 },
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  paddingTop: '100%', // 1:1 ratio
                  position: 'relative',
                  mb: 1,
                }}
              >
                {showSkeleton &&
                  <Skeleton
                    variant="rectangular" animation="wave"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      borderRadius: 1,
                      zIndex: 100
                    }}
                  />
                }
                <Box
                  component="img"
                  src={images[item.imageUrl]?.src || item.imageUrl}
                  alt={item.option}
                  sx={{
                    position: 'absolute',
                    top: 0, left: 0,
                    width: '100%', height: '100%',
                    objectFit: 'cover',
                    borderRadius: 1, zIndex: 1,
                    opacity: showSkeleton ? 0 : 1,
                  }}
                />
              </Box>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Checkbox
                  checked={item?.checked}
                  variant="rounded"
                />
                <Typography>{item?.option}</Typography>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}