import { MicOutlined } from "@mui/icons-material";
import AbcIcon from "@mui/icons-material/Abc";
import { Button, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { blue } from "@mui/material/colors";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { confirm } from "react-confirm-box";
import { confirmDialogOptions } from "../../../components/ConfirmDialog";
import { AnswerAtom } from "../atoms";
import AudioRecorder from "./AudioRecorder";
import { InputText } from "./InputText";
import QuestionTitle from "./QuestionTitle";

export const VoiceQuestion = () => {
  const [answer, setAnswer] = useAtom(AnswerAtom);
  const [view, setView] = useState("voice");

  useEffect(() => {
    if (answer?.uid) {
      setView("voice");
    }
  }, [answer?.uid]);

  const onChangeView = async (viewType) => {
    let confirmMessage = null
    if (viewType === "voice" && answer?.text) {
      confirmMessage = "You are about to switch from a written response to a recorded one. This will delete your current written answer. Are you sure you want to proceed?";
    } else if (viewType === "text" && answer?.voiceBase64) {
      confirmMessage = "You are about to switch from a recorded response to a written one. This will delete your current recorded answer. Are you sure you want to proceed?"
    }
    if (confirmMessage) {
      const response = await confirm(
        {
          title: confirmMessage,
        },
        confirmDialogOptions,
      );
      if (response === false) {
        return;
      }
      setAnswer(prev => ({ ...prev, text: "", voiceBase64: null, audioFile: null, audioDuration: 0 }))
    }
    setView(viewType);
  }
  console.log(answer);

  return (
    <>

      {
        {
          // "voice": <AudioRecorder duration={answer?.audioDuration || null} savedAudio={answer?.voiceBase64} onSubmit={({ base64Audio, answerAudioFile, duration }) => setAnswer(prev => ({...prev, voiceBase64: base64Audio, audioFile: answerAudioFile, audioDuration: duration}))} />,
          "voice": <AudioRecorder duration={answer?.audioDuration || null} savedAudio={answer?.voiceBase64} onSubmit={({ base64Audio, duration }) => setAnswer(prev => ({ ...prev, voiceBase64: base64Audio, audioDuration: duration }))} />,
          "text": <InputText value={answer?.text} onChange={e => setAnswer(prev => ({ ...prev, text: e?.target?.value }))} />,
        }[view]
      }
      <Stack direction={'row'} justifyContent={'center'} sx={{ pb: 0, my: "auto" }}>
        <Box
          sx={{
            p: "4px",
            bgcolor: blue[100],
            borderRadius: 5
          }}
        >
          <Button
            variant={view === "text" ? "contained" : "text"}
            sx={{
              borderRadius: 5
            }}
            onClick={() => onChangeView("text")}
          >
            <AbcIcon />
          </Button>
          <Button
            variant={view === "voice" ? "contained" : "text"}
            sx={{
              borderRadius: 5
            }}
            onClick={() => onChangeView("voice")}
          >
            <MicOutlined />
          </Button>
        </Box>
      </Stack>

    </>
  )
}