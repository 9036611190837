import {Stack, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import Lottie from "lottie-react";
import lottieFile from "../../../assets/lottie/lottie-not-available.json";
import React from "react";


export const SurveyNotAvailable = () => {

  return (
    <Stack maxWidth={"sm"} direction={"column"} gap={2} alignItems={"center"} justifyContent={"center"} sx={{mx: "auto", height: '100%', p: 2}}>
      <Typography variant={"h3"} sx={{width: "100%"}} color={grey[700]} fontWeight={700}>Survey Not Available</Typography>
      <Typography variant={"subtitle2"} fullWidth color={grey[400]}>This survey is either not yet open or has already closed. Please check with the survey administrator or try again later.</Typography>
      <Lottie
        animationData={lottieFile}
        loop={true}
        options={{
          autoplay: true,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        }}
        height={"auto"}
        width={"100%"}
      />
    </Stack>
  )
}