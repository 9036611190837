import Box from "@mui/material/Box";
import {Button, Modal, Stack, Typography} from "@mui/material";
import React from "react";
import {useAtomValue} from "jotai/index";
import {PrefetchedImagesAtom} from "../atoms";
import logoImg from "../../../assets/logo/Ella.svg";
import {Link} from "react-router-dom";
import {enums} from "../../../utils/enums";


export const ThankYouPage = ({survey, thankYouPage, languageBCP47}) => {
  const images = useAtomValue(PrefetchedImagesAtom);

  return (
    <Modal open={true}>
      <Stack
        direction={"column"} alignItems={"center"} justifyItems={"center"}
        sx={{
          // position: "absolute",
          maxHeight: "100dvh", width: "100dvw",
          height: "100%",
          backgroundx: "#fff",
          backgroundImage: "linear-gradient(45deg, #F6F2FF 0%, #DAE1FF 50%, #E5F3FF 100%) !important",
          zIndex: 1000
        }}
      >
        <Stack maxWidth={"1500px"} justifyContent={"center"} alignItems={"center"} gap={2}
               sx={{mx: "auto", my: "auto", flexDirection: {xs: "column", md: "row"}, p: {xs: 2, md: 5}}}>
          <Stack direction={"column"} gap={"20px"} sx={{flex: {xs: 0, md: 1}}}>
            <Typography sx={{
              fontSize: {xs: "36px", md: "50px"},
              color: "#2D2D2D"
            }}>{thankYouPage?.question[languageBCP47] || "Thank you"}</Typography>
            <Typography
              sx={{
                fontSize: {xs: "16px", md: "20px"},
                color: "#474747"
              }}
            >
              {thankYouPage?.subTitle[languageBCP47]}
            </Typography>
            <Typography sx={{fontSize: {xs: "16px", md: "20px"}, color: "#474747", fontWeight: 500}}>
              {thankYouPage?.bottomExplainer[languageBCP47]}
              &nbsp;
              <Typography component={"a"} href={thankYouPage?.link} target={"_blank"}
                          sx={{fontSize: "inherit", color: "inherit", fontWeight: 500}}>
                {thankYouPage?.link}
              </Typography>
            </Typography>
            {
              survey?.plan !== enums.ANNUAL && survey?.plan !== enums.MONTHLY &&
              <Box>
                <Button
                  component={Link}
                  to={"/"}
                  sx={{
                    width: {xs: "100%", md: "auto"},
                    color: "#FFFFFF",
                    bgcolor: "#8960FF",
                    borderRadius: 3,
                    p: {xs: 1, md: 2},
                    fontSize: {xs: "15px", md: "16px"},
                    backgroundImage: "linear-gradient(to right, #8960FF, #637DF7) !important",
                    "&:hover": {
                      bgcolor: "#637DF7",
                      backgroundImage: "linear-gradient(to right, #637DF7, #637DF7) !important",
                    }
                  }}
                >
                  Create your own Ella Survey
                </Button>
              </Box>
            }

          </Stack>
          <Box sx={{flex: {xs: 0, md: 1}}}>
            {
              thankYouPage?.imgUrl &&
              <img
                src={images[thankYouPage?.imgUrl]?.src || thankYouPage?.imgUrl}
                alt={"Thank you image"} style={{borderRadius: "24px", maxWidth: "600px"}}
                width={"100%"} height={"auto"}
              />
            }
          </Box>
        </Stack>
        {
          survey?.plan !== enums.ANNUAL &&
          <Box sx={{position: "absolute", bottom: 5, right: 10}}>
            <Link to={"/"}>
              <img src={logoImg} alt={"Logo"} height={50}/>
            </Link>
          </Box>
        }
      </Stack>
    </Modal>
  )
}