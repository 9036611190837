import { 
  Popover, 
  List, 
  ListItemButton, 
  ListItemText, 
  ListItemIcon,
  Divider,
  Box
} from '@mui/material';
import { useContext, useState } from 'react';
import { AppContext } from '../../utils/AppContext';
import { useNavigate } from 'react-router-dom';
import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../contexts/auth_context';
export const WorkspacesPopover = ({ anchorEl, onClose }) => {
  const { state, dispatch } = useContext(AppContext);
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const handleWorkspaceClick = (workspace) => {
    dispatch({workspaceId: workspace.uid});
    toast.success( `${workspace.name} activated`);
    onClose();
  };

  const handleManageClick = () => {
    navigate('/workspaces');
    onClose();
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <List sx={{ width: 250, maxHeight: 400, overflow: 'auto' }}>
        <ListItemText sx={{ color: 'secondary.dark', fontSize: '0.95em', fontWeight: 500, textAlign: 'center' }}>Click to activate workspace</ListItemText>
        {state.workspaces?.map((workspace) => (
          <ListItemButton 
            key={workspace.uid}
            onClick={() => handleWorkspaceClick(workspace)}
          >
            <ListItemIcon>
              <FolderIcon sx={{ color: 'secondary.dark' }} />
            </ListItemIcon>
            <ListItemText 
              primary={workspace.name}
              secondary={workspace.memberDetails.filter(m => m.uid === user.uid)[0]?.role}
              primaryTypographyProps={{
                sx: {
                  color: 'secondary.dark',
                  fontSize: '0.95em',
                }
              }}
            />
          </ListItemButton>
        ))}
        
        <Divider sx={{ my: 1 }} />
        
        <ListItemButton onClick={handleManageClick}>
          <ListItemIcon>
            <SettingsIcon sx={{ color: 'secondary.dark' }} />
          </ListItemIcon>
          <ListItemText 
            primary="Manage Workspaces"
            primaryTypographyProps={{
              sx: {
                color: 'secondary.dark',
                fontSize: '0.95em',
                fontWeight: 500
              }
            }}
          />
        </ListItemButton>
      </List>
    </Popover>
  );
}; 