import { Typography, Box, Stack, Divider } from "@mui/material";
import { DragIndicator } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import {
  CreateSurveyQuestionsState, CreateSurveyState,
  SelectedLanguageState,
} from "../atoms";
import { QuestionItem } from "./question-item";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { doc, writeBatch } from "firebase/firestore";
import { db } from "../../../utils/firebaseApp";
import { toast } from "react-toastify";
import { CreateQuestionButton } from "./create-question-button";
import { QuestionStatusType } from "../enums";
import { IconFlare, IconPlus } from "@tabler/icons-react";
import { grey } from "@mui/material/colors";
import { CreateThankYouPageButton } from "./create-thank-you-page-button";
import { ThankYouQuestionItem } from "./thank-you-question-item";

const groupByLanguage = (questions) => {
  return questions.reduce((groups, question) => {
    const key = question.languageObject.BCP47;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(question);
    return groups;
  }, {});
};

export const SurveyQuestions = () => {
  const questions = useAtomValue(CreateSurveyQuestionsState);
  const selectedLanguage = useAtomValue(SelectedLanguageState);
  const [survey, setSurvey] = useAtom(CreateSurveyState)
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const bottomRef = useRef();

  useEffect(() => {
    if (questions && selectedLanguage) {
      setFiltered(questions?.filter(item => item?.languageObject?.BCP47 === selectedLanguage?.BCP47)?.sort((a, b) => +a.orderNumber - +b?.orderNumber) || [])
    }
  }, [selectedLanguage, questions])

  useEffect(() => {
    if (bottomRef && bottomRef?.current) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [filtered?.length])

  // Group questions by language

  // Handle drag end event
  const handleDragEnd = async (result) => {
    if (!result.destination || loading) return;

    const { source, destination } = result;

    if (source.index === destination.index) return;

    // Create a mapping of questions before reordering to track their original orderNumbers
    const originalOrderMap = questions.reduce((map, q) => {
      map[q.uid] = q.orderNumber;
      return map;
    }, {});

    // Also track the original allOtherCasesQuestion values
    const originalAllOtherCasesMap = questions.reduce((map, q) => {
      map[q.uid] = q.allOtherCasesQuestion;
      return map;
    }, {});

    const grouped = groupByLanguage([...questions]);
    const reordered = [];
    for (const [key, questionGrouped] of Object.entries(grouped) || []) {
      const reorderedItems = Array.from(questionGrouped?.sort((a, b) => +a?.orderNumber - +b?.orderNumber));
      const [movedItem] = reorderedItems.splice(source.index, 1);
      reorderedItems.splice(destination.index, 0, movedItem);

      reordered.unshift(reorderedItems?.map((i, index) => ({ ...i, orderNumber: index })));
    }
    const newQuestions = reordered?.flat() || [];
    
    // Create a mapping from old orderNumber to new orderNumber
    const orderNumberMap = {};
    newQuestions.forEach(q => {
      orderNumberMap[originalOrderMap[q.uid]] = q.orderNumber;
    });

    const batch = writeBatch(db);

    for (const updateQuestion of newQuestions) {
      if (updateQuestion && updateQuestion?.uid) {
        const updates = {
          orderNumber: updateQuestion.orderNumber,
        };
        
        // Check if this is the last question in its language group
        const isLastInLanguageGroup = !newQuestions.some(q => 
          q.languageObject.BCP47 === updateQuestion.languageObject.BCP47 && 
          q.orderNumber === updateQuestion.orderNumber + 1
        );
        
        // If it's the last question, set allOtherCasesQuestion to null
        if (isLastInLanguageGroup) {
          updates.allOtherCasesQuestion = null;
        } else {
          // Find the next question in the same language group
          const nextQuestion = newQuestions.find(q => 
            q.languageObject.BCP47 === updateQuestion.languageObject.BCP47 && 
            q.orderNumber === updateQuestion.orderNumber + 1
          );
          
          // Always set allOtherCasesQuestion to the next question's order number
          updates.allOtherCasesQuestion = nextQuestion ? nextQuestion.orderNumber : null;
        }
        
        // Update rules if they exist
        if (updateQuestion.rules && updateQuestion.rules.length > 0) {
          const updatedRules = updateQuestion.rules.map(rule => {
            if (rule.nextQuestion !== undefined) {
              // Map the old nextQuestion orderNumber to the new orderNumber
              const oldOrderNumber = rule.nextQuestion;
              const newOrderNumber = orderNumberMap[oldOrderNumber];
              return { ...rule, nextQuestion: newOrderNumber !== undefined ? newOrderNumber : rule.nextQuestion };
            }
            return rule;
          });
          
          updates.rules = updatedRules;
        }
        
        batch.update(doc(db, "questions", updateQuestion.uid), updates);
      }
    }

    try {
      setLoading(true);
      await batch.commit();
    } catch (error) {
      toast.error(error?.message || "Failed to reorder");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd} isDropDisabled={loading}>
        <Droppable droppableId="questionsList">
          {(provided) => (
            <Stack direction={"column"} gap={2} sx={{ position: "relative", minHeight: "80%" }} {...provided.droppableProps} ref={provided.innerRef}>
              {filtered?.map((question, index) => (
                <Draggable key={question?.uid} draggableId={question?.uid} index={index}>
                  {(provided) => (
                    <Stack
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      direction={"row"}
                      gap={2}
                      alignItems={"center"}
                    >
                      <Box {...provided.dragHandleProps}>
                        <DragIndicator sx={{ cursor: loading ? "wait" : "grab" }} />
                      </Box>
                      <QuestionItem question={question} />
                    </Stack>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}

              {questions.length > 0 
              && <>
                <Divider />
                {
                  survey?.thankYouPage ?
                    <ThankYouQuestionItem /> :
                    <CreateThankYouPageButton />
                }
              </>}
                <CreateQuestionButton />

              <div ref={bottomRef} style={{ paddingBottom: "150px" }} />
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

