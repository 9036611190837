import {Box, Stack, Typography} from "@mui/material";
import {IconFlare, IconPlus} from "@tabler/icons-react";
import {grey} from "@mui/material/colors";
import React from "react";
import {useSetAtom} from "jotai";
import {CreateThankYouQuestionState} from "../atoms";


export const CreateThankYouPageButton = () => {
  const setCreateThankYouPage = useSetAtom(CreateThankYouQuestionState);

  return (
    <Stack direction={"row"} gap={2} alignItems={"center"}>
      <Box>
        <IconFlare />
      </Box>
      <Stack
        direction={"row"} alignItems={"center"} justifyContent={"center"}
        sx={{flex: 1, cursor: "pointer",borderRadius: 5, border: 1, bgcolor: grey[50], borderColor: "divider", borderStyle: "dashed", p: 3, minHeight: "10dvh"}}
        onClick={() => setCreateThankYouPage(true)}
      >
        <IconPlus />
        <Typography>Add Thank You Page</Typography>
      </Stack>
    </Stack>
  )
}