import React, { createContext, useEffect, useReducer } from 'react'
import { enums } from "./enums";
import { getBlankSurveyObject } from "./sharedFunctions";
import { useAuthContext } from "../contexts/auth_context";
import { collection, onSnapshot, orderBy, query, where, doc, setDoc } from "firebase/firestore";
import { db } from "./firebaseApp";
import { createNewWorkspace } from "./sharedFunctions";
import {QuestionTypes} from "../pages/create-project-page-new/enums";
export const AppContext = createContext({});

let startMessageStack = [
  {
    "role": "system",
    "content": `
You are a **PhD-level expert in survey design**, specializing in crafting **concise, unbiased, and high-quality survey questions**. Your primary goal is to help users improve their surveys and questions by making them **clearer, neutral, and methodologically sound**.

### **Your Task**
- When the user submits a **survey question or multiple questions**, you will:
  1. **Improve the wording** to enhance clarity, neutrality, and precision.
  2. **Ensure the question structure is appropriate** for the intended response type (e.g., open-ended, Likert scale, multiple choice).
  3. **Identify and correct biases** such as leading wording, double-barreled questions, bias, vague phrasing, and other issues in survey design, etc.

- If the user asks about anything **outside the topic of surveys**, gently remind them that your role is **exclusively focused on survey development**.
- If the user's message does not contain questions to improve, return an **empty array** for \`"improved_questions"\` and still provide a response in \`"your_response"\`.
- When responding, you value the truth even if the truth hurts and never engage in two-sideism and always say feedback directly and hyperclearly. 
- You use academic survey design terminology when discussing question structure, bias, and methodology, but otherwise, you keep your language clear, direct, and accessible to laypeople. Your explanations should be educational and precise, ensuring users understand both what was wrong and why the revision is better.
- If the question is better suited to a different response type (e.g., multiple choice instead of open-ended), recommend switching formats and suggest appropriate answer options if applicable
-If survey question option wording/phrasing can be improved, please additionally suggest your improvements in “your_response” section of the JSON output

These are the question types our platform supports that you can recommend:
-text (includes number input)
-voice (60 second voice notes for open responses in 100+ languages, transcribed to english)
-multiple choice (single select)
-checkboxes (multi select)
-slider (number in range)
-dropdown (multi select)
-Map input (drop dots of different types on a map)
-image answer (upload or take up to three photos)
-section (not a question but a section divider or title/thank you screen where you can add a hyperlink to your terms and conditions (e.g. on title screen) or to your organization (e.g. on thank you screen)
-geolocate user (accesses user’s device precise location for longitude latitude)
-image gallery (single select an option from an image gallery)

### **Output Rules**
Your response **must** be in **strict JSON format**, returning exactly a JSON response in this schema:

\`\`\`json
{{
  "improved_questions": [
    "Revised question (include updated options only if needed, formatted clearly) (string)",
    "Revised question (include updated options only if needed, formatted clearly) (string)",
    "Revised question (include updated options only if needed, formatted clearly) (string)"
  ],
  "your_response": "explanation here (string) and question option improvements if applicable"
}}
\`\`\`

-"improved_questions" → Contains the revised version(s) of the user’s survey question(s), ensuring they are concise, unbiased, and methodologically sound.
You must provide exactly one improved question per question the user originally inputs. 
-"your_response" → Provides a brief explanation of why the revisions improve clarity, neutrality, and validity.

### **Examples of Insufficient Improvements to questions**
Improvements must be meaningful changes. If you cannot identify significant issues with a question, move onto questions in need of improvement rather than making superficial changes.
ORIGINAL: "How satisfied are you with our product?" 
BAD IMPROVEMENT: "How satisfied are you with our product offering?" (merely adds a word without fixing methodological issues) 
ORIGINAL: "Do you like our customer service?"
BAD IMPROVEMENT: "How do you feel about our customer service?" (still vague, just reworded)

###**Examples of output format**
Here is an example:

Example input:
User:
"can you make this better: What do you think about how disaster preparedness is currently being handled in your community?" 

Example output:
{
"improved_questions": [“What specific suggestions do you have for improving disaster preparedness in your community?”],
"your_response": "Your original question was a bit too broad and could unintentionally steer people toward criticizing current efforts. I made three key improvements: 
(1) Removed vague phrasing like 'how... handled,' which invites subjective interpretations
(2) Eliminated the leading structure ('What do you think about'), which primes respondents to critique rather than assess neutrally
(3) Removed the implicit assumption of inadequacy ('currently being handled' implies existing efforts may be failing). 

I fixed these by removing vague and leading language and focusing on actionable feedback with 'specific suggestions.' This keeps the question neutral and encourages constructive responses, giving you more balanced and useful insights and making the most out of the benefits of an open response question."
}
    `
  },
  {
    "role": "system",
    "content": "you have the following question types at your disposal: " + Object.values(QuestionTypes)
  }, {
    "role": "system",
    "content": "when answering the question 'can you provide me with a list of questions?' always provide a question type that's best suited for the question you're giving from the list you've been given"
  },
]

const initialState = {
  viewToShow: enums.DASHBOARD,
  surveyObject: getBlankSurveyObject('auth.currentUser.uid'),
  surveyObjectToDisplay: null,
  surveys: [],
  allSurveys: [],
  questionObjects: [],
  answerObjects: [],
  questionObjectToEdit: null,
  newSurvey: 0,
  tokens: [],
  reports: [],
  messages: startMessageStack,
  surveyAnswerObjects: [],
  workspaceId: null,
  workspaces: [],
}



const reducer = (state, action) => {
  return { ...state, ...action }
};

export const AppContextProvider = props => {
  const { user } = useAuthContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!user) return;
    const fetchWorkspaces = () => {
      console.log("fetching workspaces")
      const workspacesQuery = query(
        collection(db, "workspaces"),
        where("members", "array-contains", user.uid)
      );

      return onSnapshot(workspacesQuery, async (querySnapshot) => {
        const newWorkspaces = [];

        if (querySnapshot.empty) {
          console.log("Creating workspace");
          const workspaceUid = crypto.randomUUID();
          const newWorkspace = createNewWorkspace(workspaceUid, user, enums.FREE, "My first workspace");
          await setDoc(doc(db, "workspaces", workspaceUid), newWorkspace);
          dispatch({ workspaceId: workspaceUid });
          dispatch({ workspaces: [...state.workspaces, newWorkspace] });
        } else {
          querySnapshot.forEach(doc => {
            newWorkspaces.push({ ...doc.data(), id: doc.id });
          });

          console.log('newWorkspaces', newWorkspaces);
          const oldestWorkspace = newWorkspaces.sort((a, b) => a.createTime - b.createTime)[0];
          console.log("newWorkspaces", newWorkspaces)
          dispatch({
            workspaces: newWorkspaces || [],
            workspaceId: oldestWorkspace?.uid || null
          });

        }
      });
    };

    dispatch({ currentUser: user });

    const unsubscribeWorkspaces = fetchWorkspaces();

    return () => {
      unsubscribeWorkspaces();
    };
  }, [user?.uid]);

  useEffect(() => {
    if(state?.workspaces && state?.workspaces.length > 0) {
      const fetchSurveys = (workspaceIds) => {
        console.log("fetching surveys for workspaces:", workspaceIds)
        const surveysQuery = query(
          collection(db, "surveys"),
          where("workspaceId", "in", workspaceIds)
        );

        return onSnapshot(surveysQuery, (querySnapshot) => {
          const newSurveys = [];
          querySnapshot.forEach(doc => {
            newSurveys.push({ ...doc.data(), id: doc.id });
          });

          dispatch({
            allSurveys: newSurveys.sort((a, b) => b.dateCreated - a.dateCreated),
            surveys: newSurveys
              .filter(survey => survey.workspaceId === state.workspaceId)
              .sort((a, b) => b.dateCreated - a.dateCreated)
          });
        });
      };
      const workspaceIds = state?.workspaces?.map(workspace => workspace.uid);
      const unsubscribeSurveys = fetchSurveys(workspaceIds);
      return () => unsubscribeSurveys()
    }
  }, [state?.workspaces]);

  useEffect(() => {
    if (state.allSurveys?.length) {
      dispatch({
        surveys: state.allSurveys
          .filter(survey => survey.workspaceId === state.workspaceId)
          .sort((a, b) => b.dateCreated - a.dateCreated)
      });
    }
  }, [state.workspaceId]);

  useEffect(() => {
    const fetchQuestions = (surveys) => {
      const questionsQuery = query(
        collection(db, "questions"),
        where("surveyUid", "in", surveys),
      );
      return onSnapshot(questionsQuery, (querySnapshot) => {
        let newQuestions = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          newQuestions.push(data);
        });
        console.log("questions", newQuestions)
        dispatch({ questionObjects: newQuestions?.sort((a, b) => +b.orderNumber - +a.orderNumber) });
      });
    }
    const fetchReports = (surveys) => {
      const reportsQuery = query(
        collection(db, "reports"),
        where("surveyUid", "in", surveys),
      );
      return onSnapshot(reportsQuery, (querySnapshot) => {
        let newReports = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          newReports.push(data);
        });
        console.log("reports", newReports)
        dispatch({ reports: newReports?.sort((a, b) => b.dateCreated - a.dateCreated) });
      });
    }
    const fetchAnswers = (surveys) => {
      const answerQuery = query(
        collection(db, "answers"),
        where("surveyUid", "in", surveys),
        orderBy("timestamp"),
      );
      return onSnapshot(answerQuery, (querySnapshot) => {
        let newAnswers = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          newAnswers.push(data);
        });
        console.log("answers", newAnswers)
        dispatch({ answerObjects: newAnswers });
      });
    }
    if (state?.surveys?.length) {
      const surveysUid = state?.surveys?.map(survey => survey?.uid) || [];
      const questionUnsubscribe = fetchQuestions(surveysUid);
      const reportsUnsubscribe = fetchReports(surveysUid);
      const answersUnsubscribe = fetchAnswers(surveysUid);

      return () => {
        questionUnsubscribe();
        reportsUnsubscribe();
        answersUnsubscribe();
      }
    }
  }, [state?.surveys])


  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AppContext.Provider>
  )
};
