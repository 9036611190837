import { IconButton, Stack, Typography } from "@mui/material";
import { IconX } from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import { grey } from "@mui/material/colors";
import { AppContext } from "../../../utils/AppContext";
import { enums } from "../../../utils/enums";

export const FreeTierBanner = () => {
  const { state: { workspaces, workspaceId, answerObjects } } = useContext(AppContext);

  const [open, setOpen] = useState(false);
  const [maxRespondents, setMaxRespondents] = useState(null);

  useEffect(() => {
    if (!workspaceId || !answerObjects?.length) return;

    let selectedWorkspace = null;
    if (workspaceId?.plan) {
      selectedWorkspace = { ...workspaceId };
    } else {
      selectedWorkspace = workspaces?.find(item => (item?.id || item?.uid) === workspaceId);
    }

    if (!selectedWorkspace || !selectedWorkspace?.plan) return;

    const maxResponses = enums?.MAX_RESPONSES_PER_MONTH[selectedWorkspace?.plan] || 0; // Assuming this key exists
    setMaxRespondents(maxResponses);

    // Get current month range
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1).getTime();
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59).getTime();

    // Filter responses for current month
    const currentMonthResponses = answerObjects.filter(item =>
      item?.workspaceId === workspaceId &&
      item?.timestamp >= startOfMonth &&
      item?.timestamp <= endOfMonth
    );

    // Count unique respondent UIDs
    const uniqueRespondents = new Set(currentMonthResponses.map(item => item.respondentUid)).size;

    if ((workspaceId?.plan === enums.UNPAID || workspaceId?.plan === enums.FREE) && maxResponses && uniqueRespondents >= maxResponses) {
      setOpen(true);
    }

  }, [workspaceId, workspaces, answerObjects]);

  return (
    open && (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="bg-gradient"
        sx={{ py: 2 }}
        gap={2}
      >
        <Typography align="center" color="#fefefe">
          You’ve reached the {maxRespondents || ""} free response limit. Contact us to upgrade your account!
        </Typography>
        <IconButton size="small" sx={{ color: grey[300] }} onClick={() => setOpen(false)}>
          <IconX size={16} />
        </IconButton>
      </Stack>
    )
  );
};
