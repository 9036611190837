import {
  Avatar,
  Button,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {grey} from "@mui/material/colors";
import * as yup from "yup";
import {useFormik} from "formik";
import {signOut, updateProfile} from "firebase/auth";
import {deleteDoc, doc, updateDoc} from "firebase/firestore";
import {deleteUser} from "firebase/auth";
import {useAuthContext} from "../../contexts/auth_context";
import {uploadFile} from "../../utils/functions";
import {auth, db} from "../../utils/firebaseApp";
import {DeleteOutline, Info} from "@mui/icons-material";
import {confirm} from "react-confirm-box";
import {confirmDialogOptions} from "../../components/ConfirmDialog";
import {countryData} from "../../utils/countryData";

const validationSchema = yup.object({
  firstName: yup
    .string('First name')
    .required('First name is required'),
  lastName: yup
    .string('Last name')
    .required('Last name is required'),
  email: yup
    .string('Email')
    .email('Enter a valid email')
    .required('Email is required'),
  phoneNumber: yup
    .string('Phone number'),
  location: yup
    .string('Country of residence')
});

export const AccountSettingsPage = () => {
  const {user: userDetails, updateUser} = useAuthContext();
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: userDetails?.firstName || '',
      lastName: userDetails?.lastName || '',
      username: userDetails?.username || '',
      email: userDetails?.email || '',
      phoneNumber: userDetails?.phoneNumber || '',
      location: userDetails?.location || '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const toastId = toast.loading("Updating user information...");
      try {
        setLoading(true);
        let photoURL = ""
        if (auth?.currentUser?.photoURL) {
          photoURL = auth?.currentUser?.photoURL;
        }
        const displayName = `${values.firstName || ""} ${values.lastName || ""}`
        if (image) {
          const imageName = `${userDetails?.uid}-${image?.name}`;
          toast.update(toastId, {
            render: "Uploading new image...", isLoading: true
          });
          photoURL = await uploadFile(image, "profile", imageName);
          values.photoURL = photoURL;
        }
        toast.update(toastId, {
          render: "Saving user details...", isLoading: true
        });
        const newUserDetails = {...values, displayName, photoURL};
        await updateProfile(auth.currentUser, {displayName, photoURL});
        await updateDoc(doc(db, "users", userDetails.uid), newUserDetails);
        await updateUser(newUserDetails);
        toast.update(toastId, {
          render: "Account information saved successfully", isLoading: false,
          type: "success", autoClose: 2000
        });
      } catch (e) {
        console.log(e)
        toast.update(toastId, {
          render: "Failed to update user information", isLoading: false,
          type: "error", autoClose: 2000
        });
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (userDetails?.uid) {
      console.log(userDetails);
      formik.setValues({
        firstName: userDetails?.firstName || '',
        lastName: userDetails?.lastName || '',
        username: userDetails?.username || '',
        email: userDetails?.email || '',
        phoneNumber: userDetails?.phoneNumber || '',
        location: userDetails?.location || '',
      })
      // setImage(userDetails?.photoURL || null)
    }
  }, [userDetails]);

  const onDelete = async () => {
    const user = auth.currentUser;
    if (!user) {
      console.error("No user is currently signed in.");
      return;
    }
    const response = await confirm(
      {
        title: `Are you sure you want to delete account?`,
        // subtitle: `This action will delete all questions in other languages that were created as a translation of this one.`,
      },
      confirmDialogOptions,
    );
    if (!response) {
      return;
    }

    try {
      setLoading(true);
      // Run transaction to delete the Firestore document
      const userDocRef = doc(db, "users", auth?.currentUser?.uid);
      ;

      await deleteDoc(userDocRef)

      // Delete the user from Firebase Authentication
      await deleteUser(user)

      await signOut(auth);
      toast.success("User deleted successfully.");
    } catch (error) {
      console.log(error);
      toast.error("Error deleting user:", error?.message?.replaceAll("Firebase:", ""));
      // Handle errors appropriately here
    } finally {
      setLoading(false);
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction={"column"} gap={3} maxWidth={"700px"} sx={{mx: {xs: 2, md: "auto"}}}>
        <Stack gap={3} sx={{flexDirection: {xs: "column", md: "row"}, gap: {xs: 1, md: 3}}} alignItems={"center"}>
          <Avatar
            src={image ? URL.createObjectURL(image) : userDetails?.photoURL}
            alt={"User"}
            sx={{width: 150, height: 150, bgcolor: image || userDetails?.photoURL ? "transparent" : grey[300], borderRadius: 3, objectFit: "none"}}
          />
          <Button
            variant={"outlined"}
            sx={{
              px: 5,
              borderRadius: 5,
              color: grey[700],
              borderColor: grey[700],
              "&:hover": {bgcolor: grey[50], borderColor: grey[700]}
            }}
            component="label"
          >
            Update
            <input
              type="file"
              hidden
              onChange={(event) => setImage(event.target.files[0])}
              accept="image/*"
            />
          </Button>
          <Button startIcon={<DeleteOutline/>} sx={{
            px: 5,
            borderRadius: 5,
            color: grey[700],
            borderColor: grey[700],
            "&:hover": {bgcolor: grey[50], borderColor: grey[700]}
          }}>
            Remove
          </Button>
        </Stack>
        <InputLabel
          id="firstName"
          name="firstName"
          label={"First name"}
          helperText={"Your first name"}
          value={formik.values.firstName}
          onChange={formik.handleChange}
          placeholder={"Enter your name"}
          onBlur={formik.handleBlur}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
        />
        <InputLabel
          id="lastName"
          name="lastName"
          label={"Last name"}
          helperText={"Your last name"}
          value={formik.values.lastName}
          onChange={formik.handleChange}
          placeholder={"Enter your last name"}
          onBlur={formik.handleBlur}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
        />
        <InputLabel
          id="email"
          name="email"
          label={"Email"}
          disabled
          helperText={"Your email"}
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder={"Enter your email"}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
        />
        <InputLabel
          id="phoneNumber"
          name="phoneNumber"
          label={<>Phone Number <Typography component={"i"}>(include country code)</Typography></>}
          helperText={"Your phone number"}
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          placeholder={"Enter your phone number"}
          onBlur={formik.handleBlur}
          error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
        />

        <SelectLabel
          id="location"
          name="location"
          label={"Country of residence"}
          value={formik.values.location}
          onChange={formik.handleChange}
          placeholder={"Country of residence"}
          onBlur={formik.handleBlur}
          error={formik.touched.location && Boolean(formik.errors.location)}
          items={Object.values(countryData)?.map(country => ({value: country?.name, name: country?.name}))}
        />
      </Stack>
      <Stack direction={"row"} gap={2} maxWidth={"700px"} useFlexGap flexWrap={"wrap"} alignItems={"center"} sx={{mt: 10, mx: {xs: 2, md: "auto"}, pb: 15}}>
        <Stack direction={"row"} gap={2} maxWidth={"700px"} alignItems={"center"} sx={{ flex: 1}}>
          <Button className={"gradient-outlined-btn"} disabled={loading} type={"button"}
                  onClick={() => formik.resetForm()} sx={{px: 2, border: 1, textWrap: "nowrap"}}>
            Cancel
          </Button>
          <Button className={"gradient-btn"} disabled={loading} type={"submit"} sx={{px: 2, width: "auto !important", textWrap: "nowrap"}}>
            Save Changes
          </Button>
        </Stack>
        <Button variant={"outlined"} disabled={loading} color={"error"} type={"button"} onClick={onDelete}
                sx={{px: 3, borderRadius: 2, textWrap: "nowrap"}}>
          Delete Account
        </Button>

      </Stack>
    </form>
  )
}

const InputLabel = ({label, helperText, value, onChange, ...props}) => {

  return (
    <Stack direction={"column"} gap={1} className={"text-grey"}>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Typography fontWeight={600}>{label}</Typography>
        {helperText && <Tooltip title={helperText}><Info sx={{fontSize: 15}}/></Tooltip>}
      </Stack>
      <TextField
        {...props}
        value={value}
        onChange={onChange}
      />
    </Stack>
  )
}

const SelectLabel = ({label, helperText, value, onChange, items, ...props}) => {

  return (
    <Stack direction={"column"} gap={1} className={"text-grey"}>
      <Stack direction={"row"} gap={1} alignItems={"center"}>
        <Typography fontWeight={600}>{label}</Typography>
        {helperText && <Tooltip title={helperText}><Info sx={{fontSize: 15}}/></Tooltip>}
      </Stack>
      <Select
        {...props}
        value={value}
        onChange={onChange}
      >
        {items?.map(item => <MenuItem key={item?.value} value={item?.value}>{item?.name}</MenuItem>)}
      </Select>
    </Stack>
  )
}